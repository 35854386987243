import { EditableInput, Saturation } from 'react-color/lib/components/common';
import { Box, Button, Modal, Text } from '../../../shared/components';
import { useState } from 'react';
import { colors } from '../../../shared/styles';
import { AlphaPicker, CustomPicker, HuePicker } from 'react-color';
import { container } from '../../../shared/shared-styles';
import { Color } from './ColorPicker';

export const CreateColorModal = ({ onClose, addGuideColor }) => {
  const [color, setColor] = useState('#000');

  const updateColor = (color, alphaValue = null) => {
    const { r, g, b, a } = color.rgb;
    const alpha = alphaValue || a;
    const rgba = `rgba(${r}, ${g}, ${b}, ${alpha})`;
    setColor(rgba);
  };

  return (
    <Modal display={true} onClose={onClose} data-scope="modal">
      <Modal.Paper width="600px">
        <Modal.Header title="Add a Guide Color" onClose={onClose} />
        <Modal.Body>
          <Box flex="space-between">
            <CustomColorPicker color={color} onChange={updateColor} />
          </Box>
        </Modal.Body>
        <Modal.Actions>
          <Box
            css={`
              flex-grow: 1;
            `}
            flex="left"
          >
            <Color color={color} size="32px" />
          </Box>
          <Button secondary onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={() => addGuideColor(color)}>Add Color</Button>
        </Modal.Actions>
      </Modal.Paper>
    </Modal>
  );
};

const CustomColorPicker = CustomPicker(({ updateColor, ...customPickerProps }) => {
  const props = {
    ...customPickerProps,
    onChange: (color) => customPickerProps.onChange(color, null),
  };

  const handleAlphaChange = (color) => customPickerProps.onChange({ ...color }, color.rgb.a);
  const handleRGBChange = (change) => customPickerProps.onChange({ ...customPickerProps.rgb, ...change }, null);

  return (
    <Box
      flex="space-between start"
      css={`
        gap: 32px;
        width: 100%;
      `}
    >
      <Box
        css={`
          position: relative;
          width: 300px;
          height: 250px;
          border: 1px solid ${colors.gray[200]};
          overflow: hidden;
        `}
      >
        <Saturation {...props} />
      </Box>
      <Box>
        <Text label>HEX</Text>
        <EditableInput
          label="hex"
          value={customPickerProps.hex}
          onChange={props.onChange}
          style={{
            label: { display: 'none' },
            input: {
              border: `1px solid ${colors.gray[300]}`,
              padding: '8px',
              borderRadius: '8px',
              width: '100%',
            },
          }}
        />
        <Box
          css={`
            margin: 16px 0;
          `}
        >
          <Text label>RGB</Text>
          <Box
            flex="space-between"
            css={`
              width: 200px;
              margin: 8px 0;
              gap: 8px;
            `}
          >
            <EditableInput
              label="r"
              value={customPickerProps.rgb.r}
              onChange={handleRGBChange}
              style={{
                label: { display: 'none' },
                input: {
                  border: `1px solid ${colors.gray[300]}`,
                  padding: '8px',
                  borderRadius: '8px',
                  width: '100%',
                  outline: 'none',
                },
              }}
            />
            <EditableInput
              label="g"
              value={customPickerProps.rgb.g}
              onChange={handleRGBChange}
              style={{
                label: { display: 'none' },
                input: {
                  border: `1px solid ${colors.gray[300]}`,
                  padding: '8px',
                  borderRadius: '8px',
                  width: '100%',
                  outline: 'none',
                },
              }}
            />
            <EditableInput
              label="b"
              value={customPickerProps.rgb.b}
              onChange={handleRGBChange}
              style={{
                label: { display: 'none' },
                input: {
                  border: `1px solid ${colors.gray[300]}`,
                  padding: '8px',
                  borderRadius: '8px',
                  width: '100%',
                  outline: 'none',
                },
              }}
            />
          </Box>
        </Box>
        <Box
          css={`
            margin: 24px 0;
            position: relative;
            width: 200px;
            overflow: hidden;
            background-color: ${colors.gray[100]};
            ${container.hover}
            padding: 8px 12px;
            border-radius: 8px;
          `}
        >
          <HuePicker {...props} width="175px" />
        </Box>
        <Box
          css={`
            margin: 24px 0;
            position: relative;
            width: 200px;
            overflow: hidden;
            background-color: ${colors.gray[100]};
            ${container.hover}
            padding: 8px 12px;
            border-radius: 8px;
          `}
        >
          <AlphaPicker {...props} onChange={handleAlphaChange} width="175px" />
        </Box>
      </Box>
    </Box>
  );
});
