import { List } from '@phosphor-icons/react';
import { Box } from '../../../shared/components';
import { colors } from '../../../shared/styles';
import { Tooltip } from '../../../common/components/Tooltip';
import { shadows } from '../../../shared/shared-styles';

export const DragHandle = ({ css: cssString = '', label, onDrag }) => {
  return (
    <Tooltip label={label}>
      <Box
        css={`
          width: 24px;
          border-radius: 50%;
          background-color: ${colors.gray[600]};
          padding: 4px;
          cursor: grab;
          outline: 1px solid rgba(255, 255, 255, 0.2);
          ${shadows.md}
          ${cssString}
        `}
        className="drag-handle"
        flex="center"
        draggable={true}
        onDragStart={onDrag}
      >
        <List size={16} weight="bold" color="white" />
      </Box>
    </Tooltip>
  );
};
