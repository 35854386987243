import { format } from 'date-fns';
import { css } from '@emotion/css';
import NoHeaderImage from '../Courses/no-header-image.png';
import { Box, Text } from '../shared/components';
import { colors } from '../shared/styles';
import { getGuideType } from './utils/guide';

export const GuideCard = ({ isSelected = false, onSelect = () => {}, guide = {} }) => {
  return (
    <Box
      css={`
        display: flex;
        height: 128px;
        padding: 24px 32px;
        border: 2px solid ${isSelected ? colors.gray[500] : 'transparent'};
        border-radius: 16px;
        box-shadow: 0px 2px 8px 0px rgba(37, 40, 45, 0.2);

        &:hover {
          cursor: pointer;
          :hover {
            border: 2px solid ${colors.gray[500]};
          }
        }
      `}
      onClick={() => onSelect()}
    >
      <Box
        css={`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          align-self: stretch;
          margin-right: 40px;
          height: 100%;
          width: 150px;
          height: 80px;
        `}
      >
        <img
          className={css`
            max-width: 150px;
            max-height: 80px;
          `}
          src={guide.LogoUrl ? guide.LogoUrl : NoHeaderImage}
          alt={guide.Name ? guide.Name : 'Carrier Logo'}
        />
      </Box>
      <Box
        css={`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          flex: 1 0 0;
          gap: 8px;
        `}
      >
        <Box
          css={`
            display: flex;
            justify-content: space-between;
            width: 100%;
            gap: 8px;
          `}
        >
          <Box>
            <Text h4>{guide.Name}</Text>
            <Text body>Created: {format(new Date(guide.CreatedAt), 'LLLL yyyy')}</Text>
          </Box>
          <Box
            css={`
              display: flex;
              padding: 4px 16px;
              justify-content: start;
              align-items: center;
              height: 50%;
              border-radius: 24px;
              background: var(--100, ${colors.gray[100]});
            `}
          >
            <Text
              label
              css={`
                font-size: 14px;
                line-height: 20px;
                text-wrap: nowrap;
              `}
            >
              <strong>{getGuideType({ type: guide.Type })}</strong>
            </Text>
          </Box>
        </Box>
        <Box>
          <Text label>
            <strong>{guide.Views}</strong>{' '}
            <span
              className={css`
                color: ${colors.gray[500]};
              `}
            >
              Views
            </span>
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
