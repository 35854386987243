import { Button, Input, Modal } from '../../../shared/components';

export const EditButtonNameModal = ({ sync, onClose }) => {
  return (
    <Modal display data-scope="modal">
      <Modal.Paper>
        <Modal.Header title="Edit Button Name" onClose={onClose} />
        <Modal.Body>
          <Input value={sync?.value?.content} onChange={(event) => sync?.merge({ content: event.target.value })} />
        </Modal.Body>
        <Modal.Actions>
          <Button secondary onClick={onClose}>
            Done
          </Button>
        </Modal.Actions>
      </Modal.Paper>
    </Modal>
  );
};
