const getYoutubeEmbed = (value, url) => {
  if (url.hostname === 'youtu.be') {
    return `${url.protocol}//www.youtube.com/embed${url.pathname}`;
  } else if (value.includes('/embed/')) {
    return value;
  } else if (url.pathname === '/watch') {
    const v = url.searchParams.get('v');
    if (!v) {
      return '';
    }
    return `${url.origin}/embed/${v}`;
  } else if (url.pathname.includes('/embed')) {
    return value;
  } else {
    return `${url.origin}/embed${url.pathname}`;
  }
};

const getLoomEmbed = (url) => {
  const nextUrl = url.href.replace('share', 'embed');
  return nextUrl;
};

const getVimeoEmbed = (url) => {
  if (!url.pathname.startsWith('/video')) {
    let { pathname } = url;
    const [, id1, id2] = pathname.split('/');
    if (id2) {
      pathname = `/${id1}?h=${id2}`;
    }
    return `${url.protocol}//player.vimeo.com/video${pathname}`;
  }
};

const getGoogleDriveEmbed = (url) => {
  return url.href.replace('/view', '/preview').replace('/edit', '/preview');
};

export const getVideoUrl = (value) => {
  try {
    const url = new URL(value);
    if (url.hostname === 'www.youtube.com' || url.hostname === 'youtu.be') {
      return getYoutubeEmbed(value, url);
    } else if (url.hostname === 'vimeo.com') {
      return getVimeoEmbed(url);
    } else if (url.hostname === 'www.loom.com') {
      return getLoomEmbed(url);
    } else if (url.hostname === 'drive.google.com') {
      return getGoogleDriveEmbed(url);
    }
    return value;
  } catch (err) {
    console.log(err);
    return '';
  }
};
