import { Box } from '../../../shared/components';
import { Toolbar } from '../../utility/Toolbar';
import { ButtonToolbar } from './ButtonToolbar';
import { useButtonBlock } from './useButtonBlock';

const buttonContainer = `
  max-width: 100%;
  button {
    width: auto;
    max-width: 100%;
    display: inline-block;
    word-wrap: break-word;
    white-space: normal;
    outline: none;
    border: none;
    background: none;
    appearance: none;
    text-align: center;
    padding: 0;
    margin: 0;
    overflow: hidden;
    resize: none;
    pointer-events: none;
    transition: padding .2s ease, font-size .2s ease;
  }
`;

export const ButtonBlock = () => {
  const block = useButtonBlock();

  return (
    <>
      <Box css={buttonContainer}>
        <button style={block?.style}>{block?.sync?.value?.content}</button>
      </Box>
      <Toolbar isSelected={block?.isSelected} anchor={block?.anchor}>
        <ButtonToolbar id={block?.id} sync={block?.sync} buttonSize={block?.buttonSize} />
      </Toolbar>
    </>
  );
};
