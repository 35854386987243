import { Segment } from '../../providers/segment/Segment';
import { useBlock } from '../../utility/useBlock';
import { useSync } from '../../utility/useSync';
import { useIsValidUrl } from '../../utility/editor-utils';

export const useWebsiteBlock = () => {
  const block = useBlock();

  const properties = Segment.getProperties(block.segment);
  const attributes = properties?.attributes;
  const heightRaw = Number(attributes.height.replace(/\D/g, '') || 0);

  const height = useSync(heightRaw);
  const src = useSync(attributes?.src || '');
  const linkText = useSync(properties?.linkText || '');

  const validUrl = useIsValidUrl(src.value);

  return {
    ...block,
    src,
    height,
    linkText,
    validUrl,
    attributes,
  };
};
