import { CaretDown } from '@phosphor-icons/react';
import { Box, Dropdown, Text } from '../../../../shared/components';
import { colors } from '../../../../shared/styles';
import { container } from '../../../../shared/shared-styles';
import { SmartFieldInput } from '../../../../BriteEditor/utility-components/smart-field-input';
import { safelySpread } from '../../../providers/content/content-modify';
import { TAB_MAP } from '../../../utility/constants';

export const OpenWebsite = ({ value, setValue }) => {
  const updateAction = (action) => {
    setValue({ ...value, action: { ...value.action, ...action } });
  };
  const updateActionData = (actionData) => {
    setValue({
      ...value,
      action: {
        ...safelySpread(value.action),
        data: {
          ...safelySpread(value.action.data),
          ...actionData,
        },
      },
    });
  };

  const handleSmartField = ({ data }) => {
    updateAction({
      smartFieldDisplay: data.name,
      data: {
        ...value.action.data,
        url: data.value,
      },
    });
  };

  const removeSmartField = () => {
    updateAction({
      data: {
        ...value.action.data,
        url: '',
      },
    });
  };

  return (
    <>
      <Text
        label
        css={`
          margin-top: 16px;
          margin-bottom: 8px;
        `}
      >
        Url
      </Text>
      <SmartFieldInput
        value={value?.action?.data?.url}
        smartFieldDisplay={value?.smartFieldDisplay}
        handleSmartField={handleSmartField}
        removeSmartField={removeSmartField}
        inputProps={{
          value: value?.action?.data?.url,
          onChange: (e) => updateAction({ data: { ...value?.action?.data, url: e.target.value } }),
        }}
        nameFilter="URL"
        css={`
          width: 240px;
        `}
      />

      <Text
        label
        css={`
          margin-top: 16px;
          margin-bottom: 8px;
        `}
      >
        Open website in
      </Text>
      <Dropdown
        clear
        button={() => (
          <Box
            css={`
              margin: 8px 0;
              width: 100%;
              border-radius: 8px;
              border: 1px solid ${colors.gray[200]};
              padding: 8px;
              ${container.hover}
            `}
            flex="space-between"
          >
            <Text label>{TAB_MAP[value?.action?.data?.tabType] || 'New Tab'}</Text>
            <CaretDown size={24} />
          </Box>
        )}
      >
        <Box
          css={`
            width: 200px;
          `}
          data-scope="menu"
        >
          <Box option onClick={() => updateActionData({ tabType: 'new-tab' })}>
            <Text label>New Tab</Text>
          </Box>
          <Box option onClick={() => updateActionData({ tabType: 'same-tab' })}>
            <Text label>Same Tab</Text>
          </Box>
        </Box>
      </Dropdown>
    </>
  );
};
