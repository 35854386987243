import { css } from '@emotion/css';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { Badge, Box, Button, Modal, Input, Text, Loader } from '../../shared/components';
import { textTheme } from '../../shared/components/text';
import { colors, mediaQueryFn } from '../../shared/styles';
import { useStore } from '../../store-provider/use-store';
import { createDashboard } from '../api/dashboard';
import { getGuides } from '../api/guide';
import { FilterPills } from '../FilterPills';
import { GuideCard } from '../GuideCard';
import { SortDropdown } from '../SortDropdown';
import { MOST_VIEWS_SORT_TYPE } from '../utils/guide';
import { useGuidesList } from '../utils/hooks/useGuidesList';

const DEFAULT_DASHBOARD_NAME = 'Untitled Dashboard';

export const DashboardModal = ({ hide = () => {}, refetch = () => {} }) => {
  // Store
  const {
    data: { selectedBusiness },
  } = useStore();

  // Queries
  const { data: guides, isLoading: isLoadingGuides } = useQuery('guides', getGuides);

  // State
  const [name, setName] = useState(DEFAULT_DASHBOARD_NAME);
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState('');
  const [filterVal, setFilterVal] = useState([]);
  const [sortVal, setSortVal] = useState(MOST_VIEWS_SORT_TYPE);
  const [isLoading, setIsLoading] = useState(false);

  // Custom Hooks
  const guidesList = useGuidesList({ guides, search, filterVal, sortVal });

  // TODO: UI states.
  const handleCreateDashboard = useMutation(
    () => createDashboard({ businessID: selectedBusiness?.ID, name, guideIDs: [...selected] }),
    {
      onMutate: () => setIsLoading(true),
      onSuccess: () => {
        refetch();
        hide();
      },
      onError: () => setIsLoading(false),
      onSettled: () => setIsLoading(false),
    }
  );

  const handleSelect = (id) => {
    const isSelected = selected.includes(id);
    if (isSelected) {
      setSelected(selected.filter((selectedId) => selectedId !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  return (
    <Modal full display>
      {isLoading ? (
        <Loader />
      ) : (
        <Box
          css={`
            width: 100%;
            padding: 0px;
          `}
        >
          <Box
            css={`
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 24px 40px;
              border-bottom: 1px solid var(--300, ${colors.gray[300]});
            `}
          >
            <input
              className={css`
                ${textTheme.h2}
                ${textTheme.ellipsis}
                padding: 8px;
                border: 1px solid transparent;
                border-radius: 8px;
                outline: 1px solid transparent;

                :hover {
                  background-color: ${colors.gray[100]};
                }

                :focus {
                  background-color: white;
                  border: 1px solid ${colors.gray[300]};
                }
              `}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Box
              css={`
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                gap: 16px;
              `}
            >
              <Button secondary onClick={() => hide()}>
                Cancel
              </Button>
              <Button
                css={`
                  position: relative;
                `}
                disabled={selected.length === 0}
                onClick={() => handleCreateDashboard.mutate()}
              >
                {selected.length > 0 && <Badge>{selected.length}</Badge>}
                Create Dashboard
              </Button>
            </Box>
          </Box>
          <Box
            css={`
              display: flex;
              justify-content: center;
              align-items: flex-start;
              flex-direction: column;
              margin: 0px auto;
              padding: 40px 0px;
              width: 60%;
              gap: 40px;

              ${mediaQueryFn('less-than', '1200px')} {
                width: 90%;
              }
            `}
          >
            <Text h2>Select the guides you'd like to add to this dashboard</Text>
            {isLoadingGuides ? (
              <Loader />
            ) : (
              <Box
                css={`
                  display: flex;
                  flex-direction: column;
                  height: 100%;
                  width: 100%;
                  gap: 24px;
                `}
              >
                <Box
                  css={`
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;
                    width: 100%;
                    gap: 24px;
                  `}
                >
                  <Input
                    startIcon={<MagnifyingGlass size={24} color={colors.gray[500]} />}
                    styles="search"
                    css={`
                      display: flex;
                      align-items: center;
                      padding: 0px 16px;
                      width: 40%;
                    `}
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <FilterPills setFilterVal={setFilterVal} filterVal={filterVal} />
                </Box>
                <Box
                  css={`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                  `}
                >
                  <Text h4>{guidesList?.length} Guides</Text>
                  <SortDropdown setSortVal={setSortVal} sortVal={sortVal} />
                </Box>
                {/* Vertical Scroll Styles applied to the Box component below. */}
                <Box
                  css={`
                    display: flex;
                    flex-direction: column;
                    padding: 4px 300px 48px 300px;
                    margin: 0px -300px;
                    max-height: 60vh;
                    overflow-y: auto;
                    gap: 24px;
                  `}
                >
                  {guidesList?.map((guide) => (
                    <GuideCard
                      key={guide.ID}
                      isSelected={selected.includes(guide.ID)}
                      onSelect={() => handleSelect(guide.ID)}
                      guide={guide}
                    />
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Modal>
  );
};
