import { css, cx } from '@emotion/css';
import { useState } from 'react';
import { animation, container } from '../shared-styles';
import { useOutsideClick } from '../use-outside-click';
import { Div } from './div';
import { Text } from './text';
import { Box } from './box';
import { Portal } from './Portal';
import { usePopper } from 'react-popper';
import { useLifecycle } from '../useLifecycle';
import { useThemedProps } from '../useThemedProps';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { colors } from '../styles';

const dropdownTheme = {
  default: `
    position: relative;
    background-color: white;
    border-radius: 8px;
    padding: 8px;
  `,
  clear: `
    padding: 0;
    background: none;
  `,
  disabled: `
    opacity: .6;
    pointer-events: none;
  `,
};

export const Dropdown = ({
  isInitiallyOpen = false,
  button,
  stopPropagation = false,
  css: cssString,
  flex = '',
  children,
  outsideClickProps = {},
  listWidth,
  listMaxHeight = '40vh',
  listCss = '',
  onClose = null,
  clear = false,
  // react-popper props
  popper: popperProps = {},
  disabled = false,
}) => {
  const [open, setOpen] = useState(isInitiallyOpen);
  const ref = useOutsideClick(() => setOpen(false), outsideClickProps);
  const [anchor, setAnchor] = useState(null);
  const [popper, setPopper] = useState(null);
  const { styles, attributes } = usePopper(anchor, popper, popperProps);

  const props = useThemedProps({ flex, css: cssString, disabled, clear }, dropdownTheme);

  useLifecycle(
    {
      onSubsequent: () => {
        if (!open) {
          onClose?.();
        }
      },
    },
    [open]
  );
  return (
    <Div {...props} ref={setAnchor} onClick={() => setOpen(!open)}>
      {button({ open })}
      {open ? (
        <Portal>
          <div ref={ref}>
            <Box
              ref={setPopper}
              scrollbar
              className={cx(
                css`
                  z-index: 1000;
                  ${container.box}
                  ${animation('fadeIn', '.2s ease')}
                  position: absolute;
                  padding: 8px;
                  margin-top: 8px;
                  overflow: auto;
                  overflow-x: hidden;
                  max-height: ${listMaxHeight};
                  min-width: ${listWidth};
                  border-radius: 8px;
                  ::-webkit-scrollbar-track {
                    border-radius: 50%;
                  }
                  ${listCss}
                `,
                'dropdown-list'
              )}
              style={styles.popper}
              {...attributes.popper}
              onMouseUp={(e) => {
                if (stopPropagation) {
                  e.stopPropagation();
                }
              }}
              onClick={(e) => {
                if (stopPropagation) {
                  e.stopPropagation();
                }
              }}
            >
              {children}
            </Box>
          </div>
        </Portal>
      ) : null}
    </Div>
  );
};

Dropdown.Select = ({ label = '', open, css: cssString, ...rest }) => (
  <Box
    css={`
      border-radius: 8px;
      border: 1px solid ${colors.gray[300]};
      padding: 8px;
      ${container.hover}
      ${cssString}
    `}
    flex="space-between"
    {...rest}
  >
    <Text
      label
      ellipsis
      css={`
        flex-grow: 1;
      `}
    >
      {label}
    </Text>
    {open ? <CaretUp size={24} /> : <CaretDown size={24} />}
  </Box>
);

Dropdown.Item = ({ children, onClick, css: cssStyle, className }) => {
  return (
    <Div
      flex="left"
      css={cx(
        css`
          gap: 8px;
          padding: 8px;
          border-radius: 8px;
          ${container.hover}
        `,
        css`
          ${cssStyle}
        `
      )}
      className={className}
      onClick={onClick}
    >
      <Text label>{children}</Text>
    </Div>
  );
};
