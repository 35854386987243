import { VideoCamera } from '@phosphor-icons/react';
import { Box, Text } from '../../../shared/components';
import { useVideoBlock } from './useVideoBlock';
import { colors } from '../../../shared/styles';
import { Toolbar } from '../../utility/Toolbar';
import { VideoToolbar } from './VideoToolbar';

export const VideoBlock = () => {
  const block = useVideoBlock();

  return (
    <>
      {!block?.src.value || !block?.validUrl ? (
        <Box
          flex="center column"
          css={`
            width: 100%;
            height: 400px;
            background-color: ${colors.gray[100]};
            border-radius: 8px;
          `}
        >
          <VideoCamera size={48} />
          {!block?.validUrl && !!block?.videoUrl.value ? (
            <Text color={colors.red[100]}>Invalid Video URL</Text>
          ) : (
            <Text styles="bold label">Video</Text>
          )}
        </Box>
      ) : (
        <iframe title="Video" {...block?.attributes} />
      )}
      <Toolbar isSelected={block?.isSelected} anchor={block?.anchor}>
        <VideoToolbar id={block?.id} src={block?.src} videoUrl={block?.videoUrl} />
      </Toolbar>
    </>
  );
};
