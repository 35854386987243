import { container } from '../../../shared/shared-styles';
import { colors } from '../../../shared/styles';

export const colorButtonStyles = (color, isSelected, size = '24px') => `
  transition: background-color 0.3s ease;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  min-width: ${size};
  width: ${size};
  height: ${size};
  border: none;
  background-color: ${color};
  outline: 1px solid rgba(50, 50, 50, .2);
  ${
    isSelected
      ? `outline: 2px solid ${colors.purple};`
      : `
    :hover {
      outline: 2px solid ${colors.purple};
    }  
  `
  }
  border-radius: 50%;
  padding: 8px;
  > div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: background-color 0.3s ease;
    background-color: ${color};
    
  }
`;

export const colorPickerDropdownStyles = `
  background-color: white;
  border-radius: 50%;
  padding: 0;
`;

export const selectableBox = (isSelected) => `
  flex-grow: 1;
  min-width: 40px;
  min-height: 40px;
  border-radius: 8px;
  border: 1px solid ${colors.gray[300]};
  outline-offset: -1px;
  outline: 2px solid ${isSelected ? colors.purple : 'transparent'};
  padding: 8px;
  ${container.hover}
  > *, h4 {
    color: ${isSelected ? colors.purple : colors.gray[600]};
    pointer-events: none;
  }
`;
