import { useRef } from 'react';
import { useEvent } from '../../../shared/use-event';
import { ToolKit } from '../../providers/tool-kit/ToolKit';
import { useToolKit } from '../../providers/tool-kit/use-tool-kit';
import { INPUT_DOM_ELEMENTS } from '../constants';
import { getScope } from '../editor-utils';
import { getKeydownAction, getKeyupAction, KEYDOWN_ACTIONS, KEYUP_ACTIONS } from './shortcuts';

const getMeta = (event) => {
  const HTMLElementType = document.activeElement.tagName;
  const scope = getScope(event);
  const isAvailable =
    scope === null &&
    !INPUT_DOM_ELEMENTS.includes(HTMLElementType) &&
    !document.activeElement?.getAttribute('data-slate-editor') &&
    window?.getSelection()?.isCollapsed;

  return {
    isAvailable,
    key: event.key,
    meta: event.metaKey,
    alt: event.altKey,
    ctrl: event.ctrlKey,
    shift: event.shiftKey,
    cmd: event.metaKey || event.ctrlKey,
    any: event.metaKey || event.altKey || event.ctrlKey || event.shiftKey,
  };
};

export const useEditorShortkeys = (sendUpdates) => {
  const meta = useRef({});
  const toolkit = useToolKit();

  const handleKeydown = (event) => {
    const keyboardMeta = getMeta(event);
    if (keyboardMeta?.isAvailable) {
      meta.current = keyboardMeta;
      const action = getKeydownAction(event, keyboardMeta);
      if (action in KEYDOWN_ACTIONS) {
        KEYDOWN_ACTIONS[action]({ toolkit, sendUpdates });
      }
    }
  };

  const handleKeyup = (event) => {
    if (meta?.current?.isAvailable) {
      const action = getKeyupAction(event, meta?.current);
      if (action in KEYUP_ACTIONS) {
        KEYUP_ACTIONS[action]({ toolkit, sendUpdates });
      }
    }
  };

  useEvent('keydown', handleKeydown);
  useEvent('keyup', handleKeyup);
};
