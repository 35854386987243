import { Slate } from 'slate-react';
import { usePropertyManager } from '../../providers/property-manager/PropertyManager';
import { SlateDecoration } from '../components/slate-properties/SlateDecoration';
import { SlateHorizontalAlign } from '../components/slate-properties/SlateHorizontalAlign';
import { SlateColorPicker } from '../components/slate-properties/SlateColorPicker';
import { SlateLink } from '../components/slate-properties/SlateLink';
import { Box, Text } from '../../../shared/components';
import { getBgColorProperties, getPaddingProperties, getRadiusProperties } from './property-utils';
import { ColorSetting } from '../components/ColorSetting';
import { RadiusSetting } from '../components/RadiusSetting';
import { PaddingSetting } from '../components/PaddingSetting';
import { SlateList } from '../components/slate-properties/SlateList';

export const TextSettings = ({ designStyles, ...rest }) => {
  const { properties } = usePropertyManager();

  const bgColor = getBgColorProperties({ ...rest });
  const radius = getRadiusProperties({ ...rest, type: 'container' });
  const padding = getPaddingProperties({ ...rest });

  if (!Object.keys(properties || {}).length) {
    return null;
  }

  return (
    <Slate {...properties}>
      <Box
        css={`
          display: grid;
          gap: 24px;
        `}
      >
        <SlateColorPicker {...rest} />
        <SlateHorizontalAlign />
        <SlateList />
        <SlateDecoration />
        <PaddingSetting value={padding.value} setValue={padding.setValue} />
        <SlateLink {...rest} />
        <Text label bold>
          Background
        </Text>
        <ColorSetting label="Background Color" value={bgColor.value} setValue={bgColor.setValue} />
        <RadiusSetting label="Radius" {...radius} />
      </Box>
    </Slate>
  );
};
