import { SmartFieldInput } from '../../../../BriteEditor/utility-components/smart-field-input';
import { Text } from '../../../../shared/components';
import { safelySpread } from '../../../providers/content/content-modify';

export const SendSMS = ({ value, setValue }) => {
  const updateActionData = (actionData) => {
    setValue({
      ...value,
      action: {
        ...safelySpread(value.action),
        data: {
          ...safelySpread(value.action.data),
          ...actionData,
        },
      },
    });
  };

  const handleSmartField = ({ data }) => {
    setValue({
      ...value,
      action: {
        ...value?.action,
        smartFieldDisplay: data.name,
        data: {
          ...value.action.data,
          sendToNumber: data.value,
        },
      },
    });
  };

  const removeSmartField = () => updateActionData({ sendToNumber: '' });

  return (
    <>
      <Text
        css={`
          margin-top: 16px;
          margin-bottom: 8px;
        `}
        label
      >
        Send to Number
      </Text>
      <SmartFieldInput
        value={value?.action?.data?.sendToNumber}
        smartFieldDisplay={value?.action?.smartFieldDisplay}
        handleSmartField={handleSmartField}
        removeSmartField={removeSmartField}
        inputProps={{
          format: 'phone',
          value: value?.action?.data?.sendToNumber,
          onChange: (e) => updateActionData({ sendToNumber: e.target.value }),
        }}
        nameFilter="phone"
      />
    </>
  );
};
