import { colors } from '../styles';
import { Box } from './box';
import { Text } from './text';

export const Pill = ({ isSelected = false, text = 'Pill', onClick = () => {} }) => {
  return (
    <Box
      css={`
        display: flex;
        align-items: center;
        padding: 14px 16px;
        border-radius: 24px;
        border: 2px solid ${isSelected ? `var(--700, ${colors.black})` : 'transparent'};
        background: ${isSelected ? `var(--100, ${colors.gray[100]})` : '#FFFFFF'};
        cursor: pointer;
        position: relative;
        transition: border 0.3s ease, background 0.3s ease;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: inherit;
          border: 1px solid ${isSelected ? 'transparent' : `var(--300, ${colors.gray[300]})`};
          pointer-events: none;
          transition: border 0.3s ease;
        }
      `}
      onClick={() => onClick()}
    >
      <Text
        caption
        css={`
          font-weight: 700;
          user-select: none;
          text-wrap: nowrap;
        `}
        color={colors.black}
      >
        {text}
      </Text>
    </Box>
  );
};
