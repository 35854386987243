import { getParentAction, updateAction } from './action-utils';
import { addBlockToAdjacentBlock } from './block-utils';
import { addBlockToAdjacentColumn } from './column-utils';
import { addBlockToAdjacentRow } from './row-utils';
import { addBlockToAdjacentSection } from './section-utils';

export const compositionTypes = ['section', 'row', 'column'];

export const getComposition = (content) => {
  const composition = {};
  composition._content = content;
  return composition;
};

export const compileCompositionActions = (composition) => {
  const { _content, ...rest } = composition;
  return Object.values(rest)
    .reduce((prev, action) => {
      const { type, data, ...rest } = action;
      if (!!type) {
        return [...prev, { type, ...rest }];
      }
      return prev;
    }, [])
    .sort((a, b) => b.createdAt - a.createdAt);
};

export const addToComposition = (props) => {
  if (!compositionTypes.includes(props.destination.data.type)) {
    addBlockToAdjacentBlock(props);
  } else if (props.destination.data.type === 'section') {
    addBlockToAdjacentSection(props);
  } else if (props.destination.data.type === 'row') {
    addBlockToAdjacentRow(props);
  } else if (props.destination.data.type === 'column') {
    addBlockToAdjacentColumn(props);
  }
};

export const removeFromComposition = (composition, action) => {
  const parent = getParentAction(composition, action?.id);
  const children = parent.data.children.filter((child) => child.id !== action.id);
  updateAction(composition, parent.id, 'update', { children });
};
