import * as uuid from 'uuid';
import { addChild, getChildFromAction } from './child-utils';
import { attachAction, getParentAction, insertAction, updateAction } from './action-utils';

export const createColumnAction = (parentId, children = []) => {
  const id = uuid.v4();
  const column = {
    parentId,
    children,
    id,
    type: 'column',
    properties: {},
  };

  const action = {
    id,
    data: column,
    old: null,
    updates: column,
    property: 'data',
    type: 'insert',
  };

  return action;
};

const removeActionWidths = (composition, children) => {
  for (const child of children) {
    if (child.id in composition) {
      const action = composition[child.id];
      const { width, ...properties } = action.data.properties;
      updateAction(composition, child.id, 'update', { properties });
    }
  }
};

export const addBlockToAdjacentColumn = ({ composition, source, destination, edge }) => {
  const parent = getParentAction(composition, destination?.id);
  attachAction(composition, parent);
  const child = getChildFromAction(source);
  const column = createColumnAction(parent.data.id, [child]);
  insertAction(composition, column);
  updateAction(composition, source.id, 'update', { parentId: column.id });
  const columnChild = getChildFromAction(column);
  let children = addChild(columnChild, parent.data.children, {
    edge,
    id: destination.data.id,
  });
  updateAction(composition, parent.id, 'update', { children });
  removeActionWidths(composition, children);
};
