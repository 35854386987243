import { css } from '@emotion/css';
import { colors } from '../../../shared/styles';
import { Editor, Transforms } from 'slate';

export const inlineElements = ['link', 'smart-field'];

export const withInlines = (editor) => {
  const { isInline } = editor;
  editor.isInline = (element) => inlineElements.includes(element.type) || isInline(element);
  return editor;
};

export const slateContainer = css`
  outline: none;
  width: 100%;
  cursor: text;
  p {
    margin: 0;
    padding: 0;
    transition: text-transform 0.2s ease;
  }
  .center-element {
    background-color: ${colors.gray[200]};
    border-radius: 8px;
    padding: 8px;
    margin: 0;
    width: 100%;
  }
`;

export const updateContent = (editor, slateNodes) => {
  Transforms.delete(editor, {
    at: {
      anchor: Editor.start(editor, []),
      focus: Editor.end(editor, []),
    },
  });

  if (editor?.children?.length) {
    Transforms.removeNodes(editor, { at: [0] });
  }

  Transforms.insertNodes(editor, slateNodes);
};
