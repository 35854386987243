import { X } from '@phosphor-icons/react';
import { Box, Button, Modal, Text } from '../../../shared/components';
import { colors } from '../../../shared/styles';
import { useModal } from '../../../common/hooks/useModal';
import { ReactComponent as SmartImage } from '../../../images/smart-image.svg';

export const ImageErrors = ({ imageStatus }) => {
  const modal = useModal();

  return (
    <>
      {!imageStatus?.smartImageLocationExists ? (
        <ImageError title="Smart Image" description="Image location does not exist." modal={modal} />
      ) : !imageStatus?.smartImageHasValue ? (
        <ImageError title="Smart Image" description="No image within location." modal={modal} />
      ) : imageStatus?.invalidImageUrl ? (
        <ImageError title="Image" description="Invalid Image URL" />
      ) : !imageStatus?.imageCanLoad ? (
        <ImageError title="Image" description="Image failed to load" />
      ) : null}
      <Modal display={modal.isOpen} onClose={() => modal.hide()}>
        <Modal.Paper
          css={`
            border: 3px solid ${colors.purple};
            border-radius: 16px;
          `}
        >
          <Box flex="space-between">
            <Box
              flex="left"
              css={`
                .img {
                  min-width: 32px;
                  min-height: 32px;
                }
                gap: 16px;
              `}
            >
              <SmartImage className="img" />
              <Text h4>Smart image</Text>
            </Box>
            <Button styles="icon sm" onClick={() => modal.hide()}>
              <X />
            </Button>
          </Box>
          <Modal.Body
            css={`
              display: grid;
              gap: 16px;
              margin-top: 16px;
              ul {
                margin: 0;
                margin-left: 16px;
                padding: 0;
              }
              li::marker {
                color: ${colors.gray[500]};
              }
            `}
          >
            <Text>
              Smart images are images in a guide that are connected to our library of carriers, and company images.
            </Text>
            <Text label bold>
              Smart Image Help
            </Text>
            <ul>
              <li>
                <Text caption>
                  <strong>No image within location:</strong> Check the product or label this image is connected to.
                </Text>
              </li>

              <li>
                <Text caption>
                  <strong>Image location does not exist:</strong> The smart image is not connected to an existing
                  product or label.
                </Text>
              </li>

              <li>
                <Text caption>
                  <strong>Invalid Image URL:</strong> The image url is invalid and needs to be corrected.
                </Text>
              </li>
            </ul>
          </Modal.Body>
        </Modal.Paper>
      </Modal>
    </>
  );
};

const ImageError = ({ title, description, modal = null }) => (
  <>
    <Text
      label
      bold
      css={`
        padding-top: 8px;
        text-align: center;
      `}
    >
      {title}
    </Text>
    <Text
      label
      css={`
        text-align: center;
        padding: 0 32px;
      `}
    >
      {description} {modal !== null ? <a onClick={modal.toggle}>Learn More</a> : null}
    </Text>
  </>
);
