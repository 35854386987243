import { useCallback } from 'react';
import { ComponentIconMap } from '../../BriteEditor/toolbar-menus';
import { Box, Text } from '../../shared/components';
import { container } from '../../shared/shared-styles';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { DROP_CREATE_BLOCK, TOOL_TYPE_MAP } from '../utility/constants';

const containerStyle = `
  margin: 16px;
  > p {
    margin: 8px 0;
    margin-bottom: 16px;
  }
`;

export const Tools = () => {
  const toolkit = useToolKit();

  const handleDrag = (event, type) => {
    event.preventDefault();
    ToolKit.startDrag(toolkit, {
      type: DROP_CREATE_BLOCK,
      toolType: type,
    });
  };

  const Tool = useCallback(
    ({ type }) => {
      return (
        <Box
          flex="left"
          css={`
            gap: 16px;
            padding: 8px;
            border-radius: 8px;
            ${container.hover}
            ${type in TOOL_TYPE_MAP ? '' : 'opacity: .5; pointer-events: none;'}
          `}
          draggable={true}
          onDragStart={(e) => handleDrag(e, type)}
        >
          <ComponentIconMap type={type} size={24} />
          <Text label>{TOOL_TYPE_MAP?.[type]}</Text>
        </Box>
      );
    },
    [handleDrag]
  );

  return (
    <Box
      css={`
        user-select: none;
        width: 100%;
      `}
    >
      <Box css={containerStyle}>
        <Text label bold>
          Basic
        </Text>
        <Tool type="section" />
      </Box>

      <Box css={containerStyle}>
        <Text label bold>
          Elements
        </Text>
        <Tool type="textV2" />
        <Tool type="button" />
        <Tool type="image" />
        <Tool type="space" />
        <Tool type="document" />
        <Tool type="video" />
        <Tool type="website" />
      </Box>

      <Box css={containerStyle}>
        <Text label bold>
          Components
        </Text>
        <Tool type="accordion" />
        <Tool type="benefits" />
        <Tool type="pricing" />
        <Tool type="network" />
      </Box>
    </Box>
  );
};
