import { Content } from './Content';

export const safelySpread = (object) => ({ ...(object || {}) });

const modifyStyle = (sendUpdates, id, styles) => {
  Content.update(sendUpdates, id, (data) => ({
    properties: {
      ...safelySpread(data.properties),
      attributes: {
        ...safelySpread(data.properties.attributes),
        style: {
          ...safelySpread(data.properties.attributes.style),
          ...styles,
        },
      },
    },
  }));
};

const modifyContainerStyle = (sendUpdates, id, styles) => {
  Content.update(sendUpdates, id, (data) => ({
    properties: {
      ...safelySpread(data?.properties),
      container: {
        ...safelySpread(data?.properties?.container),
        attributes: {
          ...safelySpread(data?.properties?.attributes),
          style: {
            ...safelySpread(data?.properties?.attributes?.style),
            ...styles,
          },
        },
      },
    },
  }));
};

const modifyAttributes = (sendUpdates, id, attributes) => {
  Content.update(sendUpdates, id, (data) => ({
    properties: {
      ...safelySpread(data.properties),
      attributes: {
        ...safelySpread(data.properties.attributes),
        ...attributes,
      },
    },
  }));
};

const modifyProperties = (sendUpdates, id, updateFn) => {
  Content.update(sendUpdates, id, (data) => ({
    properties: {
      ...safelySpread(data.properties),
      ...updateFn(data.properties || {}),
    },
  }));
};

const modifyMerge = (sendUpdates, id, updateFn) => Content.update(sendUpdates, id, updateFn);

export const Modify = {
  attributes: modifyAttributes,
  containerStyle: modifyContainerStyle,
  properties: modifyProperties,
  style: modifyStyle,
  merge: modifyMerge,
};
