import { Tooltip } from '../../../common/components/Tooltip';
import { Box, Button, Dropdown, Text } from '../../../shared/components';
import { ToolKit } from '../../providers/tool-kit/ToolKit';
import { useToolKit } from '../../providers/tool-kit/use-tool-kit';
import { COLOR_MAP } from '../constants';
import { colorButtonStyles, colorPickerDropdownStyles } from './styles';
import { useEditorResource } from '../../../BriteEditor/use-editor-resource';
import { useMemo } from 'react';
import { useSync } from '../useSync';
import { ChartPie, Plus, Prohibit } from '@phosphor-icons/react';
import { colors } from '../../../shared/styles';
import { useModal } from '../../../common/hooks/useModal';
import { CreateColorModal } from './CreateColorModal';

export const ColorPicker = ({ color, update, label }) => {
  const toolkit = useToolKit();

  const modal = useModal();

  const guide = ToolKit.getMetadata(toolkit, 'guide');
  const { data: designStyles } = useEditorResource('styles');
  const colorPalette = designStyles?.general?.colorPalette;

  const guideColors = useSync(guide?.data?.ColorPickerPresets || []);

  const getPreviewColor = () => {
    if (color.startsWith('var(--')) {
      const key = color?.replace('var(--', '')?.replace(')', '');
      return colorPalette[key] || key;
    }
    return color;
  };

  const designStyleColors = useMemo(() => {
    const list = Object.entries(colorPalette || {}).map(([name, value]) => ({ name, value }));
    return list.sort((a, b) => a.name.localeCompare(b.name));
  }, []);

  const previewColor = useMemo(() => {
    return getPreviewColor();
  }, [color, colorPalette]);

  const updateDesignStyleColor = (colorName) => update(`var(--${colorName})`);
  const updateGuideColor = (color) => update(color);

  const addGuideColor = (color) => {
    const colors = guideColors.value || [];
    colors.push(color);
    guide.save({ ColorPickerPresets: colors });
    guideColors.set(colors);
    updateGuideColor(color);
    modal.hide();
  };

  return (
    <>
      <Dropdown
        button={() => (
          <Tooltip label={label}>
            <Box
              css={`
                border-radius: 50%;
                padding: 0;
                border: 1px solid white;
              `}
            >
              <Color color={previewColor} />
            </Box>
          </Tooltip>
        )}
        css={colorPickerDropdownStyles}
        stopPropagation
      >
        <Box
          data-scope="menu"
          css={`
            padding: 8px;
          `}
        >
          <Box
            flex="space-between"
            css={`
              padding: 8px;
              width: 250px;
            `}
          >
            <Text label>Design Style Colors</Text>
            <Button link>Edit</Button>
          </Box>
          <Box
            flex="left wrap"
            css={`
              gap: 8px;
              width: 250px;
              padding: 8px;
            `}
          >
            <Color color="rgba(0, 0, 0, 0)" label="Transparent" onClick={() => update('rgba(0, 0, 0, 0)')} />
            {designStyleColors?.map((item) => (
              <Color
                key={item.name}
                color={item.value}
                label={COLOR_MAP[item.name] || item.name}
                onClick={() => updateDesignStyleColor(item.name)}
                isCurrentColor={color === `var(--${item.name})` || color === item.name}
              />
            ))}
          </Box>
          <Box
            flex="space-between"
            css={`
              padding: 8px;
              padding-top: 16px;
              width: 250px;
            `}
          >
            <Text label>Guide Colors</Text>
            <Button
              styles="icon sm"
              css={`
                width: 28px;
                height: 28px;
                padding: 4px;
                border: 1px solid ${colors.gray[300]};
              `}
              onClick={() => modal.toggle()}
            >
              <Plus weight="bold" />
            </Button>
          </Box>
          <Box
            flex="left wrap"
            css={`
              gap: 8px;
              width: 250px;
              padding: 8px;
            `}
          >
            {guideColors?.value?.map((item, idx) => (
              <Color
                key={idx + item}
                color={item}
                isCurrentColor={color.includes(item)}
                onClick={() => updateGuideColor(item)}
              />
            ))}
          </Box>
        </Box>
      </Dropdown>
      {modal.isOpen ? <CreateColorModal onClose={modal.hide} addGuideColor={addGuideColor} /> : null}
    </>
  );
};

export const Color = ({ color, isCurrentColor, label, onClick, size }) => {
  return (
    <Tooltip label={label}>
      <Button css={colorButtonStyles(color, isCurrentColor, size)} onClick={onClick}>
        <Box>{color === 'rgba(0, 0, 0, 0)' ? <Prohibit size={24} /> : null}</Box>
      </Button>
    </Tooltip>
  );
};
