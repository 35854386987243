import { useEffect } from 'react';
import { useDebounce } from '../../shared/use-debounce';

export const useDelayedSync = (sync, fn, delay) => {
  const debounced = useDebounce(fn, delay);
  useEffect(() => {
    if (!sync.isSynced) {
      debounced(sync.value);
    }
  }, [sync.value, sync.isSynced]);
};
