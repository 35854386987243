import { useMemo, useState } from 'react';
import { Box } from '../../shared/components';
import { colors } from '../../shared/styles';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { DragHandle } from './settings/DragHandle';
import { overlay } from './styles';
import { usePopper } from 'react-popper';
import { useResize } from '../../BriteEditor/editor-components/use-resize';
import { Conditional } from './Conditional';

export const MultiSelection = () => {
  const toolkit = useToolKit();
  const hasMultiSelection = ToolKit.hasMultiSelection(toolkit);
  return (
    <Conditional display={hasMultiSelection}>
      <MultiSelectionComponent />
    </Conditional>
  );
};

export const MultiSelectionComponent = () => {
  const toolkit = useToolKit();
  const selection = ToolKit.getSelection(toolkit);

  const [anchor, setAnchor] = useState();
  const [popper, setPopper] = useState();

  const popperProps = usePopper(anchor, popper, { placement: 'right' });
  useResize(anchor, () => popperProps?.update?.());

  const position = useMemo(() => {
    if (selection?.ids?.length && selection?.type === 'multi') {
      const rect = Object.values(selection?.bounds || {}).reduce((prev, data) => {
        return {
          top: Math.min(prev.top || data.top, data.top),
          left: Math.min(prev.left || data.left, data.left),
          right: Math.max(prev.right || data.right, data.right),
          bottom: Math.max(prev.bottom || data.bottom, data.bottom),
        };
      }, {});

      const parent = anchor?.parentElement;
      const bounds = parent?.getBoundingClientRect();

      return {
        top: rect.top - bounds?.top,
        left: rect.left - bounds?.left,
        right: bounds.right - rect.right,
        bottom: bounds.bottom - rect.bottom,
      };
    }
    return {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    };
  }, [anchor, selection.at]);

  return (
    <Box
      css={`
        position: absolute;
        margin: -8px;
        top: ${position?.top}px;
        left: ${position?.left}px;
        right: ${position?.right}px;
        bottom: ${position?.bottom}px;
        outline-offset: 2px;
        border-radius: 4px;
        outline: 1px dashed ${colors.black};
        background-color: ${colors.lighterPurple}22;
        pointer-events: none;
        z-index: 1000000;
      `}
      ref={setAnchor}
    >
      <Box
        css={`
          ${overlay}
          margin: 16px;
        `}
        ref={setPopper}
        {...popperProps?.attributes?.popper}
        style={popperProps?.styles?.popper}
      >
        <DragHandle label="Move Row" onDrag={() => {}} />
      </Box>
    </Box>
  );
};
