import { createContext, useContext, useState } from 'react';

const PropertyContext = createContext();

// This context is only to be used as a communication
// between "isSelected" components (ie, Toolbars) and
// the PropertiesPanel components.
export const PropertyManager = ({ children }) => {
  const [properties, setProperties] = useState();
  return <PropertyContext.Provider value={{ properties, setProperties }}>{children}</PropertyContext.Provider>;
};

export const usePropertyManager = () => useContext(PropertyContext);
