import { Editor, Range, Transforms } from 'slate';
import { toggleInline } from '../../../BriteEditor/editor-components/text-v2/slate-utils';

export const updateInline = (slate, type) => {
  if (!slate?.selection || Range.isCollapsed(slate?.selection)) {
    if (slate?.children?.length > 0) {
      Transforms.select(slate, {
        anchor: Editor.start(slate, []),
        focus: Editor.end(slate, []),
      });
    }
  }
  toggleInline(slate, type);
};
