import { ReactComponent as DashedLine } from '../../../images/svg/dashed-line.svg';
import { Tooltip } from '../../../common/components/Tooltip';
import { Box, Text } from '../../../shared/components';
import { colors } from '../../../shared/styles';
import { Toolbar } from '../../utility/Toolbar';
import { SpaceToolbar } from './SpaceToolbar';
import { useSpaceBlock } from './useSpaceBlock';

export const SpaceBlock = () => {
  const block = useSpaceBlock();

  return (
    <>
      <Box
        css={`
          position: relative;
          overflow: hidden;
          transition: border-radius 0.2s ease;
        `}
        flex="center"
        style={block.styles}
      >
        {block?.hasPageBreak ? <PageBreakSpace block={block} /> : <DefaultSpace block={block} />}
      </Box>
      <Toolbar isSelected={block.isSelected} anchor={block.anchor}>
        <SpaceToolbar id={block.id} height={block.height} bgColor={block.bgColor} breakAfter={block.breakAfter} />
      </Toolbar>
    </>
  );
};

const DefaultSpace = ({ block }) =>
  block?.isSelected ? (
    <Tooltip label={`Min Height ${block.height.value}`}>
      <Box
        css={`
          outline: 1px dotted rgba(200, 200, 200, 0.7);
          width: 100%;
          height: ${block.height.value}px;
          :hover {
            background-color: rgba(150, 150, 150, 0.1);
          }
        `}
      />
    </Tooltip>
  ) : null;

const PageBreakSpace = ({ block }) => {
  const Content = () => (
    <>
      <DashedLine />
      <Text
        helper
        bold
        color={colors.gray[400]}
        css={`
          white-space: nowrap;
        `}
      >
        Page Break
      </Text>
      <DashedLine />
    </>
  );

  return block?.isSelected ? (
    <Tooltip label="Page breaks only affect exported guides.">
      <Box
        flex="center"
        css={`
          width: 100%;
          height: 100%;
          gap: 8px;
        `}
      >
        <Content />
      </Box>
    </Tooltip>
  ) : (
    <Box
      css={`
        gap: 8px;
      `}
      flex="center"
    >
      <Content />
    </Box>
  );
};
