import { orderBy } from 'lodash';

/* FILTERING */
export const OPEN_ENROLLMENT_FILTER_TYPE = 'openenrollment';
export const RECRUITING_FILTER_TYPE = 'recruiting';
export const NEW_HIRE_FILTER_TYPE = 'newhire';
export const OTHER_FILTER_TYPE = 'other';

export const filterTypes = [
  OPEN_ENROLLMENT_FILTER_TYPE,
  RECRUITING_FILTER_TYPE,
  NEW_HIRE_FILTER_TYPE,
  OTHER_FILTER_TYPE,
];

export const getGuideType = ({ type = '' }) => {
  switch (type) {
    case OPEN_ENROLLMENT_FILTER_TYPE:
      return 'Open Enrollment';
    case RECRUITING_FILTER_TYPE:
      return 'Recruiting';
    case NEW_HIRE_FILTER_TYPE:
      return 'New Hire';
    case OTHER_FILTER_TYPE:
      return 'Other';
    default:
      return 'Unknown';
  }
};

/* SORTING */
export const MOST_VIEWS_SORT_TYPE = 'mostviews';
export const LEAST_VIEWS_SORT_TYPE = 'leastviews';
export const A_TO_Z_SORT_TYPE = 'atoz';
export const Z_TO_A_SORT_TYPE = 'ztoa';

export const sortTypes = [MOST_VIEWS_SORT_TYPE, LEAST_VIEWS_SORT_TYPE, A_TO_Z_SORT_TYPE, Z_TO_A_SORT_TYPE];

export const getSortText = ({ type = '' }) => {
  switch (type) {
    case MOST_VIEWS_SORT_TYPE:
      return 'Most Viewed';
    case LEAST_VIEWS_SORT_TYPE:
      return 'Least Viewed';
    case A_TO_Z_SORT_TYPE:
      return 'Alphabetical (A to Z)';
    case Z_TO_A_SORT_TYPE:
      return 'Alphabetical (Z to A)';
    default:
      return 'Unknown';
  }
};

export const sortGuidesByType = ({ guides = [], type = '' }) => {
  switch (type) {
    case MOST_VIEWS_SORT_TYPE:
      return orderBy(guides, ['Views'], ['desc']);
    case LEAST_VIEWS_SORT_TYPE:
      return orderBy(guides, ['Views']);
    case A_TO_Z_SORT_TYPE:
      return orderBy(guides, ['Name'], ['asc']);
    case Z_TO_A_SORT_TYPE:
      return orderBy(guides, ['Name'], ['desc']);
    default:
      return guides;
  }
};
