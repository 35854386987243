import { useEditorResource } from '../../../BriteEditor/use-editor-resource';
import { Segment } from '../../providers/segment/Segment';
import { useSmartField } from '../../utility/editor-utils';
import { useBlock } from '../../utility/useBlock';
import { useSync } from '../../utility/useSync';

export const useButtonBlock = () => {
  const block = useBlock();

  const { data: designStyles } = useEditorResource('styles');
  const properties = Segment.getProperties(block?.segment);

  const buttonSize = properties?.buttonSize || '';
  const { value } = useSmartField(properties?.content);

  const sync = useSync({
    content: value,
  });

  const style = {
    ...designStyles?.button,
    ...(properties?.attributes?.style || {}),
  };

  return {
    ...block,
    style,
    sync,
    buttonSize,
  };
};
