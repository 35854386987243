import { Newspaper } from '@phosphor-icons/react';
import { Box, Text } from '../../../shared/components';
import { useWebsiteBlock } from './useWebsiteBlock';
import { colors } from '../../../shared/styles';
import { Toolbar } from '../../utility/Toolbar';
import { WebsiteToolbar } from './WebsiteToolbar';

export const WebsiteBlock = () => {
  const block = useWebsiteBlock();

  const iframeAttributes = {
    ...block.attributes,
    src: block.src.value,
    height: block.height.value,
  };

  return (
    <>
      {block?.validUrl ? (
        <iframe {...iframeAttributes} title="Website" />
      ) : (
        <Box
          css={`
            background-color: ${colors.gray[100]};
            border-radius: 8px;
            height: 500px;
            width: 100%;
          `}
          flex="center column"
        >
          <Newspaper size={48} />
          {!block.validUrl && !!block?.src?.value ? (
            <Text color={colors.red[100]}>Invalid Website URL</Text>
          ) : (
            <Text styles="bold label">Website</Text>
          )}
        </Box>
      )}
      <Toolbar isSelected={block.isSelected} anchor={block.anchor}>
        <WebsiteToolbar
          id={block.id}
          src={block?.src}
          height={block?.height}
          linkText={block?.linkText}
          validUrl={block?.validUrl}
        />
      </Toolbar>
    </>
  );
};
