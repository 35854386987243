import { SmartFieldInput } from '../../../../BriteEditor/utility-components/smart-field-input';
import { Input, Text } from '../../../../shared/components';
import { safelySpread } from '../../../providers/content/content-modify';

export const SendEmail = ({ value, setValue }) => {
  const updateActionData = (actionData) => {
    setValue({
      ...value,
      action: {
        ...safelySpread(value.action),
        data: {
          ...safelySpread(value.action.data),
          ...actionData,
        },
      },
    });
  };

  const handleSmartField = ({ data }) => {
    setValue({
      ...value,
      action: {
        ...value?.action,
        smartFieldDisplay: data.name,
        data: {
          ...value.action.data,
          sendToEmail: data.value,
        },
      },
    });
  };

  const removeSmartField = () => updateActionData({ sendToEmail: '' });

  return (
    <>
      <Text
        css={`
          margin-top: 16px;
          margin-bottom: 8px;
        `}
        label
      >
        Send to Email
      </Text>
      <SmartFieldInput
        value={value?.action?.data?.sendToEmail}
        smartFieldDisplay={value?.action?.smartFieldDisplay}
        handleSmartField={handleSmartField}
        removeSmartField={removeSmartField}
        inputProps={{
          value: value?.action?.data?.sendToEmail,
          onChange: (e) => updateActionData({ sendToEmail: e.target.value }),
        }}
        nameFilter="email"
      />

      <Text
        css={`
          margin-top: 16px;
          margin-bottom: 8px;
        `}
        label
      >
        Subject
      </Text>
      <Input
        css={`
          width: 100%;
        `}
        value={value?.action?.data?.subject}
        onChange={(e) => updateActionData({ subject: e.target.value })}
      />

      <Text
        css={`
          margin-top: 16px;
          margin-bottom: 8px;
        `}
        label
      >
        Body
      </Text>
      <Input
        css={`
          width: 100%;
        `}
        rows={4}
        value={value?.action?.data?.body}
        onChange={(e) => updateActionData({ body: e.target.value })}
      />
    </>
  );
};
