import { safelySpread } from '../../providers/content/content-modify';

export const getWidthProperties = ({ data, update, designStyles }) => {
  const rawWidth = data?.properties?.attributes?.style?.width || designStyles?.image?.width;
  const width = Number(rawWidth.replace(/\D/g, '') || 0);

  return {
    update,
    value: width,
    setValue: (value) =>
      update((data) => ({
        ...safelySpread(data),
        properties: {
          ...safelySpread(data.properties),
          attributes: {
            ...safelySpread(data.properties.attributes),
            style: {
              ...safelySpread(data.properties.attributes.style),
              width: `${value}%`,
            },
          },
        },
      })),
  };
};

export const getAlignProperties = ({ data, update, alignKey }) => {
  const defaultValue = alignKey === 'justifyContent' ? 'left' : 'start';
  const align = data?.properties?.container?.attributes?.style?.[alignKey] || defaultValue;
  return {
    update,
    value: align,
    setValue: (value) =>
      update((data) => {
        return {
          ...safelySpread(data),
          properties: {
            ...safelySpread(data.properties),
            container: {
              ...safelySpread(data?.properties?.container),
              attributes: {
                ...safelySpread(data?.properties?.container?.attributes),
                style: {
                  ...safelySpread(data?.properties?.container?.attributes?.style),
                  display: 'flex',
                  [alignKey]: value,
                },
              },
            },
          },
        };
      }),
  };
};

export const getActionProperties = ({ data, update }) => {
  return {
    update,
    value: {
      link: data?.properties?.link || null,
      action: data?.properties?.action || {},
    },
    setValue: (value) =>
      update((data) => ({
        ...safelySpread(data),
        properties: {
          ...safelySpread(data.properties),
          action: value.action,
          link: value.link,
        },
      })),
  };
};

export const getPaddingProperties = ({ data, update, type = 'container' }) => {
  const padding = {
    paddingTop: data?.properties?.container?.attributes?.style?.paddingTop || '0px',
    paddingBottom: data?.properties?.container?.attributes?.style?.paddingBottom || '0px',
    paddingLeft: data?.properties?.container?.attributes?.style?.paddingLeft || '0px',
    paddingRight: data?.properties?.container?.attributes?.style?.paddingRight || '0px',
  };
  return {
    update,
    value: padding,
    setValue: (key, value) =>
      update((data) => {
        let padding = {};
        if (key === 'all') {
          padding = {
            paddingLeft: value,
            paddingRight: value,
            paddingTop: value,
            paddingBottom: value,
          };
        } else {
          padding[key] = value;
        }

        return type === 'container'
          ? {
              ...safelySpread(data),
              properties: {
                ...safelySpread(data.properties),
                container: {
                  ...safelySpread(data.properties.container),
                  attributes: {
                    ...safelySpread(data.properties.container.attributes),
                    style: {
                      ...safelySpread(data.properties.container.attributes.style),
                      ...padding,
                    },
                  },
                },
              },
            }
          : type === 'block'
          ? {
              ...safelySpread(data),
              properties: {
                ...safelySpread(data.properties),
                attributes: {
                  ...safelySpread(data.properties.attributes),
                  style: {
                    ...safelySpread(data.properties.attributes.style),
                    ...padding,
                  },
                },
              },
            }
          : {};
      }),
  };
};

export const getRadiusProperties = ({ data, update, type }) => {
  const styleReference =
    type === 'block'
      ? data?.properties?.attributes?.style
      : type === 'container'
      ? data?.properties?.container?.attributes?.style
      : {};

  const radius = {
    borderTopLeftRadius: styleReference?.borderTopLeftRadius || '0px',
    borderTopRightRadius: styleReference?.borderTopRightRadius || '0px',
    borderBottomLeftRadius: styleReference?.borderBottomLeftRadius || '0px',
    borderBottomRightRadius: styleReference?.borderBottomRightRadius || '0px',
  };

  return {
    update,
    value: radius,
    setValue: (key, value) =>
      update((data) => {
        let radius = {};
        if (key === 'all') {
          radius = {
            borderTopLeftRadius: value,
            borderTopRightRadius: value,
            borderBottomLeftRadius: value,
            borderBottomRightRadius: value,
          };
        } else {
          radius[key] = value;
        }

        return type === 'block'
          ? {
              ...safelySpread(data),
              properties: {
                ...safelySpread(data.properties),
                attributes: {
                  ...safelySpread(data.properties.attributes),
                  style: {
                    ...safelySpread(data.properties.attributes.style),
                    ...radius,
                  },
                },
              },
            }
          : type === 'container'
          ? {
              ...safelySpread(data),
              properties: {
                ...safelySpread(data.properties),
                container: {
                  ...safelySpread(data.properties.container),
                  attributes: {
                    ...safelySpread(data.properties.container.attributes),
                    style: {
                      ...safelySpread(data.properties.container.attributes.style),
                      ...radius,
                    },
                  },
                },
              },
            }
          : type === 'all'
          ? {
              ...safelySpread(data),
              properties: {
                ...safelySpread(data.properties),
                attributes: {
                  ...safelySpread(data.properties.attributes),
                  style: {
                    ...safelySpread(data.properties.attributes.style),
                    ...radius,
                  },
                },
                container: {
                  ...safelySpread(data.properties.container),
                  attributes: {
                    ...safelySpread(data.properties.container.attributes),
                    style: {
                      ...safelySpread(data.properties.container.attributes.style),
                      ...radius,
                    },
                  },
                },
              },
            }
          : {};
      }),
  };
};

export const getBgColorProperties = ({ data, update }) => {
  return {
    update,
    value: data?.properties?.container?.attributes?.style?.backgroundColor || 'rgba(0, 0, 0, 0);',
    setValue: (value) =>
      update((data) => ({
        ...safelySpread(data),
        properties: {
          ...safelySpread(data.properties),
          container: {
            ...safelySpread(data.properties.container),
            attributes: {
              ...safelySpread(data.properties.container.attributes),
              style: {
                ...safelySpread(data.properties.container.attributes.style),
                backgroundColor: value,
              },
            },
          },
        },
      })),
  };
};

export const getNumber = (value) => {
  try {
    return parseInt(value.replace(/\D/g, ''), 10) || 0;
  } catch {
    return 0;
  }
};
