import * as uuid from 'uuid';
import { Box } from '../../../shared/components';
import { useRef } from 'react';

export const Property = ({ children, ...rest }) => {
  const propertyId = useRef(uuid.v4());
  const css = `
    display: grid;
    gap: 8px;
    ${rest.css}
  `;
  return (
    <Box data-property-id={propertyId.current} {...rest} css={css}>
      {children}
    </Box>
  );
};
