import { usePopper } from 'react-popper';
import { useResize } from '../../BriteEditor/editor-components/use-resize';
import { Box, Button } from '../../shared/components';
import { Portal } from '../../shared/components/Portal';
import { shadows } from '../../shared/shared-styles';
import { useState } from 'react';
import { DotsThreeVertical, Trash } from '@phosphor-icons/react';
import { useSegment } from '../providers/segment/use-segment';
import { colors } from '../../shared/styles';
import { useContent } from '../providers/content/use-content';
import { Content } from '../providers/content/Content';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';

export const Toolbar = ({ children, isSelected, anchor }) => {
  if (!isSelected) {
    return null;
  }
  return <ToolbarComponent anchor={anchor}>{children}</ToolbarComponent>;
};

export const ToolbarComponent = ({ children, anchor }) => {
  const { sendUpdates } = useContent();
  const segment = useSegment();
  const toolkit = useToolKit();

  const [toolbarPopper, setToolbarPopper] = useState();
  const popper = usePopper(anchor, toolbarPopper);
  useResize(anchor, () => popper?.update());
  useResize(toolbarPopper, () => popper?.update());

  const handleDelete = () => Content.remove(sendUpdates, segment?.id);
  const displaySettings = () => ToolKit.setDisplaySettings(toolkit, true);

  return (
    <Portal>
      <Box
        data-scope="toolbar"
        ref={setToolbarPopper}
        css={`
          margin: 8px 0;
          padding: 8px;
          border-radius: 8px;
          background-color: ${colors.gray[600]};
          z-index: 1000;
          ${shadows.lg}
        `}
        flex="left"
        style={popper.styles.popper}
        {...popper.attributes.popper}
      >
        {children}
        <Box
          flex="right"
          css={`
            margin-left: 8px;
            padding-left: 8px;
            border-left: 1px solid ${colors.black};
          `}
        >
          <Button styles="icon-dark sm" hoverLabel="Delete" onClick={handleDelete}>
            <Trash size={24} color="currentColor" />
          </Button>
          <Button styles="icon-dark sm" hoverLabel="More" onClick={displaySettings}>
            <DotsThreeVertical size={24} color="currentColor" />
          </Button>
        </Box>
      </Box>
    </Portal>
  );
};
