import { useMemo } from 'react';
import * as uuid from 'uuid';
import { useEditorResource } from '../../BriteEditor/use-editor-resource';
import { isSmartText } from '../../BriteEditor/editor-components/image/image';

export const migrateV2 = (content) => {
  let next = {
    root: content?.root || {},
    sections: [],
    data: {},
  };

  for (const row of content?.rows || []) {
    const sectionId = uuid.v4();
    const { type, rowId, columns, stackColumns, ...sectionProperties } = row;
    next.sections.push({
      id: sectionId,
      type: 'section',
      properties: { ...sectionProperties },
      children: [{ id: rowId, type: 'row' }],
    });

    let children = [];
    for (const column of columns) {
      const columnId = uuid.v4();
      const { id: blockId, width, component, ...blockProperties } = column;
      let list = [];
      if (column.type === 'list') {
        for (const item of column.list) {
          const { id: blockId, component, ...blockProperties } = item;
          list.push({ type: component, id: blockId });
          next.data[blockId] = {
            id: blockId,
            type: component,
            parentId: columnId,
            properties: {
              ...blockProperties,
            },
          };
        }
      } else {
        list.push({ id: blockId, type: component });
        next.data[blockId] = {
          id: blockId,
          type: component,
          parentId: columnId,
          properties: {
            ...blockProperties,
          },
        };
      }

      const properties = width ? { width } : {};
      children.push({ id: columnId, type: 'column' });
      next.data[columnId] = {
        id: columnId,
        type: 'column',
        properties,
        children: list,
        parentId: rowId,
      };
    }

    next.data[rowId] = {
      properties: { stackColumns },
      children,
      id: rowId,
      type: 'row',
      parentId: sectionId,
    };
  }

  return next;
};

export const getScope = (event) => {
  const blockElement = event.target.closest('[data-scope]');
  if (blockElement) {
    return blockElement.getAttribute('data-scope');
  }
  return null;
};

export const getCanDeselect = (scope) => {
  switch (scope) {
    case 'context-menu':
    case 'menu':
    case 'modal':
    case 'block':
    case 'toolbar':
      return true;
    default: {
      return false;
    }
  }
};

export const formatUrl = (text) => {
  let url = text;
  if (!/^https?:\/\//i.test(url) && !/^http?:\/\//i.test(url)) {
    url = 'https://' + url;
  }
  try {
    const data = new URL(url);
    return [data.href, true];
  } catch (err) {
    return ['', false];
  }
};

export const getIsValidUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
};

export const useIsValidUrl = (url) => useMemo(() => getIsValidUrl(url), [url]);

export const useSmartField = (value) => {
  const { data: smartFields } = useEditorResource('liveSmartFields');
  const isSmartField = isSmartText(value);

  const src = useMemo(() => {
    if (isSmartField && smartFields?.[value]?.Success) {
      return smartFields?.[value]?.Value !== '<no value>' ? smartFields?.[value]?.Value : '';
    }
    return value;
  }, [isSmartField, value, smartFields?.[value]?.Value]);

  const validUrl = useIsValidUrl(src);

  return {
    value,
    validUrl,
    isSmartField,
    smartFields,
  };
};

// BATCHING: We needed a way to manage large local updates
// while maintaining history integrity and not overwhelming
// the websocket with too many messages.
export const buildBatchHistory = (current, batchId, actions) => {
  const history = current?.history || {};
  history[batchId] = current?.history?.[batchId] || {};
  for (const action of actions) {
    // We only want to accept the first "old" value for each action
    if (!(action.id in history[batchId])) {
      history[batchId][action.id] = structuredClone(action.old);
    }
  }
  return history;
};

export const getBatchChanges = (current, batchId, actions) => {
  const cloneActions = structuredClone(actions);
  const changes = {};
  changes[batchId] = cloneActions;
  return {
    ...(current?.changes || {}),
    ...changes,
  };
};

export const getBatch = (current, batchId, actions) => {
  const history = buildBatchHistory(current, batchId, actions);
  const changes = getBatchChanges(current, batchId, actions);
  return {
    history,
    changes,
  };
};

export const compileBatchIntoActions = (current) => {
  const changes = current?.changes || {};
  const history = current?.history || {};

  const actions = Object.entries(changes || {}).reduce((prev, [batchId, actions]) => {
    const list = actions.map((item) => {
      const old = history?.[batchId]?.[item.id] || item.old;
      return {
        ...item,
        old,
      };
    });
    return [...prev, ...list];
  }, []);

  return actions;
};

// END BATCHING CODE
