import { memo, useMemo, useRef } from 'react';
import { Box, Text } from '../../shared/components';
import { colors } from '../../shared/styles';
import { Row } from './Row';
import { Segment } from '../providers/segment/Segment';
import { overlay } from '../utility/styles';
import { SelectionPlus } from '@phosphor-icons/react';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { useSegment } from '../providers/segment/use-segment';
import { useDroppable } from '../utility/useDroppable';
import { Droppable } from '../utility/Droppable';
import { DROP_MOVE_SECTION } from '../utility/constants';
import { useStore } from '../../store-provider/use-store';

const containers = {
  row: Row,
};

const sectionCss = (childCount, isDropEnabled, isDraggingSource, isDraggingDisabled, showSections) => `
  position: relative;
  ${
    childCount
      ? ''
      : `
        background-color: ${colors.gray[100]};
        border-top: 1px dashed ${colors.gray[200]};
        border-bottom: 1px dashed ${colors.gray[200]};
      `
  }
  ${isDropEnabled ? `outline: 1px dashed ${colors.gray[300]};` : ''}
  ${
    isDraggingSource
      ? `
    outline: 2px solid ${colors.purple};
    filter: brightness(50%) saturate(0);
  `
      : ''
  }
  width: 100%;
  transition: filter .2s ease;
  
  .hover-settings {
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px;
  }
  :not(:hover) {
    .hover-settings {
      display: none;
    }
  }
  :hover {
    ::after {
      ${overlay}
      pointer-events: none;
      ${showSections ? `outline: 1px dashed ${colors.gray[300]};` : ''}
    }
  }
  .section {
    transition: width .2s ease;
  }
`;

const sectionUtils = {
  getStyles: (root, properties, viewMode) => {
    const sectionStyles = properties?.style || {};
    const width = viewMode === 'mobile' ? { width: '350px' } : {};
    if (properties?.fullScreenWidth) {
      return {
        container: { ...sectionStyles },
        section: { ...root?.style, ...sectionStyles, ...width },
      };
    }
    return {
      container: {},
      section: { ...root?.style, ...sectionStyles, ...width },
    };
  },
};

export const Section = memo(({ section, root }) => {
  const {
    data: { devMode },
  } = useStore();

  const sectionRef = useRef();

  const toolkit = useToolKit();
  const segment = useSegment();
  const childCount = section?.children?.length;

  const viewMode = ToolKit.getViewMode(toolkit);
  const drag = ToolKit.getDrag(toolkit);

  const styles = useMemo(() => {
    return sectionUtils.getStyles(root, section?.properties, viewMode);
  }, [root, section?.properties, viewMode]);

  // TODO: Implement section drag
  // const handleDrag = (event) => {
  //   event.preventDefault();
  //   ToolKit.startDrag(toolkit, {
  //     toolType: 'section',
  //     sourceId: segment.id,
  //     type: DROP_MOVE_SECTION,
  //   });
  // };

  const isDropEnabled = drag?.isDragging && drag?.type === DROP_MOVE_SECTION;
  const isDraggingDisabled = drag?.isDragging && drag?.type !== DROP_MOVE_SECTION;
  const droppable = useDroppable({
    disabled: !isDropEnabled,
    activeRects: {
      top: [0, 0, 100, 50],
      bottom: [0, 50, 100, 100],
    },
  });

  const isDraggingSource = drag?.isDragging && drag?.sourceId === segment.id;

  return (
    <Box
      ref={sectionRef}
      flex="center"
      css={sectionCss(childCount, isDropEnabled, isDraggingSource, isDraggingDisabled, devMode)}
      style={styles?.container}
      data-scope="section"
      {...droppable.attributes}
    >
      <Box style={styles?.section} className="section">
        {!section?.children?.length ? (
          <EmptySection />
        ) : (
          section?.children?.map((child, index) => {
            const Child = containers[child.type];
            return (
              <Segment key={child?.id} element={sectionRef} scope="section" index={index} {...child}>
                <Child />
              </Segment>
            );
          })
        )}
      </Box>
      {/* TODO: Implement Section "isGrouped" settings */}
      {/* <Box
        className="hover-settings"
        flex="right"
        css={`
          gap: 8px;
          margin: 8px;
        `}
      >
        <DragHandle onDrag={handleDrag} label="Move Section" />
      </Box> */}
      <Droppable {...droppable} />
    </Box>
  );
});

export const EmptySection = () => {
  return (
    <Box
      css={`
        width: 100%;
        padding: 32px;
        margin: 16px 0;
        gap: 16px;
      `}
      flex="left"
    >
      <SelectionPlus size={64} color={colors.gray[400]} />
      <Box>
        <Text h4>Empty Section</Text>
        <Text label>Drag N Drop a block here</Text>
      </Box>
    </Box>
  );
};
