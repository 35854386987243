import { forwardRef, useContext, useEffect, useState } from 'react';
import { Box, Icon, Text } from '../../shared/components';
import { useSubscription } from '../../WebSocket/useSubscription';
import { useEvent } from '../../shared/use-event';
import { colors } from '../../shared/styles';
import { editorUtils, getLayoutSelection } from '../../BriteEditor/provider/utils';
import { useStore } from '../../store-provider/use-store';
import { EditorDetailsContext } from '../../BriteEditor/provider/editor-detail-provider';
import { ReactComponent as Cursor } from '../../images/svg/cursor.svg';

const getSelection = (editor) => {
  if (!editor?.state?.selection) {
    return null;
  }
  return getLayoutSelection(editor?.state?.layout, editor?.state?.selection);
};

function mapCursorPosition(element, data) {
  if (!element || !data?.sender || !data?.position) {
    return { x: 0, y: 0 };
  }
  return {
    x: data.position.xPercent * 100,
    y: data.position.yPixel,
  };
}

function getCursorPosition(element, position) {
  if (!element || !position) return { x: 0, y: 0 };
  const rect = element.getBoundingClientRect();

  const relativeX = (position.x - rect.left) / rect.width;
  const relativeY = position.y - rect.top + element?.scrollTop;
  return {
    position: { xPercent: relativeX, yPixel: relativeY },
    sender: {
      width: rect.width,
      height: rect.height,
    },
  };
}

export const UserOverlay = forwardRef(({ editor, type, id }, ref) => {
  const {
    data: { user },
  } = useStore();

  const { editorDetails } = useContext(EditorDetailsContext);

  const [users, setUsers] = useState({});

  const onMessage = (message) => {
    switch (message.event.action) {
      case 'user_mouse_position': {
        const userId = message?.client?.user?.ID;
        setUsers((users) => ({
          ...users,
          [userId]: {
            user: message?.client?.user,
            position: mapCursorPosition(ref.current, message?.data),
          },
        }));
        break;
      }
      case 'user_select_block': {
        editorUtils.setProperty(
          editor,
          'liveUsers',
          {
            ...(editor?.state?.liveUsers || {}),
            [message?.client?.user?.ID]: {
              blockId: message?.data?.id,
              user: message?.client?.user,
            },
          },
          {
            syncRenderState: true,
          }
        );
        break;
      }
      case 'unsubscribe':
      case 'user_deselect_block': {
        const liveUsers = { ...editor?.state?.liveUsers };
        delete liveUsers[message?.client?.user?.ID];
        editorUtils.setProperty(editor, 'liveUsers', liveUsers, {
          syncRenderState: true,
        });
        break;
      }
      default:
        break;
    }
  };

  const onSuccess = (message) => {
    if (message?.action === 'unsubscribe') {
      const liveUsers = { ...editor?.state?.liveUsers };
      delete liveUsers[user?.ID];
      editorUtils.setProperty(editor, 'liveUsers', liveUsers, {
        syncRenderState: true,
      });
    }
  };

  const subscription = useSubscription({
    onMessage,
    onSuccess,
    key: id,
    type,
  });

  useEffect(() => {
    // Selecting blocks for editing
    const selection = getSelection(editor);
    if (selection) {
      subscription.send('user_select_block', { id: selection?.id });
    } else {
      subscription.send('user_deselect_block');
    }
  }, [editor?.state?.selection]);

  useEffect(() => {
    // Selecting comments
    if (editorDetails?.commenting?.componentId) {
      subscription.send('user_select_block', { id: editorDetails?.commenting?.componentId });
    } else {
      subscription.send('user_deselect_block');
    }
  }, [editorDetails?.commenting?.componentId]);

  const handleMouseMove = (event) => {
    const data = {
      ...getCursorPosition(ref.current, { x: event.clientX, y: event.clientY }),
    };
    subscription.send('user_mouse_position', data);
  };

  useEvent('mousemove', handleMouseMove);

  const getDisplayLabel = (user) => user?.FirstName || user?.LastName || user?.Email;

  return (
    <Box
      css={`
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1000;
        pointer-events: none;
      `}
    >
      {Object.values(users || {})?.map((item) => (
        <Box
          css={`
            position: absolute;
            top: ${item?.position?.y}px;
            left: ${item?.position?.x}%;
            z-index: 1000;
          `}
        >
          <Icon
            SVG={Cursor}
            size={20}
            weight="bold"
            color={item?.user?.Preferences?.Color || colors.purple}
            fill={(item?.user?.Preferences?.Color || colors.purple) + '44'}
          />
          <Box
            css={`
              position: absolute;
              top: 16px;
              left: 16px;
              background-color: ${item?.user?.Preferences?.Color || colors.purple};
              padding: 4px 8px;
              border-radius: 16px;
              max-width: 200px;
            `}
            key={item?.user?.ID}
          >
            <Text
              css={`
                color: white;
                font-weight: bold;
              `}
              ellipsis
              helper
            >
              {getDisplayLabel(item?.user)}
            </Text>
          </Box>
        </Box>
      ))}
    </Box>
  );
});
