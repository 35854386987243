import { useMemo } from 'react';
import { useEditorResource } from '../../../BriteEditor/use-editor-resource';
import { Box, Text } from '../../../shared/components';
import { container } from '../../../shared/shared-styles';
import { colors } from '../../../shared/styles';
import { ColorPicker } from '../../utility/settings/ColorPicker';
import { COLOR_MAP } from '../../utility/constants';
import { Property } from './property';

export const ColorSetting = ({ label, value, setValue }) => {
  const { data: designStyles } = useEditorResource('styles');
  const colorPalette = designStyles?.general?.colorPalette;

  const getPreviewColor = () => {
    if (value === 'rgba(0, 0, 0, 0)') {
      return 'None';
    } else if (value.startsWith('var(--')) {
      const key = value?.replace('var(--', '')?.replace(')', '');
      return COLOR_MAP[key] || colorPalette[key] || key;
    }
    return value;
  };

  const previewColor = useMemo(() => {
    return getPreviewColor();
  }, [value, colorPalette]);

  return (
    <Property>
      <Text label>{label}</Text>
      <Box
        css={`
          border-radius: 8px;
          border: 1px solid ${colors.gray[300]};
          ${container.hover}
          padding: 8px;
          gap: 16px;
        `}
        flex="left"
      >
        <ColorPicker color={value} update={setValue} label={label} />
        <Text
          label
          ellipsis
          css={`
            flex-grow: 1;
            max-width: 160px;
          `}
        >
          {previewColor}
        </Text>
      </Box>
    </Property>
  );
};
