import { createContext } from 'react';
import { getDropActions, getRemoveActions, getUpdateAction } from './content-utils';

// (DEV being the user) user-facing methods are found here.
// Functions that that these methods use should be
// added to the content-utils.js file.

export const ContentContext = createContext();

export const Content = ({ children, content, setContent, sendUpdates, local }) => {
  const value = {
    content,
    sendUpdates,
    setContent,
    local,
  };
  return <ContentContext.Provider value={value}>{children}</ContentContext.Provider>;
};

Content.commit = (old, actions) => {
  const content = { ...old };
  for (const action of actions) {
    switch (action.type) {
      case 'remove': {
        if (action?.property === 'sections') {
          content.sections = content.sections.filter((section) => section.id !== action?.id);
        } else if (action?.property === 'data') {
          delete content.data[action?.id];
        }
        break;
      }
      case 'update': {
        if (action?.property === 'sections') {
          content.sections = content.sections.map((section) =>
            section.id === action?.id ? { ...section, ...action?.updates } : section
          );
        } else if (action?.property === 'data') {
          content.data[action?.id] = {
            ...content.data[action?.id],
            ...action?.updates,
          };
        }
        break;
      }
      case 'insert': {
        if (action?.property === 'data') {
          content.data[action?.id] = { ...action?.updates };
        } else if (action?.property === 'sections') {
          content.sections.splice(action?.at, 0, action?.updates);
        }
        break;
      }

      case 'sections': {
        content.sections = [...action.sections];
        break;
      }

      default:
        break;
    }
  }
  return content;
};

Content.drop = async (setter, drag) => {
  setter((old) => {
    const actions = getDropActions(old, drag);
    const content = Content.commit(old, actions);
    return { content, actions };
  });
};

Content.update = async (setter, id, updateFn) => {
  setter((old) => {
    const action = getUpdateAction(old, id, updateFn);
    const content = Content.commit(old, [action]);
    return { content, actions: [action] };
  });
};

Content.remove = async (setter, id) => {
  setter((old) => {
    const actions = getRemoveActions(old, id);
    const content = Content.commit(old, actions);
    return { content, actions };
  });
};

Content.get = (content, id) => {
  if (id in content.data) {
    return content.data[id];
  } else {
    const section = content.sections.find((section) => section.id === id);
    if (section) {
      return section;
    }
  }
  return null;
};
