import React, { useState } from 'react';
import { css } from '@emotion/css';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { Div, Text } from '../shared/components';
import { colors } from '../shared/styles';
import { animation, container, flex, scrollbar, shadows } from '../shared/shared-styles';
import { useOutsideClick } from '../shared/use-outside-click';
import { getSortText, sortTypes } from './utils/guide';

export const SortDropdown = ({ setSortVal = () => {}, sortVal = '' }) => {
  const [open, setOpen] = useState(false);

  const handleSelect = (selected) => {
    setOpen(false);
    setSortVal(selected);
  };

  const ref = useOutsideClick(() => setOpen(false));

  return (
    <Div
      css={css`
        position: relative;
        border-radius: 8px;
        height: 48px;

        ${flex('space-between')}
        padding: 16px;
        padding-top: 12px;
        padding-bottom: 12px;

        svg {
          min-width: 32px;
        }
        background-color: white;
        cursor: pointer;
        border: 1px solid ${colors.gray[300]};
        ${open ? '' : container.hover}
      `}
      ref={ref}
      onClick={() => setOpen(!open)}
    >
      <Text
        h4
        ellipsis
        css={`
          flex-grow: 1;
          color: var(--700, ${colors.black}});
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 150% */
          letter-spacing: 0.15px;
        `}
      >
        Sort:{' '}
        <span
          className={css`
            color: var(--700, ${colors.black}});
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.15px;
          `}
        >
          {getSortText({ type: sortVal })}
        </span>
      </Text>
      {open ? <CaretUp className="hover-caret" size={16} /> : <CaretDown className="hover-caret" size={16} />}
      {!open ? null : (
        <Div
          css={css`
            ${animation('fadeIn', '.15s ease')}
            ${shadows?.md}
            position: absolute;
            top: calc(100% + 8px);
            left: 0;
            right: 0;
            background-color: white;
            z-index: 100000000000000000;
            border-radius: 8px;
            cursor: default;
            padding: 8px 0;
            width: 208px;
          `}
          onClick={(e) => e.stopPropagation()}
        >
          <Div
            css={css`
              max-height: 48vh;
              overflow-y: auto;
              padding: 8px;
              ${scrollbar.style}
              width: 208px;
            `}
          >
            {sortTypes.map((type) => (
              <SortOption key={type} sortVal={sortVal} type={type} handleSelect={handleSelect} />
            ))}
          </Div>
        </Div>
      )}
    </Div>
  );
};

const SortOption = ({ sortVal = '', type = '', handleSelect = () => {} }) => {
  return (
    <Div
      css={css`
        ${flex('space-between')}
        ${container.hover}
        ${sortVal === type ? `background-color: ${colors.gray[100]};` : ''}
        border-radius: 8px;
        padding: 4px 8px;
        &:hover {
          background-color: ${colors.gray[100]};
        }
      `}
      onClick={() => handleSelect(type)}
    >
      <Div
        css={css`
          flex-grow: 1;
          padding: 8px;
          width: 208px;
        `}
      >
        <Text
          label
          css={`
            flex-grow: 1;
            max-width: 100%;
            padding: 0;
            margin: 0;
          `}
        >
          {getSortText({ type })}
        </Text>
      </Div>
    </Div>
  );
};
