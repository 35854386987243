import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { Box, Button, Dropdown, Slider, Text } from '../../../shared/components';
import { Modify } from '../../providers/content/content-modify';
import { useContent } from '../../providers/content/use-content';
import { ColorPicker } from '../../utility/settings/ColorPicker';

const spaceOptions = [
  {
    label: 'Space',
    value: 'space',
  },
  {
    label: 'Page Break',
    value: 'page-break',
  },
];

export const SpaceToolbar = ({ id, height, bgColor, breakAfter }) => {
  const { sendUpdates } = useContent();

  const updateHeight = () => {
    const heightValue = Math.max(8, Number(height.value));
    Modify.style(sendUpdates, id, {
      height: '100%',
      minHeight: `${heightValue}px`,
    });
  };

  const updatePageBreak = (value) => {
    Modify.containerStyle(sendUpdates, id, {
      breakAfter: value === 'page-break' ? 'page' : 'avoid',
    });
  };

  const updateBgColor = (backgroundColor) => Modify.style(sendUpdates, id, { backgroundColor });

  return (
    <Box
      flex="left"
      css={`
        gap: 8px;
      `}
    >
      <Dropdown
        clear
        button={({ open }) => (
          <Button
            editor
            css={`
              display: flex;
              justify-content: space-between;
              width: 150px;
              text-transform: none;
              background-color: none;
              svg,
              p {
                color: white;
                font-weight: bold;
              }
            `}
          >
            <Text label>{breakAfter === 'page' ? 'Page Break' : 'Space'}</Text>
            {open ? <CaretUp size={24} color="currentColor" /> : <CaretDown size={24} color="currentColor" />}
          </Button>
        )}
        popper={{ placement: 'bottom-start' }}
      >
        <Box
          css={`
            background-color: white;
            width: 150px;
          `}
          data-scope="menu"
        >
          {spaceOptions.map((option) => (
            <Dropdown.Item key={option.value} onClick={() => updatePageBreak(option.value)}>
              {option.label}
            </Dropdown.Item>
          ))}
        </Box>
      </Dropdown>
      <Slider
        min={8}
        max={300}
        value={height.value}
        style={{ width: '100px', color: 'white' }}
        valueLabelDisplay="auto"
        onChange={(e, value) => height.set(value)}
        onChangeCommitted={updateHeight}
      />
      <ColorPicker label="Background Color" color={bgColor} update={updateBgColor} />
    </Box>
  );
};
