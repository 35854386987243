import { CaretDown, CaretUp, Plus, Trash } from '@phosphor-icons/react';
import { Box, Button, Dropdown, Text } from '../../../shared/components';
import { colors } from '../../../shared/styles';
import { container } from '../../../shared/shared-styles';
import { Collapse } from '../../../common/components/Collapse';
import { useState } from 'react';
import { CLICK_ACTIONS } from '../../utility/constants';
import { OpenWebsite } from './action-settings/OpenWebsite';
import { RouteToPage } from './action-settings/RouteToPage';
import { SendEmail } from './action-settings/SendEmail';
import { SendSMS } from './action-settings/SendSMS';
import { Property } from './property';

export const ActionSetting = ({ value, setValue }) => {
  const [open, setOpen] = useState(false);

  const changeActionType = (type) => {
    setValue({ ...value, action: { type, ...CLICK_ACTIONS[type] } });
  };

  const removeAction = () => {
    setValue({ ...value, action: null });
    setOpen(false);
  };

  return (
    <Property
      css={`
        border-top: 1px solid ${colors.gray[200]};
        border-bottom: 1px solid ${colors.gray[200]};
      `}
    >
      <Box
        flex="space-between"
        css={`
          transition: margin 0.2s ease;
          ${container.hover}
          padding: 8px;
          ${open
            ? `
              border-radius: 8px;  
              margin: 8px 0;
            `
            : ''}
        `}
        onClick={() => setOpen(!open)}
      >
        <Box>
          <Text label bold>
            Action
          </Text>
          {value?.action?.displayName ? <Text helper>{value?.action?.displayName}</Text> : null}
        </Box>
        <Button styles="icon sm">
          {open ? <CaretUp size={24} /> : !value?.action?.type ? <Plus size={24} /> : <CaretDown size={24} />}
        </Button>
      </Box>
      <Collapse isOpen={open}>
        <Box>
          <Dropdown
            clear
            button={() => (
              <Box
                css={`
                  margin: 8px 0;
                  width: 100%;
                  border-radius: 8px;
                  border: 1px solid ${colors.gray[200]};
                  padding: 8px;
                  ${container.hover}
                `}
                flex="space-between"
              >
                <Text label>{value?.action?.displayName || 'Action Type'}</Text>
                <CaretDown size={24} />
              </Box>
            )}
          >
            <Box
              data-scope="menu"
              css={`
                width: 200px;
              `}
            >
              <Box option onClick={() => changeActionType('open-website')}>
                <Text label>Open Website</Text>
              </Box>
              <Box option onClick={() => changeActionType('route-to-page')}>
                <Text label>Route To Page</Text>
              </Box>
              <Box option onClick={() => changeActionType('open-email')}>
                <Text label>Send Email</Text>
              </Box>
              <Box option onClick={() => changeActionType('open-sms')}>
                <Text label>Send SMS</Text>
              </Box>
              <Box option onClick={() => changeActionType('call-phone')}>
                <Text label>Call Phone</Text>
              </Box>
            </Box>
          </Dropdown>
          <Box
            css={`
              margin: 16px 0;
            `}
          >
            {value?.action?.type === 'open-website' ? (
              <OpenWebsite value={value} setValue={setValue} />
            ) : value?.action?.type === 'route-to-page' ? (
              <RouteToPage value={value} setValue={setValue} />
            ) : value?.action?.type === 'open-email' ? (
              <SendEmail value={value} setValue={setValue} />
            ) : value?.action?.type === 'open-sms' ? (
              <SendSMS value={value} setValue={setValue} />
            ) : value?.action?.type === 'call-phone' ? (
              <SendSMS value={value} setValue={setValue} />
            ) : null}
          </Box>
        </Box>
        {value?.action?.type ? (
          <Box
            flex="space-between"
            css={`
              padding: 8px;
              margin: 8px 0;
              border-radius: 8px;
              ${container.hover}
            `}
            onClick={removeAction}
          >
            <Text label color={colors.red[100]}>
              Remove action
            </Text>
            <Button styles="icon sm">
              <Trash size={24} color={colors.red[100]} />
            </Button>
          </Box>
        ) : null}
      </Collapse>
    </Property>
  );
};
