import { MenuItem } from '@material-ui/core';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { DotsThree, SquaresFour } from '@phosphor-icons/react';
import { useModal } from '../common/hooks/useModal';
import { Box, Button, DropMenu, Input, Text } from '../shared/components';
import { colors } from '../shared/styles';
import { deleteDashboard, duplicateDashboard, updateDashboard } from './api/dashboard';
import { DashboardNameModal } from './modals/DashboardNameModal';

export const DashboardCard = ({ data = {}, onClick = () => {}, refetch = () => {} }) => {
  // State
  const [description, setDescription] = useState(data.description);

  // Modals
  const dashboardNameModal = useModal();

  // TODO: UI states for all the mutations below.
  const handleUpdateDashboard = useMutation(() => updateDashboard({ dashboard: { ...data, description } }), {
    onMutate: () => {},
    onSuccess: () => refetch(),
    onError: () => {},
    onSettled: () => {},
  });

  const handleDuplicateDashboard = useMutation(() => duplicateDashboard({ dashboard: { ...data } }), {
    onMutate: () => {},
    onSuccess: () => refetch(),
    onError: () => {},
    onSettled: () => {},
  });

  const handleDeleteDashboard = useMutation(() => deleteDashboard({ id: data.id }), {
    onMutate: () => {},
    onSuccess: () => refetch(),
    onError: () => {},
    onSettled: () => {},
  });

  return (
    <>
      <Box
        css={`
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          padding: 24px;
          border-radius: 16px;
          box-shadow: 0px 2px 8px 0px rgba(37, 40, 45, 0.2);
          width: 100%;
        `}
        onClick={() => onClick()}
      >
        <Box
          css={`
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
          `}
        >
          <SquaresFour weight="fill" />
          <DropMenu
            button={
              <Button styles="icon">
                <DotsThree />
              </Button>
            }
          >
            <MenuItem onClick={() => dashboardNameModal.toggle()}>Change Name</MenuItem>
            <MenuItem onClick={() => handleDuplicateDashboard.mutate()}>Duplicate</MenuItem>
            <MenuItem onClick={() => handleDeleteDashboard.mutate()}>Delete</MenuItem>
          </DropMenu>
        </Box>
        <Box
          css={`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-top: 16px;
            gap: 8px;
            width: 100%;
          `}
        >
          <Text h4>{data.name}</Text>
          <Box
            css={`
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              gap: 8px;
              width: 100%;
            `}
          >
            <Text body color={colors.black}>
              {data.config.guideIDs.length} Guides
            </Text>
            <Box
              css={`
                width: 1px;
                height: 16px;
                background: ${colors.gray[300]};
              `}
            />
            <Input
              css={`
                input {
                  padding: 0px;
                  color: ${colors.gray[500]};
                  text-overflow: ellipsis;
                }

                flex-grow: 1;
                height: 32px;
                background: transparent;
                border: none;

                :hover {
                  border: none;
                }
              `}
              placeholder="Add a description..."
              value={description}
              onBlur={() => handleUpdateDashboard.mutate()}
              onChange={(e) => setDescription(e.target.value)}
              onClick={(e) => e.stopPropagation()}
            />
          </Box>
        </Box>
      </Box>
      {dashboardNameModal.isOpen && (
        <DashboardNameModal data={data} hide={() => dashboardNameModal.hide()} refetch={refetch} />
      )}
    </>
  );
};
