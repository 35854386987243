import { useEffect, useState } from 'react';
import { Box, Input, Text } from '../../../shared/components';
import { selectableBox } from '../../utility/settings/styles';
import { Property } from './property';
import { Collapse } from '../../../common/components/Collapse';
import { CornersOut } from '@phosphor-icons/react';
import { colors } from '../../../shared/styles';
import { getNumber } from '../Settings/property-utils';

const getCurrentType = (value) => {
  const sizeList = Object.values(value || {}).toString();
  if (sizeList === '0px,0px,0px,0px' || sizeList === '0,0,0,0') {
    return 'small';
  } else if (sizeList === '8px,8px,8px,8px') {
    return 'medium';
  } else if (sizeList === '16px,16px,16px,16px') {
    return 'large';
  }
  return 'custom';
};

const radiusMap = {
  small: '0',
  medium: '8px',
};

const getCornerStyle = (value, key1, key2) => `
  transition: border-radius .2s ease;
  border-radius: 0;
  border-${key1}-${key2}-radius: ${value};
  border-${key1}: 3px solid ${colors.gray[300]};
  border-${key2}: 3px solid ${colors.gray[300]};
  width: 22px;
  height: 16px;
  margin: 8px;
`;

export const RadiusSetting = ({ label = 'Radius', value, setValue }) => {
  const [customize, setCustomize] = useState(false);
  const currentType = getCurrentType(value);

  useEffect(() => {
    if (currentType === 'custom') {
      setCustomize(true);
    }
  }, [currentType]);

  const setPresetValue = (type) => {
    setCustomize(false);
    setValue('all', radiusMap[type]);
  };

  const updateCustomValue = (key, value) => {
    const newValue = `${value}px`;
    setValue(key, newValue);
  };

  return (
    <Property>
      <Text label>{label}</Text>
      <Box
        flex="left"
        css={`
          gap: 8px;
        `}
      >
        <Box
          flex="center"
          css={selectableBox(!customize && currentType === 'small')}
          onClick={() => setPresetValue('small')}
        >
          <Box
            css={`
              border-top-right-radius: 0;
              border-top: 2px solid;
              border-right: 2px solid;
              width: 16px;
              height: 16px;
            `}
          />
        </Box>
        <Box
          flex="center"
          css={selectableBox(!customize && currentType === 'medium')}
          onClick={() => setPresetValue('medium')}
        >
          <Box
            css={`
              border-top-right-radius: 8px;
              border-top: 2px solid;
              border-right: 2px solid;
              width: 16px;
              height: 16px;
            `}
          />
        </Box>
        <Box flex="center" css={selectableBox(customize)} onClick={() => setCustomize(true)}>
          <CornersOut size={24} color="currentColor" weight="bold" />
        </Box>
      </Box>
      <Collapse isOpen={customize}>
        <Box
          css={`
            display: grid;
            gap: 8px;
            input {
              height: 32px;
            }
          `}
        >
          <Box
            flex="space-around"
            css={`
              gap: 8px;
            `}
          >
            <Input
              value={getNumber(value?.borderTopLeftRadius)}
              onChange={(e) => updateCustomValue('borderTopLeftRadius', e.target.value)}
              startIcon={<Box css={getCornerStyle(value?.borderTopLeftRadius, 'top', 'left')} />}
            />
            <Input
              value={getNumber(value?.borderTopRightRadius)}
              onChange={(e) => updateCustomValue('borderTopRightRadius', e.target.value)}
              startIcon={<Box css={getCornerStyle(value?.borderTopRightRadius, 'top', 'right')} />}
            />
          </Box>
          <Box
            flex="space-around"
            css={`
              gap: 8px;
            `}
          >
            <Input
              value={getNumber(value?.borderBottomLeftRadius)}
              onChange={(e) => updateCustomValue('borderBottomLeftRadius', e.target.value)}
              startIcon={<Box css={getCornerStyle(value?.borderBottomLeftRadius, 'bottom', 'left')} />}
            />
            <Input
              value={getNumber(value?.borderBottomRightRadius)}
              onChange={(e) => updateCustomValue('borderBottomRightRadius', e.target.value)}
              startIcon={<Box css={getCornerStyle(value?.borderBottomRightRadius, 'bottom', 'right')} />}
            />
          </Box>
        </Box>
      </Collapse>
    </Property>
  );
};
