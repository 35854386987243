import { Editable, Slate } from 'slate-react';
import { useTextBlock } from './useTextBlock';
import { Toolbar } from '../../utility/Toolbar';
import { TextToolbar } from './TextToolbar';
import React, { useRef } from 'react';
import { useBlock } from '../../utility/useBlock';
import { TextUpdatesManager } from './TextUpdatesManager';

export const TextBlock = () => {
  const slate = useRef();
  const block = useBlock();
  const text = useTextBlock(block);

  return (
    <Slate {...text.slateProps}>
      <Editable {...text.editorProps} ref={slate} />
      <TextUpdatesManager block={block} text={text} slate={slate} />
      <Toolbar isSelected={block.isSelected} anchor={block.anchor}>
        <TextToolbar id={block.id} textType={text?.textType} color={text?.color} />
      </Toolbar>
    </Slate>
  );
};
