import { useEffect, useState } from 'react';

export const useSync = (valueToSync, deps = []) => {
  const [value, setValue] = useState(valueToSync);
  const spread = Object.values(valueToSync || {}).toString();

  const resync = () => setValue(valueToSync);

  useEffect(() => {
    setValue(valueToSync);
  }, [spread, ...deps]);

  const set = (stateSetter) => {
    setValue(stateSetter);
  };

  const merge = (updates) => {
    setValue({ ...value, ...updates });
  };

  return { value, set, merge, resync, isSynced: value === valueToSync };
};
