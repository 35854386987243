import { FileText, UploadSimple } from '@phosphor-icons/react';
import { Box, BriteLoader, Modal, Text } from '../../shared/components';
import { colors } from '../../shared/styles';
import { useUpload } from './useUpload';

export const UploadModal = ({ title, onClose, update, dropzoneProps = {} }) => {
  const upload = (document) => {
    update(document);
    onClose();
  };

  const { getRootProps, getInputProps, isDragActive, isLoading } = useUpload(upload, dropzoneProps);

  return (
    <Modal display onClose={onClose} data-scope="modal">
      <Modal.Paper>
        <Modal.Header title={title} onClose={onClose} />
        <Modal.Body>
          <Box
            css={`
              border-radius: 8px;
              background-color: ${colors.gray[100]};
              border: 1px solid ${colors.gray[300]};
              width: 100%;
              height: 150px;
              cursor: pointer;
              transition: background-color 0.2s ease;
              ${!isDragActive
                ? `
                :hover {
                  background-color: ${colors.gray[200]};
                }  
              `
                : `
                border: 1px dashed ${colors.purple};
                `}
            `}
            flex="center column"
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {isLoading ? (
              <BriteLoader size={48} weight={8} overlay={false} />
            ) : isDragActive ? (
              <UploadSimple size={48} color={colors.purple} />
            ) : (
              <FileText size={48} />
            )}
            {isDragActive ? (
              <Text bold>Drop file to upload</Text>
            ) : (
              <Text bold label>
                Drop a file here, or click to upload
              </Text>
            )}
          </Box>
        </Modal.Body>
      </Modal.Paper>
    </Modal>
  );
};
