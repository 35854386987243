import { css } from '@emotion/css';
import { useState } from 'react';

function toFixed(num, fixed = 4) {
  var re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
  return num.toString().match(re)[0];
}

const calculatedCrop = ({ crop, image, loading }) => {
  if (!image?.naturalWidth) {
    return '';
  }

  const pixelWidth = toFixed(crop?.width) * image?.naturalWidth;
  const pixelHeight = toFixed(crop?.height) * image?.naturalHeight;
  const aspectRatio = toFixed(pixelWidth) / toFixed(pixelHeight);
  const scaleW = image?.naturalWidth / (image?.naturalWidth * (toFixed(crop?.width) / 100));
  const scaleH = image?.naturalHeight / (image?.naturalHeight * (toFixed(crop?.height) / 100));
  const width = scaleW * 100;
  const cropX = scaleW * toFixed(crop?.x);
  const cropY = scaleH * toFixed(crop?.y);
  return css`
    position: relative;
    width: 100%;
    aspect-ratio: ${aspectRatio};
    ${crop.circularCrop ? `border-radius: 50%;` : ''}
    overflow: hidden;
    ${loading ? 'opacity: 0;' : 'opacity: 1;'}
    transition: opacity 0.2s ease;
    img {
      position: absolute;
      width: ${width}%;
      top: -${cropY}%;
      left: -${cropX}%;
      transform: scale(${crop?.scale}) rotate(${crop?.rotate}deg);
      transition: transform 0.2s ease;
    }
  `;
};

export const CroppedImage = ({ image, crop, style, width }) => {
  const [loading, setLoading] = useState(true);
  const cropStyle = {
    ...style,
    ...(crop?.circularCrop
      ? {
          borderTopLeftRadius: '50%',
          borderTopRightRadius: '50%',
          borderBottomLeftRadius: '50%',
          borderBottomRightRadius: '50%',
        }
      : {}),
  };

  return (
    <div className={calculatedCrop({ width, crop, image, loading })} style={cropStyle}>
      <img src={image?.src} alt="Cropped" onLoad={() => setLoading(false)} />
    </div>
  );
};
