import { cloneDeep } from 'lodash';
import { Box, Pill } from '../shared/components';
import { filterTypes, getGuideType } from './utils/guide';

export const FilterPills = ({ setFilterVal = () => {}, filterVal = [] }) => {
  const handleSelect = (selected) => {
    let cloned = cloneDeep(filterVal);
    const index = cloned.indexOf(selected);
    if (index === -1) {
      cloned.push(selected);
    } else {
      cloned.splice(index, 1);
    }
    setFilterVal(cloned);
  };

  return (
    <Box
      css={`
        display: flex;
        flex-direction: row;
        gap: 8px;
        overflow-x: auto;
      `}
    >
      {filterTypes.map((type) => (
        <Pill
          key={type}
          isSelected={filterVal.includes(type)}
          text={getGuideType({ type })}
          onClick={() => handleSelect(type)}
        />
      ))}
    </Box>
  );
};
