import { useMemo, useRef } from 'react';
import { Box, BriteLoader } from '../shared/components';
import { Segment } from './providers/segment/Segment';
import { useEditorResource } from '../BriteEditor/use-editor-resource';
import { FontLinks } from '../BriteEditor/utility-components';
import { generateColors } from '../BriteEditor/brite-editor';
import { Section } from './composition/Section';
import { Content } from './providers/content/Content';
import { useEditor } from './utility/useEditor';
import { Cursor } from './utility/Cursor';
import { useToolKit } from './providers/tool-kit/use-tool-kit';
import { ToolKit } from './providers/tool-kit/ToolKit';
import { scrollbar } from '../shared/shared-styles';
import { Sidebar } from './sidebar/Sidebar';
import { ViewOptions } from './utility/ViewOptions';
import { ContextMenu } from './utility/ContextMenu';
import { MultiSelection } from './utility/MultiSelection';
import { BottomDrag } from './utility/BottomDrag';
import { Conditional } from './utility/Conditional';
import { PropertyManager } from './providers/property-manager/PropertyManager';

export const Editor = (props) => {
  const editorRef = useRef();
  const editor = useEditor(props?.course, props?.query);

  const { data: styles } = useEditorResource('styles');

  const toolkit = useToolKit();
  const { drag, hasMultiSelection } = useMemo(
    () => ({
      hasMultiSelection: ToolKit.hasMultiSelection(toolkit),
      drag: ToolKit.getDrag(toolkit),
    }),
    [toolkit]
  );

  return (
    <>
      <PropertyManager>
        <Content {...editor}>
          <Box
            flex="left start"
            css={`
              width: 100%;
              height: calc(100vh - 100px);
              ${generateColors(styles)}
            `}
          >
            <FontLinks fontURLs={styles?.general?.fontURLs} />
            <Box
              css={`
                position: relative;
                flex-grow: 1;
                background-color: white;
                height: 100%;
                width: 100%;
                overflow: auto;
                background-color: var(--background-color);
                ${scrollbar.style}
              `}
              flex="column start"
            >
              {editor?.isLoading ? (
                <BriteLoader />
              ) : (
                <Box
                  css={`
                    width: 100%;
                  `}
                  ref={editorRef}
                >
                  {editor?.content?.sections?.map((section) => (
                    <Segment key={section?.id} scope="editor" element={editorRef} type="section" id={section?.id}>
                      <Section section={section} root={editor?.content?.root} />
                    </Segment>
                  ))}
                </Box>
              )}
              <Conditional display={hasMultiSelection}>
                <MultiSelection />
              </Conditional>
              <BottomDrag editor={editor} />
              <ContextMenu />
            </Box>
            <ViewOptions />
            <Sidebar />
          </Box>
        </Content>
      </PropertyManager>
      <Conditional display={drag?.isDragging}>
        <Cursor drag={drag} />
      </Conditional>
    </>
  );
};
