import { useState } from 'react';
import { useMutation } from 'react-query';
import { Box, Button, Input, Modal, Text } from '../../shared/components';
import { updateDashboard } from '../api/dashboard';

export const DashboardNameModal = ({ data = {}, hide = () => {}, refetch = () => {} }) => {
  // State
  const [dashboard, setDashboard] = useState({ ...data });

  // TODO: UI states.
  const handleUpdateDashboard = useMutation(() => updateDashboard({ dashboard }), {
    onMutate: () => {},
    onSuccess: () => {
      refetch();
      hide();
    },
    onError: () => {},
    onSettled: () => {},
  });

  return (
    <Modal display onClose={() => hide()}>
      <Modal.Paper width="500px">
        <Modal.Header title="Change Name" onClose={() => hide()} />
        <Modal.Body>
          <Box
            css={`
              display: flex;
              flex-direction: column;
              gap: 8px;
            `}
          >
            <Text label bold>
              Dashboard Name
            </Text>
            <Input value={dashboard.name} onChange={(e) => setDashboard({ ...data, name: e.target.value })} />
          </Box>
        </Modal.Body>
        <Modal.Actions>
          <Button secondary onClick={() => hide()}>
            Cancel
          </Button>
          <Button primary onClick={() => handleUpdateDashboard.mutate()}>
            Save
          </Button>
        </Modal.Actions>
      </Modal.Paper>
    </Modal>
  );
};
