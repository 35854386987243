import { css } from '@emotion/css';
import { useEffect, useMemo } from 'react';
import { Box, Button, Div, Modal, Text } from '../../../shared/components';
import { ImageSquare, WarningCircle, X } from '@phosphor-icons/react';
import { colors } from '../../../shared/styles';
import { CroppedImage } from './copped-image';
import { flex } from '../../../shared/shared-styles';
import { useStateSync } from '../../../shared/use-state-sync';
import { useEditorResource } from '../../use-editor-resource';
import { useModal } from '../../../common/hooks/useModal';
import { ReactComponent as SmartImage } from '../../../images/smart-image.svg';

export const isSmartText = (string) => string?.startsWith('{{') && string?.endsWith('}}');

export const Image = ({ element }) => {
  const { attributes, crop = null } = element;
  const [error, setError] = useStateSync(false, [attributes]);

  const { data: styles } = useEditorResource('styles');
  const { data: smartFields } = useEditorResource('liveSmartFields');

  const combinedStyles = {
    ...styles?.image,
    ...attributes?.style,
  };

  const isSmartField = isSmartText(attributes?.src);

  useEffect(() => {
    setError(false);
  }, []);

  const sourceLink = useMemo(() => {
    const { src = '' } = attributes;

    if (isSmartField && smartFields?.[src]?.Success) {
      return smartFields?.[src]?.Value !== '<no value>' ? smartFields?.[src]?.Value : '';
    }
    return src;
  }, [element, isSmartField, attributes?.src, smartFields?.[attributes?.src]?.Value]);

  const validUrl = useMemo(() => {
    try {
      new URL(sourceLink);
      return true;
    } catch (err) {
      return !!sourceLink ? false : null;
    }
  }, [sourceLink]);

  return (
    <>
      {validUrl === false || error || !sourceLink ? (
        <ImageErrors
          src={attributes?.src}
          validUrl={validUrl}
          sourceLink={sourceLink}
          error={error}
          isSmartField={isSmartField}
          smartFields={smartFields}
        />
      ) : crop === null ? (
        <img
          src={sourceLink}
          alt={attributes.alt}
          data-component={true}
          style={combinedStyles}
          onError={() => setError(true)}
        />
      ) : (
        <CroppedImage
          style={attributes.style}
          width={combinedStyles.width}
          crop={crop}
          imageProps={{
            src: sourceLink,
            alt: attributes.alt,
            'data-component': true,
          }}
        />
      )}
    </>
  );
};

export const ImageErrors = ({ src, validUrl, error, sourceLink, isSmartField, smartFields }) => {
  const modal = useModal();

  const brokenSmartImage = isSmartField && src in smartFields && !smartFields?.[src]?.Success;

  const handleDoubleClick = (e) => {
    if (brokenSmartImage) {
      e.stopPropagation();
    }
  };

  const smartImageLocationExists = !brokenSmartImage && !(error && isSmartField);
  const smartImageHasValue = sourceLink || !isSmartField;
  const invalidImageUrl = !validUrl && !!src;
  const imageCanLoad = !error || !sourceLink;

  const errorsExist = (isSmartField && (!sourceLink || brokenSmartImage || error)) || (!validUrl && !!src);

  const errorColors = {
    warning: brokenSmartImage ? colors.red[100] : colors.orange[100],
    image: error && !!sourceLink ? colors.red[100] : colors.gray[500],
  };

  const handleLearnMore = () => {
    if (errorsExist) {
      modal.toggle();
    }
  };

  return (
    <>
      <Div
        data-component={true}
        className={css`
          ${flex('aic column')}
          background-color: ${colors.gray[100]};
          border-radius: 8px;
          width: 100%;
          min-height: 150px;
          padding: 32px 0;
          * {
            pointer-events: none;
          }
          .image-hover {
            padding: 8px 0;
            padding-bottom: 16px;
            text-align: center;
          }
          :not(:hover) {
            .image-hover {
              visibility: hidden;
            }
          }
          a {
            text-decoration: underline;
            :hover {
              color: ${colors.gray[500]};
            }
          }
          ${!errorsExist
            ? `
            padding-bottom: 8px;  
          `
            : ''}
        `}
        onDoubleClick={handleDoubleClick}
        onClick={handleLearnMore}
      >
        {errorsExist ? (
          <WarningCircle size={32} color={errorColors.warning} weight="fill" />
        ) : (
          <ImageSquare size={48} color={errorColors.image} weight="fill" />
        )}

        {!smartImageLocationExists ? (
          <>
            <Text
              css={`
                padding-top: 8px;
                text-align: center;
              `}
              label
              bold
            >
              Smart Image
            </Text>
            <Text
              label
              css={`
                text-align: center;
                padding: 0 32px;
              `}
            >
              Image location does not exist. <a>Learn More</a>
            </Text>
          </>
        ) : !smartImageHasValue ? (
          <>
            <Text
              css={`
                padding-top: 8px;
                text-align: center;
              `}
              label
              bold
            >
              Smart Image
            </Text>
            <Text
              label
              css={`
                text-align: center;
                padding: 0 32px;
              `}
            >
              No image within location. <a>Learn More</a>
            </Text>
          </>
        ) : invalidImageUrl ? (
          <>
            <Text
              label
              bold
              css={`
                padding-top: 8px;
              `}
            >
              Image
            </Text>
            <Text
              label
              css={`
                text-align: center;
                padding: 0 32px;
              `}
            >
              Invalid Image URL
            </Text>
          </>
        ) : !imageCanLoad ? (
          <>
            <Text
              label
              bold
              css={`
                padding-top: 8px;
              `}
            >
              Image
            </Text>
            <Text
              label
              css={`
                text-align: center;
                padding: 0 32px;
              `}
            >
              Image failed to load
            </Text>
          </>
        ) : (
          <Text
            label
            css={`
              padding-top: 8px;
            `}
          >
            Image
          </Text>
        )}
        {!isSmartField ? (
          <Text label className="image-hover">
            Double Click for more options.
          </Text>
        ) : null}
      </Div>
      <Modal display={modal.isOpen} onClose={() => modal.hide()}>
        <Modal.Paper
          css={`
            border: 3px solid ${colors.purple};
            border-radius: 16px;
          `}
        >
          <Box flex="space-between">
            <Box
              flex="left"
              css={`
                .img {
                  min-width: 32px;
                  min-height: 32px;
                }
                gap: 16px;
              `}
            >
              <SmartImage className="img" />
              <Text h4>Smart image</Text>
            </Box>
            <Button styles="icon sm" onClick={() => modal.hide()}>
              <X />
            </Button>
          </Box>
          <Modal.Body
            css={`
              display: grid;
              gap: 16px;
              margin-top: 16px;
              ul {
                margin: 0;
                margin-left: 16px;
                padding: 0;
              }
              li::marker {
                color: ${colors.gray[500]};
              }
            `}
          >
            <Text>
              Smart images are images in a guide that are connected to our library of carriers, and company images.
            </Text>
            <Text label bold>
              Smart Image Help
            </Text>
            <ul>
              <li>
                <Text caption>
                  <strong>No image within location:</strong> Check the product or label this image is connected to.
                </Text>
              </li>

              <li>
                <Text caption>
                  <strong>Image location does not exist:</strong> The smart image is not connected to an existing
                  product or label.
                </Text>
              </li>

              <li>
                <Text caption>
                  <strong>Invalid Image URL:</strong> The image url is invalid and needs to be corrected.
                </Text>
              </li>
            </ul>
          </Modal.Body>
        </Modal.Paper>
      </Modal>
    </>
  );
};
