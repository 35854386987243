import { useCallback, useState } from 'react';
import { media, useRequests } from '../../react-query';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

export const useUpload = (updateDocument, dropzoneProps = {}) => {
  const [isLoading, setIsLoading] = useState(false);
  const requests = useRequests();

  const uploadMedia = useCallback(async (file) => {
    try {
      setIsLoading(true);
      const postMeta = media?.utils?.getPost(file);
      const response = await requests.post(postMeta);
      updateDocument(response.data);
    } catch (err) {
      console.warn(err);
      toast.error('Failed to upload media.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length === 1) {
      const files = acceptedFiles?.map((item) => media.utils.extractFileObject(item));
      uploadMedia(files[0]);
    } else {
      toast.error('Invalid file or too many files');
    }
  });

  console.log('dropzoneProps:', dropzoneProps);
  const dropzone = useDropzone({ onDrop, ...dropzoneProps });

  return {
    ...dropzone,
    isLoading,
  };
};
