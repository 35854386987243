import { SmartFieldInput } from '../../../BriteEditor/utility-components/smart-field-input';
import { Tooltip } from '../../../common/components/Tooltip';
import { Box, Input, Text } from '../../../shared/components';
import { useDebounce } from '../../../shared/use-debounce';
import { Modify } from '../../providers/content/content-modify';
import { useContent } from '../../providers/content/use-content';
import { formatUrl } from '../../utility/editor-utils';
import { useDelayedSync } from '../../utility/useDelayedSync';

export const WebsiteToolbar = ({ id, validUrl, linkText, height, src }) => {
  const { sendUpdates } = useContent();

  const handleSmartField = ({ data }) => {
    Modify.properties(sendUpdates, id, (properties) => ({
      linkText: data?.name,
      attributes: {
        ...properties.attributes,
        src: data?.value,
      },
    }));
  };

  const removeSmartField = () => {
    Modify.properties(sendUpdates, id, (properties) => ({
      linkText: '',
      attributes: {
        ...properties.attributes,
        src: '',
      },
    }));
  };

  const debounceChange = useDebounce((updateFn) => Modify.properties(sendUpdates, id, updateFn), 500);

  const onChange = (event) => {
    const [formattedSRC] = formatUrl(event.target.value);
    linkText.set(event.target.value);
    src.set(formattedSRC);
    debounceChange((properties) => ({
      linkText: event.target.value,
      attributes: {
        ...properties.attributes,
        src: formattedSRC,
      },
    }));
  };

  const updateHeight = (height) =>
    Modify.properties(sendUpdates, id, (properties) => ({
      attributes: {
        ...properties.attributes,
        height,
      },
    }));

  useDelayedSync(height, updateHeight, 100);

  return (
    <Box
      flex="left"
      css={`
        gap: 8px;
      `}
    >
      <SmartFieldInput
        css={`
          min-width: 200px;
          height: 40px;
        `}
        nameFilter="URL"
        value={src.value}
        smartFieldDisplay={linkText.value}
        handleSmartField={handleSmartField}
        removeSmartField={removeSmartField}
        inputProps={{
          placeholder: 'Website URL',
          value: linkText.value,
          onChange,
        }}
      />
      <Tooltip label="Height">
        <Input
          css={`
            width: 150px;
            height: 40px;
            padding: 4px;
            padding-right: 8px;
          `}
          disabled={!validUrl}
          type="number"
          value={height.value}
          onChange={(e) => height.set(e.target.value)}
          placeholder="Height"
          endIcon={<Text>px</Text>}
        />
      </Tooltip>
    </Box>
  );
};
