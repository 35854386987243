import { Content } from '../../providers/content/Content';
import { ToolKit } from '../../providers/tool-kit/ToolKit';

const DELETE_SELECTION = {
  action: 'DELETE_SELECTION',
  match: (event, meta) => {
    return !meta.any && event?.key === 'Backspace';
  },
  onKeydown: (context) => {
    const { toolkit, sendUpdates } = context;
    const selection = ToolKit.getSelection(toolkit);
    if (selection?.id) {
      Content.remove(sendUpdates, selection?.id);
    }
  },
};

const COPY_SELECTION = {
  action: 'COPY_SELECTION',
  match: (event, meta) => meta.cmd && event.key === 'c',
};

const SHIFT_MODIFIER = {
  action: 'SHIFT_MODIFIER',
  match: (event, meta) => meta.shift && meta.key === 'Shift',
  onKeydown: (context) => {
    const { toolkit } = context;
    ToolKit.mergeCache(toolkit, 'keyboard', { shift: true });
  },
  onKeyup: (context) => {
    const { toolkit } = context;
    ToolKit.mergeCache(toolkit, 'keyboard', { shift: false });
  },
};

export const shortcuts = [DELETE_SELECTION, COPY_SELECTION, SHIFT_MODIFIER];

export const getKeydownAction = (event, meta) => {
  const shortcut = shortcuts.find((shortcut) => shortcut.match(event, meta));
  return shortcut?.action || '';
};

export const getKeyupAction = (event, meta) => {
  const shortcut = shortcuts.find((shortcut) => shortcut.match(event, meta));
  return shortcut?.action || '';
};

export const KEYDOWN_ACTIONS = shortcuts.reduce((acc, value) => {
  if (value.onKeydown) {
    acc[value.action] = value.onKeydown;
  }
  return acc;
}, {});

export const KEYUP_ACTIONS = shortcuts.reduce((acc, value) => {
  if (value.onKeyup) {
    acc[value.action] = value.onKeyup;
  }
  return acc;
}, {});
