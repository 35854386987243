import { Box, Button, Dropdown, Icon } from '../../../shared/components';
import { ReactComponent as SmartText } from '../../../images/svg/smart-text.svg';
import { SmartFieldWrapper } from '../../../BriteEditor/toolbar-menus/toolbar';
import { useSlate } from 'slate-react';
import { TextAlignCenter, TextAlignLeft, TextAlignRight, TextBolder, TextItalic } from '@phosphor-icons/react';
import { updateInline } from './SlateProperties';
import { useCallback } from 'react';
import {
  getActiveBlock,
  isBlockActive,
  isInlineActive,
  toggleBlock,
} from '../../../BriteEditor/editor-components/text-v2/slate-utils';
import { Tooltip } from '../../../common/components/Tooltip';
import { colors } from '../../../shared/styles';

const alignIconMap = {
  p: TextAlignLeft,
  left: TextAlignLeft,
  center: TextAlignCenter,
  right: TextAlignRight,
};

export const TextToolbar = ({ id, color, textType }) => {
  const slate = useSlate();
  const activeBlock = getActiveBlock(slate, ['ordered-list', 'unordered-list']);
  const Inline = useCallback(
    ({ type, label, children }) => {
      const onClick = () => updateInline(slate, type);
      const isActive = isInlineActive(slate, type);
      return (
        <Button
          css={`
            transition: background-color 0.2s ease;
            ${isActive ? `outline: 1px solid ${colors.gray[500]};` : ''}
          `}
          styles="icon-dark sm"
          hoverLabel={label}
          onClick={onClick}
          onMouseDown={(e) => e.preventDefault()}
        >
          {children}
        </Button>
      );
    },
    [slate]
  );

  const StyleButton = useCallback(
    ({ type, label, children }) => {
      const onClick = () => toggleBlock(slate, type);
      const blockActive = isBlockActive(slate, type);
      return (
        <Tooltip label={label}>
          <Button
            css={`
              ${blockActive ? `outline: 1px solid ${colors.gray[500]};` : ''}
            `}
            styles="icon-dark sm"
            hoverLabel={label}
            onClick={onClick}
            onMouseDown={(e) => e.preventDefault()}
          >
            {children}
          </Button>
        </Tooltip>
      );
    },
    [slate]
  );

  const block = getActiveBlock(slate);

  const AlignIcon = alignIconMap?.[block?.type] || TextAlignLeft;

  // TODO: Decide if we want color picker in toolbar
  // const activeColor = getActiveColor(slate) || color || `var(--text-${textType})`;
  // const onUpdateColor = (color) => {
  //   if (Range.isCollapsed(slate?.selection)) {
  //     removeAllMarks(slate, 'color');
  //     Modify.properties(sendUpdates, id, (properties) => ({
  //       attributes: {
  //         ...properties.attributes,
  //         style: { ...properties.attributes?.style, color },
  //       },
  //     }));
  //   } else {
  //     updateColor(slate, 'color', color);
  //   }
  // };

  return (
    <Box
      flex="left"
      css={`
        gap: 8px;
      `}
    >
      <Box
        flex="left"
        css={`
          gap: 4px;
        `}
      >
        <Inline type="bold" label="Bold">
          <TextBolder size={24} color="white" />
        </Inline>
        <Inline type="italic" label="Italic">
          <TextItalic size={24} color="white" />
        </Inline>
        {/* TODO: Decide if we want colorpicker in toolbar */}
        {/* <ColorPicker color={activeColor} update={onUpdateColor} label="Text Color" /> */}
      </Box>

      <Dropdown
        clear
        disabled={!!activeBlock?.type}
        button={() => (
          <Button styles="icon-dark sm">
            <AlignIcon size={24} color="currentColor" />
          </Button>
        )}
        listCss={`background-color: ${colors.gray[600]}; margin-top: 4px;`}
      >
        <Box
          flex="left"
          data-scope="menu"
          css={`
            gap: 8px;
          `}
        >
          <StyleButton type="left" label="Left">
            <TextAlignLeft size={24} color="currentColor" />
          </StyleButton>
          <StyleButton type="center" label="Center">
            <TextAlignCenter size={24} color="currentColor" />
          </StyleButton>
          <StyleButton type="right" label="Right">
            <TextAlignRight size={24} color="currentColor" />
          </StyleButton>
        </Box>
      </Dropdown>
      <Dropdown
        clear
        stopPropagation
        button={() => (
          <Button styles="icon-dark sm">
            <Icon SVG={SmartText} size={24} />
          </Button>
        )}
      >
        <Box
          data-scope="menu"
          css={`
            min-width: 400px;
            padding: 0 8px;
          `}
        >
          <SmartFieldWrapper />
        </Box>
      </Dropdown>
    </Box>
  );
};
