import { DROP_CREATE_BLOCK, DROP_MOVE_BLOCK, DROP_MOVE_ROW, DROP_MOVE_SECTION } from '../../utility/constants';
import { attachAction, getAction, removeEmptyActions, updateAction } from './composition/action-utils';
import { createBlock, moveBlock } from './composition/block-utils';
import { findAdjacentChildIndex } from './composition/child-utils';
import { compileCompositionActions, getComposition, removeFromComposition } from './composition/composition-utils';
import { moveRow } from './composition/row-utils';

// Functions and values found here are used by
// the methods in the Content.jsx file.

export const getUpdateAction = (content, id, updateFn) => {
  let unit = {};
  let property = 'data';
  if (content?.data?.[id]) {
    unit = content.data[id];
  } else if (content?.sections) {
    unit = content.sections.find((section) => section.id === id);
    property = 'sections';
  }
  const updates = updateFn(unit);
  return {
    id,
    updates,
    property,
    old: unit,
    type: 'update',
    createdAt: Date.now(),
  };
};

export const moveSection = ({ composition, source, destination, edge }) => {
  const content = composition._content;
  const section = content.sections.find((section) => section.id === source.id);
  const sections = content.sections.filter((section) => section.id !== source.id);
  const index = findAdjacentChildIndex(sections, { id: destination.id, edge });
  sections.splice(index, 0, section);
  const action = { type: 'sections', sections };
  return [action];
};

const getDragActions = (composition, drag) => {
  const source = getAction(composition, drag.sourceId);
  attachAction(composition, source);
  const destination = getAction(composition, drag.destinationId);
  attachAction(composition, destination);
  return { source, destination };
};

export const createTool = (props) => {
  if (props.type === 'section') {
  } else {
    return createBlock(props);
  }
};

export const getDropActions = (content, drag) => {
  const composition = getComposition(content);
  if (drag?.type === DROP_CREATE_BLOCK) {
    return createTool({ composition, drag });
  } else if (drag?.type === DROP_MOVE_BLOCK) {
    const dragActions = getDragActions(composition, drag);
    return moveBlock({ composition, edge: drag?.edge, ...dragActions });
  } else if (drag?.type === DROP_MOVE_ROW) {
    const dragActions = getDragActions(composition, drag);
    return moveRow({ composition, edge: drag?.edge, ...dragActions });
  } else if (drag?.type === DROP_MOVE_SECTION) {
    const dragActions = getDragActions(composition, drag);
    return moveSection({ composition, edge: drag?.edge, ...dragActions });
  }
  return [];
};

export const getRemoveActions = (content, id) => {
  const composition = getComposition(content);

  const action = getAction(composition, id);
  attachAction(composition, action);

  removeFromComposition(composition, action);
  updateAction(composition, action.id, 'remove', {});
  removeEmptyActions(composition);

  const actions = compileCompositionActions(composition);
  return actions;
};
