import { Box } from '../../../shared/components';
import { ActionSetting } from '../components/ActionSetting';
import { AlignSetting } from '../components/AlignSetting';
import { PaddingSetting } from '../components/PaddingSetting';
import { getActionProperties, getAlignProperties, getPaddingProperties } from './property-utils';

export const ButtonSettings = ({ data, update, send }) => {
  const padding = getPaddingProperties({ data, update });
  const horizontalAlign = getAlignProperties({ data, update, alignKey: 'justifyContent' });
  const verticalAlign = getAlignProperties({ data, update, alignKey: 'alignItems' });
  const actions = getActionProperties({ data, update });

  return (
    <Box
      css={`
        display: grid;
        gap: 24px;
      `}
    >
      <PaddingSetting value={padding.value} setValue={padding.setValue} send={send} />
      <AlignSetting label="Align" type="horizontal" {...horizontalAlign} send={send} />
      <AlignSetting label="Vertical Align" type="vertical" {...verticalAlign} send={send} />
      <ActionSetting value={actions.value} setValue={actions.setValue} send={send} />
    </Box>
  );
};
