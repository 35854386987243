import { Image, WarningCircle } from '@phosphor-icons/react';
import { Box, Text } from '../../../shared/components';
import { colors } from '../../../shared/styles';
import { getErrorColors, getImageStatus } from './image-helpers';
import { ImageErrors } from './ImageErrors';

export const FallbackImage = ({ block }) => {
  const imageStatus = getImageStatus(block);
  const errorColors = getErrorColors(block, imageStatus);

  return (
    <Box
      css={`
        border-radius: 8px;
        background-color: ${colors.gray[100]};
        padding: 32px 0;
        width: 100%;
        min-height: 150px;
      `}
      flex="center column"
    >
      <ImageIcon hasError={imageStatus?.hasError} errorColors={errorColors} />
      {imageStatus?.hasError ? (
        <ImageErrors imageStatus={imageStatus} />
      ) : (
        <>
          <Text
            label
            css={`
              padding-top: 8px;
            `}
          >
            Image
          </Text>
        </>
      )}
    </Box>
  );
};

const ImageIcon = ({ hasError, errorColors }) =>
  hasError ? (
    <WarningCircle size={32} color={errorColors.warning} weight="fill" />
  ) : (
    <Image size={48} color={errorColors.image} weight="fill" />
  );
