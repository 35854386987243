import { useSlate } from 'slate-react';
import { useCallback } from 'react';
import { Box, Text } from '../../../../shared/components';
import { Highlighter, TextStrikethrough, TextSubscript, TextSuperscript, TextUnderline } from '@phosphor-icons/react';
import { updateInline } from '../../../blocks/text-block/SlateProperties';
import { Tooltip } from '../../../../common/components/Tooltip';
import { isInlineActive } from '../../../../BriteEditor/editor-components/text-v2/slate-utils';
import { propertyButton } from './styles';
import { Property } from '../property';

export const SlateDecoration = () => {
  const slate = useSlate();

  const Inline = useCallback(
    ({ type, label, children }) => {
      const onClick = () => updateInline(slate, type);
      const inlineActive = isInlineActive(slate, type);
      return (
        <Tooltip label={label}>
          <Box
            flex="center"
            css={propertyButton(inlineActive)}
            onMouseDown={(e) => e.preventDefault()}
            onClick={onClick}
          >
            {children}
          </Box>
        </Tooltip>
      );
    },
    [slate]
  );

  return (
    <Property>
      <Text label>Decoration</Text>
      <Box
        flex="left"
        css={`
          gap: 8px;
        `}
      >
        <Inline type="underline" label="Underline">
          <TextUnderline size={24} color="currentColor" />
        </Inline>
        <Inline type="highlight" label="Highlight">
          <Highlighter size={24} color="currentColor" />
        </Inline>
        <Inline type="strikethrough" label="Strikethrough">
          <TextStrikethrough size={24} color="currentColor" />
        </Inline>
        <Inline type="superscript" label="Superscript">
          <TextSuperscript size={24} color="currentColor" />
        </Inline>
        <Inline type="subscript" label="Subscript">
          <TextSubscript size={24} color="currentColor" />
        </Inline>
      </Box>
    </Property>
  );
};
