import {
  AlignBottomSimple,
  AlignCenterHorizontalSimple,
  AlignCenterVerticalSimple,
  AlignLeftSimple,
  AlignRightSimple,
  AlignTopSimple,
} from '@phosphor-icons/react';
import { Box, Text } from '../../../shared/components';
import { selectableBox } from '../../utility/settings/styles';
import { Property } from './property';

const getAlign1 = (type, value) => {
  if (type === 'horizontal') {
    return {
      value: 'left',
      Icon: AlignLeftSimple,
      isAligned: value === 'left',
    };
  } else if (type === 'vertical') {
    return {
      value: 'start',
      Icon: AlignTopSimple,
      isAligned: value === 'start',
    };
  }
};

const getAlign2 = (type, value) => {
  if (type === 'horizontal') {
    return {
      value: 'center',
      Icon: AlignCenterHorizontalSimple,
      isAligned: value === 'center',
    };
  } else if (type === 'vertical') {
    return {
      value: 'center',
      Icon: AlignCenterVerticalSimple,
      isAligned: value === 'center',
    };
  }
};

const getAlign3 = (type, value) => {
  if (type === 'horizontal') {
    return {
      value: 'right',
      Icon: AlignRightSimple,
      isAligned: value === 'right',
    };
  } else if (type === 'vertical') {
    return {
      value: 'end',
      Icon: AlignBottomSimple,
      isAligned: value === 'end',
    };
  }
};

export const AlignSetting = ({ label, value, setValue, type, disabled }) => {
  const align1 = getAlign1(type, value);
  const align2 = getAlign2(type, value);
  const align3 = getAlign3(type, value);

  if (!align1 || !align2 || !align3) {
    return null;
  }
  return (
    <Property
      css={`
        ${disabled
          ? `
          pointer-events: none;
          opacity: .5;
        `
          : ''}
      `}
    >
      <Text label>{label}</Text>
      <Box
        flex="left"
        css={`
          gap: 8px;
        `}
      >
        <Box flex="center" css={selectableBox(align1?.isAligned)} onClick={() => setValue(align1?.value)}>
          <align1.Icon size={24} color="currentColor" />
        </Box>
        <Box flex="center" css={selectableBox(align2?.isAligned)} onClick={() => setValue(align2?.value)}>
          <align2.Icon size={24} color="currentColor" />
        </Box>
        <Box flex="center" css={selectableBox(align3?.isAligned)} onClick={() => setValue(align3?.value)}>
          <align3.Icon size={24} color="currentColor" />
        </Box>
      </Box>
    </Property>
  );
};
