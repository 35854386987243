import { createContext, memo, useMemo } from 'react';
import { useSegment } from './use-segment';
import { useContent } from '../content/use-content';
import { Content } from '../content/Content';

export const SegmentContext = createContext();

export const scopeParentToChildMap = {
  section: 'row',
  row: 'column',
  column: 'block',
};

export const scopeChildToParentMap = {
  section: '',
  row: 'section',
  column: 'row',
  block: 'column',
};

export const Segment = (props) => {
  const { children, type, id, index, scope } = props;

  const { content } = useContent();

  const data = useMemo(() => {
    return content?.data?.[id] ?? {};
  }, [content?.data?.[id]]);

  const parentSegment = useSegment() ?? {};
  const { elements: currentElements = {}, indices: currentIndices = {}, ...parentRest } = parentSegment;

  const parent = useMemo(() => {
    return parentRest?.id || parentRest?.type
      ? {
          id: parentRest?.id,
          type: parentRest?.type,
          parent: parentRest?.parent,
          childCount: parentRest?.data?.children?.length,
        }
      : null;
  }, [parentRest?.id, parentRest?.type, parentRest?.parent, parentRest?.data?.children?.length]);

  const elements = useMemo(() => {
    return scope
      ? {
          ...currentElements,
          [scope]: props?.element,
        }
      : currentElements;
  }, [scope]);

  const indexDeps = Object.values(currentIndices).toString();
  const indices = useMemo(() => {
    return scope in scopeParentToChildMap && index !== undefined
      ? {
          ...currentIndices,
          [scopeParentToChildMap?.[scope]]: index,
        }
      : currentIndices;
  }, [indexDeps]);

  const value = useMemo(() => {
    return {
      data,
      id,
      type,
      elements,
      parent,
      indices,
      scope: scopeParentToChildMap[scope],
    };
  }, [data, id, type, elements, indices, parent]);

  return <SegmentMemo value={value}>{children}</SegmentMemo>;
};

const SegmentMemo = memo(({ children, value }) => {
  return (
    <SegmentContext.Provider key={value.id} value={value}>
      {children}
    </SegmentContext.Provider>
  );
});

// GETTERS
Segment.countParentsChildren = (segment, type = '') => {
  const parent = Segment.getParent(segment, type);
  return parent?.childCount || null;
};

Segment.getChildCount = (segment) => segment?.data?.children?.length || null;

Segment.getProperties = (segment) => segment?.data?.properties || {};

Segment.getStyle = (segment, style = {}) => ({
  ...(segment?.data?.properties?.attributes?.style || {}),
  ...style,
});

Segment.getContainerStyle = (segment, style = {}) => ({
  ...(segment?.data?.properties?.container?.attributes?.style || {}),
  ...style,
});

Segment.getIndex = (segment, type) => segment?.indices?.[type] ?? null;

Segment.getIsSelected = (segment, toolkit) =>
  toolkit?.data?.selection?.ids?.includes(segment?.id) && toolkit?.data?.selection?.type === 'single';

Segment.getIsContext = (segment, toolkit) => segment?.id === toolkit?.data?.contextMenu?.context?.id;

Segment.getIsHighlighted = (segment, toolkit) => {
  const isInSelection = toolkit?.data?.selection?.ids?.includes(segment?.id);
  const isContext = Segment.getIsContext(segment, toolkit);
  return isInSelection || isContext;
};

Segment.getParent = (segment, type) => {
  let parent = segment.parent;
  if (!type) {
    return parent;
  }
  while (parent && parent.type !== type) {
    parent = parent.parent;
  }
  return parent ?? null;
};

Segment.getPathToRoot = (segment) => {
  let current = segment;
  let tree = [];
  while (current?.type || current?.scope) {
    tree = [...tree, current?.id];
    current = current.parent;
  }
  return tree;
};

Segment.remove = (segment) => {
  const { sendUpdates, id } = segment;
  Content.remove(sendUpdates, id);
};

// NEW ^^^
//-------->
// OLD vvv

Segment.getData = (segment) => segment?.data;
Segment.getElement = (segment, type) => segment?.elements?.[type]?.current;
Segment.getListIndex = (segment) => segment?.location?.[2] ?? null;
Segment.getColumnIndex = (segment) => segment?.location?.[1] ?? null;
Segment.getRowIndex = (segment) => segment?.location?.[0] ?? null;
