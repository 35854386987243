import { css } from '@emotion/css';
import { Box, BriteLoader } from '../../../shared/components';
import { Toolbar } from '../../utility/Toolbar';
import { FallbackImage } from './FallbackImage';
import { ImageToolbar } from './ImageToolbar';
import { useImageBlock } from './useImageBlock';
import { CroppedImage } from './CroppedImage';
import { animation } from '../../../shared/shared-styles';

export const ImageBlock = () => {
  const block = useImageBlock();
  const aspectRatio = block?.image?.img?.naturalWidth / block?.image?.img?.naturalHeight;

  return (
    <>
      {block?.image?.loading ? (
        <BriteLoader
          size={48}
          weight={8}
          css={`
            background-color: transparent;
          `}
        />
      ) : null}
      {block?.isFallbackImage ? (
        <FallbackImage block={block} />
      ) : block?.sync?.value?.crop === null ? (
        <Box
          css={`
            position: relative;
            aspect-ratio: ${aspectRatio};
            overflow: hidden;
            ${animation('fadeIn', '.3s ease')}
            transition: border-radius 0.2s ease, width 0.1s ease;
          `}
          style={block?.style}
        >
          <img
            className={css`
              width: 100%;
            `}
            src={block?.image?.img?.src}
            alt={block?.sync?.value?.alt}
          />
        </Box>
      ) : (
        <CroppedImage
          style={block?.style}
          width={block?.style.width}
          crop={block?.sync?.value?.crop}
          image={block?.image?.img}
        />
      )}
      <Toolbar isSelected={block?.isSelected} anchor={block?.anchor}>
        <ImageToolbar id={block?.id} sync={block?.sync} />
      </Toolbar>
    </>
  );
};
