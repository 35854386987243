import * as uuid from 'uuid';
import { addChild, findAdjacentChildIndex, getChildFromAction } from './child-utils';
import { createColumnAction } from './column-utils';
import { attachAction, getParentAction, insertAction, removeEmptyActions, updateAction } from './action-utils';
import { compileCompositionActions, removeFromComposition } from './composition-utils';
import { createSectionAction } from './section-utils';

export const createRowAction = (parentId, children = []) => {
  const id = uuid.v4();
  const row = {
    parentId,
    children,
    id,
    type: 'row',
    properties: {},
  };

  const action = {
    id,
    data: row,
    old: null,
    updates: row,
    property: 'data',
    type: 'insert',
  };

  return action;
};

export const addBlockToAdjacentRow = ({ composition, source, destination, edge }) => {
  const parent = getParentAction(composition, destination?.id);
  attachAction(composition, parent);

  // Create new row
  const row = createRowAction(parent.id, []);
  insertAction(composition, row);

  // Create new column with child
  const child = getChildFromAction(source);
  const column = createColumnAction(row.id, [child]);
  updateAction(composition, source.id, 'update', { parentId: column.id });
  insertAction(composition, column);

  // Add column to row
  const columnAsChild = getChildFromAction(column);
  const rowChildren = addChild(columnAsChild, row.data.children, { edge, id: destination.data.id });
  updateAction(composition, row.id, 'update', { children: rowChildren });

  // Add row to section
  const rowAsChild = getChildFromAction(row);
  const children = addChild(rowAsChild, parent.data.children, { edge, id: destination.data.id });
  updateAction(composition, parent.id, 'update', { children });
};

export const moveRow = ({ composition, source, destination, edge }) => {
  removeFromComposition(composition, source);
  let parent = getParentAction(composition, destination?.id);
  if (destination.data.type === 'section') {
    // Create new section:
    // Dragging a row onto a section (bottom of page) means
    // the section doesn't exist yet. If this changes we'll
    // have to update the if logic here.
    const index = findAdjacentChildIndex(composition._content.sections, { id: destination.id, edge });
    parent = createSectionAction(index, []);
  }
  attachAction(composition, parent);
  const child = getChildFromAction(source);
  const children = addChild(child, parent.data.children, { id: destination.id, edge });
  updateAction(composition, parent.id, 'update', { children });
  updateAction(composition, source.id, 'update', { parentId: parent.data.id });
  removeEmptyActions(composition);
  const actions = compileCompositionActions(composition);
  return actions;
};
