import { useMemo } from 'react';
import { isSmartText } from '../../../BriteEditor/editor-components/image/image';
import { useEditorResource } from '../../../BriteEditor/use-editor-resource';
import { Segment } from '../../providers/segment/Segment';
import { useIsValidUrl } from '../../utility/editor-utils';
import { useBlock } from '../../utility/useBlock';
import { getVideoUrl } from './video-utils';
import { useSync } from '../../utility/useSync';

export const useVideoBlock = () => {
  const block = useBlock();

  const { segment } = block;

  const properties = Segment.getProperties(segment);
  const { attributes = {} } = properties;

  const { data: liveSmartFields, dataUpdatedAt } = useEditorResource('liveSmartFields');

  const srcRaw = useMemo(() => {
    if (isSmartText(attributes.src) && liveSmartFields[attributes.src]?.Success) {
      return getVideoUrl(liveSmartFields[attributes.src]?.Value);
    }
    return attributes?.src;
  }, [attributes?.src, dataUpdatedAt]);

  const src = useSync(srcRaw);
  const videoUrl = useSync(properties?.videoUrl || '');

  const validUrl = useIsValidUrl(src.value || '');

  const allow = attributes.allow + " vertical-scroll 'none'";

  return {
    ...block,
    src,
    videoUrl,
    validUrl,
    attributes: {
      ...attributes,
      allow,
      src: src.value,
      title: 'Video',
      allowFullScreen: true,
    },
  };
};
