import { Slider as MUISlider } from '@material-ui/core';
import { css } from '@emotion/css';
import { px } from '../shared-styles';
import { Div } from './div';
import { colors } from '../styles';

export const Slider = ({ containerProps = {}, ...props }) => {
  return (
    <Div
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
      className={css`
        padding: 0 ${px.sm};
        margin-right: 16px;
        .Mui-disabled {
          opacity: 50%;
          cursor: default;
        }
        .brite-slider {
          padding-bottom: 8px;
          .MuiSlider-track,
          .MuiSlider-rail {
            height: 4px;
            border-radius: 8px;
          }
          .MuiSlider-thumb {
            top: 10px;
            left: 4px;
            height: 18px;
            width: 18px;
          }
          .MuiSlider-valueLabel {
            color: ${props?.labelColor || colors.black};
          }
        }
      `}
    >
      <MUISlider className="brite-slider" {...props} />
    </Div>
  );
};
