import { createContext, useMemo, useReducer, useRef } from 'react';
import { Editor } from '../../Editor';
import { toolKitReducer, initialToolkit } from './tool-kit-reducer';
import { safelySpread } from '../content/content-modify';

export const ToolKitContext = createContext();

export const ToolKit = (props) => {
  const cache = useRef({});

  const [data, dispatch] = useReducer(toolKitReducer, initialToolkit);

  const metadata = useMemo(() => {
    return {
      guide: {
        data: props?.course,
        save: props?.saveCourseRecord,
      },
    };
  }, [props?.course?.ID]);

  return (
    <ToolKitContext.Provider
      value={{
        cache,
        data,
        dispatch,
        metadata,
      }}
    >
      <Editor {...props} />
    </ToolKitContext.Provider>
  );
};

ToolKit.hasSelection = (toolkit) => !!toolkit?.data?.selection?.ids?.length;
ToolKit.hasSingleSelection = (toolkit) => toolkit?.data?.selection?.ids?.length === 1;
ToolKit.hasMultiSelection = (toolkit) => toolkit?.data?.selection?.ids?.length > 1;
ToolKit.getSelection = (toolkit) => {
  const selection = toolkit?.data?.selection || {};
  return selection;
};

ToolKit.multiSelect = (toolkit, { id, element, scope }) => {
  const selection = ToolKit.getSelection(toolkit);
  const bounds = element.getBoundingClientRect();

  toolkit.dispatch({
    type: 'SET_PROPERTY',
    property: 'selection',
    payload: {
      at: Date.now(),
      id,
      scope,
      type: 'multi',
      ids: [...(selection?.ids || []), id],
      bounds: {
        ...(selection?.bounds || {}),
        [id]: bounds,
      },
    },
  });
};

ToolKit.select = (toolkit, { id, element, scope }) => {
  const bounds = element?.getBoundingClientRect();
  toolkit.dispatch({
    type: 'SET_PROPERTY',
    property: 'selection',
    payload: { at: Date.now(), id, ids: [id], bounds: { [id]: bounds }, type: 'single', scope },
  });
};

ToolKit.deselect = (toolkit) => {
  toolkit.dispatch({
    type: 'RESET_PROPERTY',
    property: 'selection',
  });
};

ToolKit.getDrag = (toolkit) => toolkit?.data?.drag || {};

ToolKit.startDrag = (toolkit, dragData) => {
  toolkit.dispatch({
    type: 'RESET_PROPERTY',
    property: 'selection',
  });
  toolkit.dispatch({
    type: 'SET_PROPERTY',
    property: 'drag',
    payload: {
      ...initialToolkit?.drag,
      ...dragData,
      isDragging: true,
    },
  });
};

ToolKit.drop = (toolkit, dropData) => {
  toolkit.dispatch({
    type: 'UPDATE_PROPERTY',
    property: 'drag',
    payload: {
      completedAt: Date.now(),
      isComplete: true,
      isDragging: false,
      ...dropData,
    },
  });
};

ToolKit.resetDrag = (toolkit) => {
  toolkit.dispatch({
    type: 'RESET_PROPERTY',
    property: 'drag',
  });
};

ToolKit.openContextMenu = (toolkit, props) => {
  const rect = props.editor.getBoundingClientRect();
  const x = props.x - rect.left;
  const y = props.y - rect.top;
  const position = { x, y };
  toolkit.dispatch({
    type: 'SET_PROPERTY',
    property: 'contextMenu',
    payload: {
      position,
      display: true,
      updatedAt: Date.now(),
      context: props.context,
    },
  });
};

ToolKit.getContextMenu = (toolkit) => toolkit?.data?.contextMenu || {};
ToolKit.closeContextMenu = (toolkit) => {
  toolkit.dispatch({
    type: 'RESET_PROPERTY',
    property: 'contextMenu',
  });
};

ToolKit.getMetadata = (toolkit, property) => toolkit?.metadata?.[property];

ToolKit.getViewMode = (toolkit) => toolkit?.data?.viewMode;
ToolKit.setViewMode = (toolkit, viewMode) => {
  toolkit.dispatch({
    type: 'SET_PROPERTY',
    property: 'viewMode',
    payload: viewMode,
  });
};

ToolKit.mergeCache = (toolkit, property, value) => {
  toolkit.cache.current[property] = {
    ...safelySpread(toolkit?.cache?.current?.[property] || {}),
    ...value,
  };
};

ToolKit.getCache = (toolkit, key) => toolkit?.cache?.current?.[key] || {};
ToolKit.removeCache = (toolkit, key) => {
  try {
    delete toolkit.cache.current[key];
  } catch (err) {
    console.warn(err);
  }
};

ToolKit.CACHE_KEYS = {
  IMAGES: 'images',
  KEYBOARD: 'keyboard',
  SLATE: 'slate',
};

ToolKit.setKeyboard = (toolkit, keyboard) => {
  toolkit.dispatch({
    type: 'UPDATE_PROPERTY',
    property: 'keyboard',
    payload: {
      [keyboard.type]: {
        updatedAt: Date.now(),
        ...keyboard,
      },
    },
  });
};
ToolKit.setDisplaySettings = (toolkit, payload) => {
  toolkit.dispatch({
    type: 'SET_PROPERTY',
    property: 'settings',
    payload,
  });
};

ToolKit.getIsDisplaySettings = (toolkit) => toolkit?.data?.settings;
