import { useEditorResource } from '../../../BriteEditor/use-editor-resource';
import { Segment } from '../../providers/segment/Segment';
import { TRANSPARENT_COLOR } from '../../utility/constants';
import { useBlock } from '../../utility/useBlock';
import { useSync } from '../../utility/useSync';

export const getSpaceProperties = ({ segment, designStyles }) => {
  const blockStyles = Segment.getStyle(segment);
  const styles = {
    ...designStyles?.[segment?.type],
    ...blockStyles,
  };
  const containerStyle = Segment.getContainerStyle(segment);

  if (segment?.type === 'space') {
    // Legacy code JIC:
    // Container styles are on the block wrapper and the block for some reason
    styles.borderTopRightRadius = containerStyle?.borderTopRightRadius || '';
    styles.borderBottomRightRadius = containerStyle?.borderBottomRightRadius || '';
    styles.borderTopLeftRadius = containerStyle?.borderTopLeftRadius || '';
    styles.borderBottomLeftRadius = containerStyle?.borderBottomLeftRadius || '';
  }

  const height = Number(styles.minHeight.replace(/\D/g, '') || 0);
  const bgColor = styles.backgroundColor || TRANSPARENT_COLOR;
  const breakAfter = containerStyle?.breakAfter;
  const hasPageBreak = breakAfter === 'page';

  return {
    hasPageBreak,
    breakAfter,
    bgColor,
    styles,
    height,
  };
};

export const useSpaceBlock = () => {
  const props = useBlock();
  const { toolkit, segment } = props;
  const { data: designStyles } = useEditorResource('styles');
  const block = getSpaceProperties({ segment, toolkit, designStyles });
  const height = useSync(block.height);

  return { ...props, ...block, height };
};
