import { Segment } from '../../providers/segment/Segment';
import { useBlock } from '../../utility/useBlock';
import { useSync } from '../../utility/useSync';

export const useDocumentBlock = () => {
  const block = useBlock();

  const properties = Segment.getProperties(block.segment);
  const document = useSync(properties.document);

  return {
    ...block,
    document,
  };
};
