export const findAdjacentChildIndex = (children, at) => {
  const adjustedIndex = at.edge === 'top' || at.edge === 'left' ? 0 : 1;
  const index = children.findIndex((child) => child.id === at.id) + adjustedIndex;
  return index;
};

export const addChild = (child, children, at) => {
  let list = [...children];
  const index = findAdjacentChildIndex(list, at);
  list.splice(index, 0, child);
  return list;
};

export const getChildFromAction = (action) => ({
  id: action.data.id,
  type: action.data.type,
});
