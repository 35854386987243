import { Box } from '../../shared/components';
import { colors } from '../../shared/styles';
import { useDebounceValue } from '../../shared/use-debounce-value';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { PropertiesPanel } from './PropertiesPanel';
import { Tools } from './Tools';

export const Sidebar = () => {
  const toolkit = useToolKit();

  const isDisplaySettings = ToolKit.getIsDisplaySettings(toolkit);
  const [delayedDisplaySettings] = useDebounceValue(isDisplaySettings, 300);

  return (
    <Box
      css={`
        position: relative;
        max-width: 250px;
        width: 100%;
        height: 100%;
        background-color: white;
        border-left: 1px solid ${colors.gray[300]};
        margin-left: 1px;
      `}
    >
      <Tools />
      {isDisplaySettings || delayedDisplaySettings ? <PropertiesPanel isDisplaySettings={isDisplaySettings} /> : null}
    </Box>
  );
};
