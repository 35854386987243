import { PencilSimple } from '@phosphor-icons/react';
import { Box, Button, Dropdown, Text } from '../../../shared/components';
import { useContent } from '../../providers/content/use-content';
import { Modify, safelySpread } from '../../providers/content/content-modify';
import { useModal } from '../../../common/hooks/useModal';
import { EditButtonNameModal } from './EditButtonNameModal';

export const ButtonToolbar = ({ id, sync, buttonSize }) => {
  const { sendUpdates } = useContent();

  const modal = useModal();

  const updateSize = (size) => {
    Modify.properties(sendUpdates, id, (properties) => ({
      buttonSize: size,
      attributes: {
        ...safelySpread(properties?.attributes),
        style: {
          ...safelySpread(properties?.attributes?.style),
          fontSize: size === 'small' ? '12px' : size === 'large' ? '20px' : '16px',
          padding: size === 'small' ? '4px 8px' : size === 'large' ? '10px 40px' : '8px 16px',
        },
      },
    }));
  };

  return (
    <>
      <Box
        flex="left"
        css={`
          gap: 8px;
        `}
      >
        <Dropdown
          clear
          button={() => (
            <Button
              editor
              css={`
                width: 100px;
              `}
            >
              {buttonSize || 'Medium'}
            </Button>
          )}
        >
          <Box
            data-scope="menu"
            css={`
              width: 100px;
            `}
          >
            <Box option onClick={() => updateSize('small')}>
              <Text label>Small</Text>
            </Box>
            <Box option onClick={() => updateSize('')}>
              <Text label>Medium</Text>
            </Box>
            <Box option onClick={() => updateSize('large')}>
              <Text label>Large</Text>
            </Box>
          </Box>
        </Dropdown>
        <Button styles="icon-dark sm" onClick={modal.toggle}>
          <PencilSimple size={24} color="white" />
        </Button>
      </Box>
      {modal.isOpen ? <EditButtonNameModal sync={sync} onClose={modal.hide} /> : null}
    </>
  );
};
