import { ListBullets, ListNumbers } from '@phosphor-icons/react';
import { Property } from '../property';
import { Box, Text } from '../../../../shared/components';
import { useCallback } from 'react';
import { getActiveBlock, toggleBlock } from '../../../../BriteEditor/editor-components/text-v2/slate-utils';
import { Tooltip } from '../../../../common/components/Tooltip';
import { propertyButton } from './styles';
import { useSlate } from 'slate-react';

export const SlateList = () => {
  const slate = useSlate();

  const StyleButton = useCallback(
    ({ type, label, children }) => {
      const onClick = () => toggleBlock(slate, type);
      const blockActive = getActiveBlock(slate, [type]);
      const isActive = !!blockActive?.type;
      return (
        <Tooltip label={label}>
          <Box flex="center" css={propertyButton(isActive)} onMouseDown={(e) => e.preventDefault()} onClick={onClick}>
            {children}
          </Box>
        </Tooltip>
      );
    },
    [slate]
  );

  return (
    <Property>
      <Text label>Lists</Text>
      <Box
        flex="stretch"
        css={`
          gap: 8px;
        `}
      >
        <StyleButton type="unordered-list" label="Bulleted List">
          <ListBullets size={24} color="currentColor" />
        </StyleButton>
        <StyleButton type="ordered-list" label="Numbered List">
          <ListNumbers size={24} color="currentColor" />
        </StyleButton>
      </Box>
    </Property>
  );
};
