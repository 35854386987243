import { usePopper } from 'react-popper';
import { Box, Text } from '../../shared/components';
import { Portal } from '../../shared/components/Portal';
import { shadows } from '../../shared/shared-styles';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { useEffect, useState } from 'react';
import { Trash } from '@phosphor-icons/react';
import { Content } from '../providers/content/Content';
import { useContent } from '../providers/content/use-content';
import { Conditional } from './Conditional';

export const ContextMenu = () => {
  const toolkit = useToolKit();
  const contextMenu = ToolKit.getContextMenu(toolkit);

  return (
    <Conditional display={contextMenu.display}>
      <ContextMenuPopper contextMenu={contextMenu} toolkit={toolkit} />
    </Conditional>
  );
};

export const ContextMenuPopper = ({ toolkit, contextMenu }) => {
  const { sendUpdates } = useContent();

  const [anchor, setAnchor] = useState();
  const [menu, setMenu] = useState();
  const popper = usePopper(anchor, menu, { placement: 'bottom-start' });

  useEffect(() => {
    popper?.update?.();
  }, [contextMenu.updatedAt]);

  const remove = () => {
    if (contextMenu.context.scope === 'block') {
      Content.remove(sendUpdates, contextMenu.context.id);
      ToolKit.closeContextMenu(toolkit);
    }
  };

  return (
    <>
      <Box
        css={`
          position: absolute;
          top: ${contextMenu.position.y}px;
          left: ${contextMenu.position.x}px;
        `}
        ref={setAnchor}
      />
      <Portal>
        <Box
          data-scope="context-menu"
          ref={setMenu}
          style={popper.styles.popper}
          {...popper.attributes.popper}
          css={`
            width: 200px;
            border-radius: 8px;
            border-top-left-radius: 0;
            background-color: white;
            ${shadows.lg}
            z-index: 1000;
            padding: 8px;
          `}
        >
          <Box option onClick={remove}>
            <Trash size={24} />
            <Text label bold>
              Remove
            </Text>
          </Box>
        </Box>
      </Portal>
    </>
  );
};
