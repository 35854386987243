import { Box } from '../../shared/components';
import { shadows } from '../../shared/shared-styles';
import { colors } from '../../shared/styles';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';

export const BottomDrag = ({ editor }) => {
  const toolkit = useToolKit();
  const drag = ToolKit.getDrag(toolkit);
  if (drag?.isDragging) {
    return <BottomDragComponent editor={editor} toolkit={toolkit} />;
  }
  return (
    <Box
      css={`
        min-height: 400px;
      `}
    />
  );
};

export const BottomDragComponent = ({ editor, toolkit }) => {
  return (
    <Box
      css={`
        position: relative;
        width: 100%;
        min-height: 400px;
        flex-grow: 1;
        :not(:hover) {
          .action {
            display: none;
          }
        }
      `}
      onMouseUp={() => ToolKit.drop(toolkit, { destinationId: editor?.content?.sections?.at(-1)?.id, edge: 'bottom' })}
    >
      <Box
        className="action"
        css={`
          width: 100%;
          height: 6px;
          background-color: ${colors.purple};
          ${shadows.lg}
        `}
      ></Box>
    </Box>
  );
};
