import { useEvent } from '../../shared/use-event';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { getCanDeselect, getScope } from './editor-utils';

export const useDeselect = () => {
  const toolkit = useToolKit();

  const deselect = (event) => {
    try {
      const scope = getScope(event);

      const contextMenu = ToolKit.getContextMenu(toolkit);
      if (contextMenu.display) {
        if (scope !== 'context-menu') {
          ToolKit.closeContextMenu(toolkit);
          return;
        }
      }

      const hasSelection = ToolKit.hasSelection(toolkit);
      if (!hasSelection) {
        return;
      }

      const canDeselect = getCanDeselect(scope);
      if (canDeselect) {
        return;
      }
      ToolKit.setDisplaySettings(toolkit, false);
      ToolKit.deselect(toolkit);
    } catch (err) {
      console.warn(err);
    }
  };

  useEvent('mousedown', deselect);
};
