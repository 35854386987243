import { useMemo } from 'react';
import { sortGuidesByType } from '../guide';

// Custom hook to filter and sort guides based on search, filter, and sort values.
export const useGuidesList = ({ guides = [], search = '', filterVal = [], sortVal = '' }) => {
  const guidesList = useMemo(() => {
    let list = guides;

    // If no filters are applied, return the original list.
    if (!search && !sortVal && filterVal.length === 0) {
      return list;
    }

    // If search is applied, filter the list based on the search term.
    if (search) {
      const term = search?.toLowerCase();
      list = list?.filter((item) => {
        return item?.Name?.toLowerCase().includes(term);
      });
    }

    // If filter is applied, filter the list based on the filter value.
    if (filterVal.length > 0) {
      list = list?.filter((item) => {
        for (let valueOfFilter of filterVal) {
          if (item?.Type?.includes(valueOfFilter)) {
            return true;
          }
        }
        return false;
      });
    }

    // If sort is applied, sort the list based on the sort value.
    return sortGuidesByType({ guides: list, type: sortVal });
  }, [guides?.length, search, filterVal?.length, sortVal]);

  return guidesList;
};
