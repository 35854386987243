import { isToday } from 'date-fns';
import { css } from '@emotion/css';
import { ArrowSquareOut, X, Users, DotsThree } from '@phosphor-icons/react';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { ExtensionIconMapper } from '../../../Documents/ExtensionIconMapper';
import { history } from '../../../history';
import { useResource, useRequests } from '../../../react-query';
import { MenuItem } from '@material-ui/core';
import { Button, Div, DropMenu, Input, Modal, NewTag, Select, Text } from '../../../shared/components';
import { container, flex } from '../../../shared/shared-styles';
import { colors } from '../../../shared/styles';
import { useForm } from '../../../shared/use-form';
import { getIsParent, useStore } from '../../../store-provider/use-store';
import { media } from '../../../react-query/resources/media';
import { Tooltip } from '../../../common/components/Tooltip';

export const fileTypesMap = {
  pdf: ['application/pdf', ''],
  txt: ['text/plain'],
  images: ['image/png', 'image/gif', 'image/jpeg'],
  csv: ['text/csv'],
  xls: ['application/vnd.ms-excel'],
  xlsx: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
};

const shareStyle = css`
  right: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  padding: 4px;
`;

export const DocumentsLibrary = ({ onUpdate, element, ...modalProps }) => {
  const { businessId } = useParams();
  const [search, setSearch] = useState('');

  const documentsResource = useResource(media, {
    search: {
      with_share_scope: 'business,inherited',
    },
  });

  useEffect(() => {
    documentsResource?.query?.refetch();
  }, [modalProps?.display]);

  const documents = documentsResource?.query?.data;

  const [filters] = useState(['pdf', 'txt', 'csv', 'xls', 'xlsx']);

  const changeDocument = (data) => {
    onUpdate({ document: data });
    modalProps.onClose();
  };

  const rawFilter = useMemo(() => {
    return filters.reduce((prev, key) => [...prev, ...fileTypesMap[key]], []);
  }, [filters.length]);

  const filteredDocuments = useMemo(() => {
    if (documents?.length) {
      const { today = [], old = [] } = documents?.reduce(
        (prev, item) => {
          const { Name, CreatedAt, ContentType } = item;
          const validFilter = !rawFilter.length || rawFilter.includes(ContentType);
          if (validFilter && (!search || Name.toUpperCase().includes(search.toUpperCase()))) {
            const key = isToday(new Date(CreatedAt)) ? 'today' : 'old';
            return { ...prev, [key]: [...prev?.[key], item] };
          }
          return prev;
        },
        { today: [], old: [] }
      );
      return [...today, ...old];
    } else {
      return [];
    }
  }, [search, documentsResource?.query?.dataUpdatedAt, rawFilter.length]);

  useEffect(() => {
    if (!modalProps.desplay) {
      setSearch('');
    }
  }, [modalProps.display]);

  return (
    <Modal {...modalProps} data-scope="modal">
      <Div
        css={css`
          width: 600px;
          padding: 32px;
          box-sizing: border-box;
        `}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <Div>
          <Div
            css={css`
              ${flex('jcsb aic')}
            `}
          >
            <Text styles="h2">Company Documents</Text>
            <Div
              css={css`
                ${flex('right')}
              `}
            >
              <Button
                hoverLabel="Go To Documents"
                styles="icon sm"
                onClick={() => history.push(`/${businessId}/documents`)}
              >
                <ArrowSquareOut />
              </Button>
              <Button styles="icon" onClick={modalProps.onClose}>
                <X />
              </Button>
            </Div>
          </Div>

          <Div>
            <Input
              className={css`
                width: 100%;
                border-radius: 30px;
                background-color: ${colors.gray[100]};
                border: none;
                padding: 0 16px;
                margin: 16px 0;
                :hover {
                  border: none;
                }
              `}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search"
            />
          </Div>
        </Div>

        <Div
          className={css`
            width: 100%;
            height: 400px;
            overflow: hidden;
            overflow-y: auto;
            margin-top: 16px;
            ::-webkit-scrollbar-track {
              background-color: none;
              background: blue;
              border: none;
            }
          `}
        >
          {filteredDocuments.map((item) => {
            const { Name, ID, CreatedAt, FileType, ShareScope } = item;
            return (
              <Div
                className={css`
                  ${flex('left')}
                  ${container.hover}
                  width: 100%;
                  ${ID === element?.document?.ID
                    ? `
                    outline: 2px solid ${colors.purple};
                    outline-offset: -2px;
                  `
                    : ''}
                  border-radius: 8px;
                  padding: 8px 0;
                  padding-right: 16px;
                  cursor: pointer;
                `}
                key={ID}
                onClick={() => changeDocument(item)}
              >
                <Div
                  css={css`
                    ${flex('left')}
                    flex-grow: 1;
                    max-width: calc(100% - 32px);
                    padding: 0 8px;
                  `}
                >
                  <ExtensionIconMapper fileType={FileType} />
                  <Text
                    label
                    css={`
                      flex-grow: 1;
                      padding: 0 8px;
                      font-weight: 550;
                    `}
                    ellipsis
                  >
                    {Name}
                  </Text>
                  {ShareScope && ShareScope !== 'business' && (
                    <Tooltip label="Public" className={shareStyle}>
                      <Users size={24} />
                    </Tooltip>
                  )}
                </Div>
                <div
                  className={css`
                    ${flex('right')}
                  `}
                >
                  <NewTag createdAt={CreatedAt} />
                </div>
              </Div>
            );
          })}
        </Div>
      </Div>
    </Modal>
  );
};

export const DocumentOptions = (props) => {
  const [modal, setModal] = useState('');
  const onClose = () => setModal('');
  const {
    data: { selectedBusiness },
  } = useStore();
  const isParent = getIsParent(selectedBusiness);
  return (
    <>
      <DropMenu
        button={
          <Button styles="icon sm">
            <DotsThree />
          </Button>
        }
      >
        <MenuItem onClick={() => setModal('edit')}>Edit</MenuItem>
        {isParent ? <MenuItem onClick={() => setModal('share')}>Share</MenuItem> : null}
        <MenuItem onClick={() => setModal('delete')}>Delete</MenuItem>
      </DropMenu>
      {!!modal ? <DocumentOptionsModals modal={modal} onClose={onClose} {...props} /> : null}
    </>
  );
};

export const DocumentOptionsModals = ({
  onClose,
  modal,
  //
  document,
  setDocument,
  mediaResource,
}) => {
  const form = useForm(document, [modal]);
  const documentRequests = useRequests(mediaResource);

  const updateDocument = async () => {
    try {
      form?.setIsLoading(true);
      const documentPut = media.utils.getPut(form?.values?.ID, {
        Filename: form?.values?.Name,
        ShareScope: form?.values?.ShareScope,
      });
      await documentRequests.put(documentPut);
      setDocument(form?.values);
    } catch (err) {
      console.log(err);
    } finally {
      form?.setIsLoading(false);
      onClose();
    }
  };

  const deleteDocument = async () => {
    try {
      form?.setIsLoading(true);
      const documentDelete = media?.utils?.getDelete(form?.values?.ID);
      await documentRequests.delete(documentDelete);
      setDocument(null);
    } catch (err) {
      console.warn(err);
    } finally {
      form?.setIsLoading(false);
    }
  };

  return (
    <>
      <Modal display={modal === 'edit'} onClose={onClose}>
        <Modal.Paper>
          <Modal.Header title="Edit Document" onClose={onClose} />
          <Modal.Body>
            <Text label>Name</Text>
            <Input {...form?.getProperty('Name')} />
          </Modal.Body>
          <Modal.Actions>
            <Button secondary onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={updateDocument} disabled={!form?.hasChanges || form?.isLoading}>
              Update
            </Button>
          </Modal.Actions>
        </Modal.Paper>
      </Modal>
      <Modal display={modal === 'share'} onClose={onClose}>
        <Modal.Paper>
          <Modal.Header title="Share Document" onClose={onClose} />
          <Modal.Body>
            <Text
              bold
              label
              css={`
                margin-bottom: 8px;
              `}
            >
              General Access
            </Text>
            <Select {...form?.getProperty('ShareScope')}>
              <MenuItem value="inherited">Public</MenuItem>
              <MenuItem value="business">Private</MenuItem>
            </Select>
            <Text
              css={`
                margin-top: 8px;
              `}
            >
              {form?.values.ShareScope === 'inherited'
                ? 'All of your companies can use this file'
                : form?.values.ShareScope === 'business'
                ? 'Only your company can use this file'
                : 'Anyone can use this file'}
            </Text>
          </Modal.Body>
          <Modal.Actions>
            <Button secondary onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={updateDocument} disabled={!form?.hasChanges || form?.isLoading}>
              Update
            </Button>
          </Modal.Actions>
        </Modal.Paper>
      </Modal>
      <Modal display={modal === 'delete'} onClose={onClose}>
        <Modal.Paper>
          <Modal.Header title="Are you sure?" onClose={onClose} />
          <Modal.Actions>
            <Button secondary onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={deleteDocument} redBg disabled={form?.isLoading}>
              Delete Document
            </Button>
          </Modal.Actions>
        </Modal.Paper>
      </Modal>
    </>
  );
};
