import { useId } from 'react';
import { Box, Slider as BriteSlider, Text } from '../../../shared/components';

export const SliderSetting = ({ label, value, setValue, props }) => {
  const id = useId();
  return (
    <Box
      key={id}
      css={`
        padding: 8px 0;
      `}
    >
      <Text label>{label}</Text>
      <BriteSlider
        style={{ width: '200px' }}
        {...props}
        value={value}
        valueLabelDisplay="auto"
        onChange={(e, value) => setValue(value)}
      />
    </Box>
  );
};
