import { TextAlignCenter, TextAlignLeft, TextAlignRight } from '@phosphor-icons/react';
import { propertyButton } from './styles';
import { Box, Text } from '../../../../shared/components';
import { Tooltip } from '../../../../common/components/Tooltip';
import { useSlate } from 'slate-react';
import { getActiveBlock, toggleBlock } from '../../../../BriteEditor/editor-components/text-v2/slate-utils';
import { useCallback } from 'react';
import { Property } from '../property';

export const SlateHorizontalAlign = () => {
  const slate = useSlate();
  const StyleButton = useCallback(
    ({ type, label, children, disabled }) => {
      const types = type === 'left' ? ['p', 'left'] : [type];
      const activeBlock = getActiveBlock(slate, types);
      const onClick = () => {
        const activeBlock = getActiveBlock(slate, ['ordered-list', 'unordered-list']);
        if (type === 'left' && !!activeBlock?.type) {
          toggleBlock(slate, activeBlock?.type);
        } else {
          toggleBlock(slate, type);
        }
      };
      return (
        <Tooltip label={label}>
          <Box
            flex="center"
            css={propertyButton(activeBlock, disabled)}
            onMouseDown={(e) => e.preventDefault()}
            onClick={onClick}
          >
            {children}
          </Box>
        </Tooltip>
      );
    },
    [slate]
  );

  const activeBlock = getActiveBlock(slate, ['ordered-list', 'unordered-list']);

  return (
    <Property>
      <Text label>Align</Text>
      <Box
        flex="left"
        css={`
          gap: 8px;
        `}
      >
        <StyleButton type="left" label="Left">
          <TextAlignLeft size={24} color="currentColor" />
        </StyleButton>

        <StyleButton type="center" label="Center" disabled={!!activeBlock}>
          <TextAlignCenter size={24} color="currentColor" />
        </StyleButton>

        <StyleButton type="right" label="Right" disabled={!!activeBlock}>
          <TextAlignRight size={24} color="currentColor" />
        </StyleButton>
      </Box>
    </Property>
  );
};
