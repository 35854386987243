import { SmartFieldInput } from '../../../BriteEditor/utility-components/smart-field-input';
import { Box, Button, Input, Modal, Text } from '../../../shared/components';

export const SourceModal = ({ onClose, sync, updateSource }) => {
  const updateAndClose = () => {
    updateSource(sync);
    onClose();
  };

  const undoAndClose = () => {
    sync.resync();
    onClose();
  };

  const handleSmartField = ({ data }) => {
    sync.merge({
      src: data?.url,
      smartFieldDisplay: data?.display,
    });
  };

  const removeSmartField = () => {};

  return (
    <Modal display onClose={onClose} data-scope="modal">
      <Modal.Paper>
        <Modal.Header title="Image Source" onClose={onClose} />
        <Modal.Body
          css={`
            display: grid;
            gap: 16px;
          `}
        >
          <Box>
            <Text label>Source Link</Text>
            <SmartFieldInput
              value={sync?.value?.src}
              smartFieldDisplay={sync?.value?.smartFieldDisplay}
              handleSmartField={handleSmartField}
              removeSmartField={removeSmartField}
              inputProps={{
                value: sync?.value?.src,
                onChange: (event) => sync.merge({ src: event.target.value }),
              }}
              name="Image"
            />
          </Box>
          <Box>
            <Text label>Alternative Text</Text>
            <Input value={sync?.value?.alt} onChange={(event) => sync.merge({ alt: event.target.value })} />
          </Box>
        </Modal.Body>
        <Modal.Actions>
          <Button secondary onClick={undoAndClose}>
            Cancel
          </Button>
          <Button onClick={updateAndClose}>Save</Button>
        </Modal.Actions>
      </Modal.Paper>
    </Modal>
  );
};
