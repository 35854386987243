import { Bell, DotsThree, GearSix } from '@phosphor-icons/react';
import { Button, DropMenu, Text } from '../shared/components';
import { Dropdown } from '../shared/components/dropdown';
import { Box } from '../shared/components/box';
import { Dot } from '../shared/components/dot';
import { colors } from '../shared/styles';
import { ActorIcons } from './components/ActorIcons';
import { format } from 'date-fns';
import { container } from '../shared/shared-styles';
import { history } from '../history';
import { useEffect, useState } from 'react';
import { MenuItem } from '@material-ui/core';
import { knockUtils, useKnock } from './useKnock';
import { NotificationSettingsModal } from './components/NotificationSettingsModal';
import { CustomAxios } from '../redux/axios/axios';
import { useBusinessAuth } from '../Dashboard/use-business-auth';
import { useStore } from '../store-provider/use-store';

export const notificationSourceMap = {
  'new-guide-comment-v1': (item) => {
    const businessId = item?.data?.business_id;
    const pageId = item?.data?.metadata?.pageId;
    const guideId = item?.data?.resource_id;
    const threadId = item?.data?.metadata?.threadId || item?.data?.id;
    const componentId = item?.data?.metadata?.componentId;
    let routeTo = ['comment', threadId, componentId];
    if (threadId !== item?.data?.id) {
      routeTo.push(item?.data?.id);
    }
    const route = `/${businessId}/courses/${guideId}?mode=collaborating&pageId=${pageId}&routingTo=${routeTo.join(
      ','
    )}`;
    return route;
  },
};

const validTypes = ['new-guide-comment-v1'];
export const Notifications = () => {
  const {
    data: { selectedBusiness },
  } = useStore();
  const { setBusiness } = useBusinessAuth();

  const [subMenuOpen, setSubMenuOpen] = useState(false);

  const [modal, setModal] = useState('');

  const knock = useKnock();

  const unseenChangelog = window.__unreadChangelogsCount;

  const [hasUnseenReleases, setHasUnseenReleases] = useState(unseenChangelog > 0);

  const [businesses, setBusinesses] = useState({});

  const fetchBusinesses = async () => {
    const filteredNotifications = knock.sdk.store?.items?.filter(
      (item) => item?.data?.business_id && !(item?.data?.business_id in businesses)
    );
    const dedupedNotifications = [...new Set(filteredNotifications?.map((item) => item?.data?.business_id))];
    const promises = await Promise.all(
      dedupedNotifications?.map((id) => {
        return CustomAxios.get(`/v2/business/${id}`);
      })
    );
    const newBusinesses = promises.reduce(
      (prev, item) => ({
        ...prev,
        [item?.data?.ID]: item?.data,
      }),
      {}
    );
    setBusinesses((current) => ({ ...current, ...newBusinesses }));
  };

  useEffect(() => {
    fetchBusinesses();
  }, [knock.sdk.store?.items?.length]);

  const handleNotificationClick = (item) => {
    knockUtils.markItemAsRead(knock, item);
    const route = notificationSourceMap[item?.source?.key](item);
    if (item?.data?.business_id !== selectedBusiness?.ID) {
      setBusiness(businesses[item?.data?.business_id]);
    }
    history.push(route);
  };

  const displayRelease = () => {
    window.Featurebase('manually_open_changelog_popup');
    setHasUnseenReleases(false);
  };

  const routeNewWindow = (item) => {
    const route = notificationSourceMap[item?.source?.key](item);
    const url = `${window.location.origin}${route}`;
    window.open(url, '_blank');
  };

  return (
    <>
      <NotificationSettingsModal
        display={modal === 'notification-settings'}
        onClose={() => setModal('')}
        knock={knock}
      />
      <Dropdown
        css={`
          :hover {
            background: none;
          }
        `}
        listCss={`padding-top: 0;`}
        listWidth="500px"
        outsideClickProps={{ disableClose: subMenuOpen }}
        popper={{ placement: 'bottom-end' }}
        button={() => (
          <Button
            styles="icon"
            css={`
              position: relative;
            `}
          >
            {knock.sdk.store?.metadata?.unread_count > 0 || hasUnseenReleases ? (
              <Dot position="top-right" spacing={-16} color={colors.purple} />
            ) : null}
            <Bell />
          </Button>
        )}
      >
        <Box
          css={`
            width: 500px;
          `}
          key={knock?.updatedAt}
        >
          <Box
            flex="space-between middle"
            css={`
              padding: 16px;
              position: sticky;
              top: 0;
              background-color: white;
              z-index: 1;
            `}
          >
            <Text h2>Notifications</Text>
            <Box flex="space-between middle">
              <Text
                css={`
                  position: relative;
                  margin-right: 16px;
                  :hover {
                    text-decoration: underline;
                    cursor: pointer;
                  }
                `}
                onClick={displayRelease}
                link={hasUnseenReleases}
                bold={hasUnseenReleases}
              >
                What's New
              </Text>
              <DropMenu
                setTrackDisplay={setSubMenuOpen}
                button={
                  <Button styles="icon">
                    <DotsThree />
                  </Button>
                }
              >
                <MenuItem onClick={() => knockUtils.markAllAsRead(knock)}>Mark all as read</MenuItem>
                <MenuItem onClick={() => knockUtils.archiveAll(knock)}>Delete all</MenuItem>
              </DropMenu>

              <Button styles="icon" onClick={() => setModal('notification-settings')}>
                <GearSix />
              </Button>
            </Box>
          </Box>

          {knock.sdk.store?.items?.length > 0 ? (
            knock.sdk.store?.items?.map((item) =>
              validTypes.includes(item?.source?.key) ? (
                <Box
                  key={item?.id}
                  flex="space-between start"
                  css={`
                    position: relative;
                    padding: 16px 24px;
                    ${container?.hover}
                    border-radius: 8px;
                    overflow: hidden;
                  `}
                  onClick={() => handleNotificationClick(item)}
                >
                  {!item?.read_at ? <Dot verticalSpacing={22} horizontalSpacing={4} /> : null}

                  <Box flex="grow">
                    <Box flex="left start">
                      <ActorIcons actors={item?.actors} />
                      <Box
                        css={`
                          margin-left: 16px;
                          max-width: 340px;
                        `}
                      >
                        <Box flex="left middle wrap">
                          <Text
                            label={!item?.read_at}
                            bold
                            css={`
                              margin-right: 8px;
                            `}
                          >
                            {item?.actors?.map(({ name }) => name)?.join(', ')}
                          </Text>
                          {item?.source?.key === 'new-guide-comment-v1' ? (
                            <Text label={!item?.read_at}>left a comment</Text>
                          ) : null}
                        </Box>
                        <Text
                          label={!item?.read_at}
                          bold
                          css={`
                            width: 100%;
                          `}
                          ellipsis
                        >
                          {businesses?.[item?.data?.business_id]?.Name +
                            (businesses?.[item?.data?.business_id]?.Name ? ' - ' : '')}
                          {item?.data?.resource_name}
                        </Text>
                        <Text>{format(new Date(item?.inserted_at), 'MMM do')}</Text>
                        <Text
                          ellipsis
                          label={!item?.read_at}
                          css={`
                            width: 350px;
                          `}
                        >
                          {item?.data?.content}
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <DropMenu
                      setTrackDisplay={setSubMenuOpen}
                      button={
                        <Button styles="icon">
                          <DotsThree />
                        </Button>
                      }
                    >
                      <MenuItem onClick={() => knockUtils.markItemAsRead(knock, item)}>Mark as read</MenuItem>
                      <MenuItem onClick={() => knockUtils.archiveItem(knock, item)}>Delete notification</MenuItem>
                      <MenuItem onClick={() => knockUtils.unsubscribeFromItem(knock, item)}>Unsubscribe</MenuItem>
                      <MenuItem onClick={() => routeNewWindow(item)}>Open in new tab</MenuItem>
                    </DropMenu>
                  </Box>
                </Box>
              ) : null
            )
          ) : (
            <Box
              css={`
                padding: 16px;
                padding-top: 0;
              `}
            >
              <Text label>You're all caught up on notifications!</Text>
            </Box>
          )}
        </Box>
      </Dropdown>
    </>
  );
};
