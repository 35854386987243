import { Box, Text } from '../../../shared/components';
import { ActionSetting } from '../components/ActionSetting';
import { AlignSetting } from '../components/AlignSetting';
import { ColorSetting } from '../components/ColorSetting';
import { PaddingSetting } from '../components/PaddingSetting';
import { RadiusSetting } from '../components/RadiusSetting';
import { SliderSetting } from '../components/SliderSetting';
import {
  getActionProperties,
  getAlignProperties,
  getBgColorProperties,
  getPaddingProperties,
  getRadiusProperties,
  getWidthProperties,
} from './property-utils';

export const ImageSettings = ({ update, data, designStyles }) => {
  const settingProps = {
    data,
    update,
  };

  const width = getWidthProperties({ ...settingProps, designStyles });
  const horizontalAlign = getAlignProperties({ ...settingProps, alignKey: 'justifyContent' });
  const verticalAlign = getAlignProperties({ ...settingProps, alignKey: 'alignItems' });
  const actions = getActionProperties({ ...settingProps });
  const padding = getPaddingProperties({ ...settingProps });
  const imageRadius = getRadiusProperties({ ...settingProps, type: 'block' });
  const containerRadius = getRadiusProperties({ ...settingProps, type: 'container' });
  const bgColor = getBgColorProperties({ ...settingProps });

  return (
    <Box
      css={`
        display: grid;
        gap: 24px;
      `}
    >
      <SliderSetting
        label={'Width'}
        props={{
          'aria-label': 'Width',
          min: 10,
          max: 100,
        }}
        value={width.value}
        setValue={width.setValue}
      />
      <PaddingSetting value={padding.value} setValue={padding.setValue} />
      <RadiusSetting label="Image Radius" value={imageRadius.value} setValue={imageRadius.setValue} />

      <AlignSetting
        label="Align"
        type="horizontal"
        value={horizontalAlign.value}
        setValue={horizontalAlign.setValue}
        disabled={width.value === 100}
      />
      <AlignSetting
        label="Vertical Align"
        type="vertical"
        value={verticalAlign.value}
        setValue={verticalAlign.setValue}
      />
      <ActionSetting value={actions.value} setValue={actions.setValue} />
      <Text label bold>
        Background
      </Text>
      <ColorSetting {...bgColor} />
      <RadiusSetting label="Radius" value={containerRadius.value} setValue={containerRadius.setValue} />
    </Box>
  );
};
