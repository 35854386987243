import { CaretDown } from '@phosphor-icons/react';
import { Box, Dropdown, Text } from '../../../../shared/components';
import { safelySpread } from '../../../providers/content/content-modify';
import { ToolKit } from '../../../providers/tool-kit/ToolKit';
import { useToolKit } from '../../../providers/tool-kit/use-tool-kit';
import { useQueryAPI } from '../../../../react-query';
import { useMemo } from 'react';
import { TAB_MAP } from '../../../utility/constants';

export const RouteToPage = ({ value, setValue }) => {
  const toolkit = useToolKit();
  const guide = ToolKit.getMetadata(toolkit, 'guide');

  const { data: pages } = useQueryAPI({
    cacheKey: ['pre-fetch-editor-resource'],
    url: `v2/course/${guide?.data?.ID}/pages`,
    enabled: true,
  });

  const displayPageName = useMemo(() => {
    return pages?.find((page) => page?.ID === value?.action?.data?.pageId)?.Name || '';
  }, [value?.action?.data?.pageId]);

  const updateActionData = (actionData) => {
    setValue({
      ...value,
      action: {
        ...safelySpread(value.action),
        data: {
          ...safelySpread(value.action.data),
          ...actionData,
        },
      },
    });
  };

  return (
    <>
      <Text
        label
        css={`
          margin-top: 16px;
          margin-bottom: 8px;
        `}
      >
        Page
      </Text>
      <Dropdown
        clear
        button={() => (
          <Box dropdown>
            <Text label>{displayPageName || 'Select a page'}</Text>
            <CaretDown size={24} />
          </Box>
        )}
      >
        <Box
          css={`
            width: 200px;
          `}
          data-scope="menu"
        >
          {pages?.map((page) => (
            <Box option onClick={() => updateActionData({ pageId: page?.ID })}>
              <Text label>{page?.Name}</Text>
            </Box>
          ))}
        </Box>
      </Dropdown>

      <Text
        label
        css={`
          margin-top: 16px;
          margin-bottom: 8px;
        `}
      >
        Open Page in
      </Text>
      <Dropdown
        clear
        button={() => (
          <Box dropdown>
            <Text label>{TAB_MAP[value?.action?.data?.tabType] || 'Same Tab'}</Text>
            <CaretDown size={24} />
          </Box>
        )}
      >
        <Box
          css={`
            width: 200px;
          `}
          data-scope="menu"
        >
          <Box option onClick={() => updateActionData({ tabType: 'new-tab' })}>
            <Text label>New Tab</Text>
          </Box>
          <Box option onClick={() => updateActionData({ tabType: 'same-tab' })}>
            <Text label>Same Tab</Text>
          </Box>
        </Box>
      </Dropdown>
    </>
  );
};
