import { memo, useMemo, useRef } from 'react';
import { Box, Text } from '../../shared/components';
import { colors } from '../../shared/styles';
import { SpaceBlock } from '../blocks/space-block/SpaceBlock';
import { Segment } from '../providers/segment/Segment';
import { useSegment } from '../providers/segment/use-segment';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { overlay } from '../utility/styles';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { Droppable } from '../utility/Droppable';
import { useDroppable } from '../utility/useDroppable';
import { blockContainerDefaultStyle, blockDefaultStyle, getCanDragBlock, getDroppableProps } from './block-helpers';
import { DROP_MOVE_BLOCK } from '../utility/constants';
import { WebsiteBlock } from '../blocks/website-block/WebsiteBlock';
import { DocumentBlock } from '../blocks/document-block/DocumentBlock';
import { VideoBlock } from '../blocks/video-block/VideoBlock';
import { ImageBlock } from '../blocks/image-block/ImageBlock';
import { ButtonBlock } from '../blocks/button-block/ButtonBlock';
import { TextBlock } from '../blocks/text-block/TextBlock';

const blocks = {
  space: SpaceBlock,
  website: WebsiteBlock,
  document: DocumentBlock,
  video: VideoBlock,
  image: ImageBlock,
  button: ButtonBlock,
  textV2: TextBlock,
};

export const Block = memo(() => {
  const blockRef = useRef();
  const toolkit = useToolKit();
  const segment = useSegment();

  const isMultiSelect = ToolKit.hasMultiSelection(toolkit);
  const isSelected = Segment.getIsSelected(segment, toolkit);
  const isHighlighted = Segment.getIsHighlighted(segment, toolkit);
  const style = Segment.getContainerStyle(segment);

  const doubleClick = () => {
    ToolKit.setDisplaySettings(toolkit, true);
  };

  const openContextMenu = (event) => {
    event.preventDefault();
    const editor = Segment.getElement(segment, 'editor');
    ToolKit.openContextMenu(toolkit, {
      x: event.clientX,
      y: event.clientY,
      editor,
      context: {
        toolType: segment?.type,
        id: segment?.id,
        scope: 'block',
      },
    });
  };

  const selectBlock = () => {
    const keyboard = ToolKit.getCache(toolkit, ToolKit.CACHE_KEYS.KEYBOARD);

    if (keyboard.shift) {
      ToolKit.multiSelect(toolkit, {
        id: segment?.id,
        element: blockRef.current,
        scope: 'block',
      });
    } else {
      ToolKit.select(toolkit, {
        id: segment?.id,
        element: blockRef.current,
        scope: 'block',
      });
    }
  };

  const drag = ToolKit.getDrag(toolkit);

  const handleDragStart = (event) => {
    event.preventDefault();
    ToolKit.startDrag(toolkit, {
      toolType: segment?.type,
      sourceId: segment.id,
      type: DROP_MOVE_BLOCK,
    });
  };

  const drop = useMemo(() => getDroppableProps(segment, drag), [segment, drag?.isDragging]);
  const droppable = useDroppable(drop.props);
  const canDrag = getCanDragBlock(isSelected, segment);

  const BlockComponent = blocks[segment?.type];

  return (
    <Box css={blockContainerDefaultStyle(isHighlighted, drop?.isBeingDragged)} data-scope="block">
      <Box
        {...droppable.attributes}
        ref={blockRef}
        style={style}
        css={blockDefaultStyle(isSelected)}
        className="block-wrapper"
        draggable={canDrag}
        onDragStart={handleDragStart}
        onClick={selectBlock}
        onContextMenu={openContextMenu}
        onDoubleClick={doubleClick}
      >
        {segment?.type in blocks ? (
          <BlockComponent />
        ) : (
          <Box
            flex="left"
            css={`
              border: 4px dotted ${colors.yellow[100]};
              border-radius: 8px;
              padding: 8px 16px;
            `}
          >
            <Text>{segment?.type}</Text>
          </Box>
        )}
      </Box>
      <BlockOverlay isHighlighted={isHighlighted} isMultiSelect={isMultiSelect} />
      <Droppable {...droppable} />
    </Box>
  );
});

const overlayStyles = (isHighlighted, isMultiSelect) => `
  pointer-events: none;
  border-radius: 1px;
  ${
    isHighlighted
      ? `
        outline: 1px ${isMultiSelect ? 'dotted' : 'solid'} rgba(255, 255, 255, .5);
        border: 1px ${isMultiSelect ? 'dotted' : 'solid'} ${colors.purple}AA;
        margin: -1px;
      `
      : ''
  }
  ${overlay}
`;

const BlockOverlay = ({ isHighlighted, isMultiSelect }) => {
  return <Box css={overlayStyles(isHighlighted, isMultiSelect)} className="overlay" />;
};
