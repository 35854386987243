import { Crop, LinkSimple, SquaresFour, UploadSimple } from '@phosphor-icons/react';
import { Box, Button, Dropdown, Text } from '../../../shared/components';
import { useContent } from '../../providers/content/use-content';
import { useModal } from '../../../common/hooks/useModal';
import { UploadModal } from '../../utility/UploadModal';
import { ImagesModal } from '../../../BriteEditor/toolbar-menus';
import { Modify, safelySpread } from '../../providers/content/content-modify';
import { getLogoStyle } from './image-helpers';
import { ImageCropper } from '../../../BriteEditor/toolbar-menus/menu-components/image-cropper';
import { SourceModal } from './SourceModal';

export const ImageToolbar = ({ id, sync }) => {
  const { sendUpdates } = useContent();

  const uploadModal = useModal();
  const libraryModal = useModal();
  const sourceModal = useModal();
  const cropModal = useModal();

  const updateSource = (sync) => {
    Modify.properties(sendUpdates, id, (properties) => {
      const { smartFieldDisplay = '', ...rest } = sync.value;
      const smartField = smartFieldDisplay ? { smartFieldDisplay } : {};
      return {
        ...smartField,
        attributes: {
          ...safelySpread(properties.attributes),
          alt: rest?.alt,
          src: rest?.src,
        },
      };
    });
  };

  const updateImage = (type, attributes) => {
    if (type === 'logos') {
      const style = getLogoStyle();
      Modify.properties(sendUpdates, id, (properties) => ({
        attributes: {
          ...safelySpread(properties.attributes),
          ...attributes,
          style,
        },
        container: {
          ...safelySpread(properties.container),
          attributes: {
            ...safelySpread(properties.container.attributes),
            style,
          },
        },
      }));
    } else {
      Modify.properties(sendUpdates, id, (properties) => ({
        attributes: {
          ...safelySpread(properties.attributes),
          ...attributes,
        },
      }));
    }
  };

  const uploadImage = (document) => {
    updateImage('images', {
      src: document?.PublicURL || document?.LogoURL,
      alt: document?.Name || 'image',
      'data-img-id': document?.ID,
    });
  };

  const updateCrop = ({ crop }) => Modify.properties(sendUpdates, id, (properties) => ({ ...properties, crop }));
  const removeCrop = () => Modify.properties(sendUpdates, id, (properties) => ({ ...properties, crop: null }));

  return (
    <>
      <Box
        css={`
          gap: 8px;
        `}
        flex="left"
      >
        <Dropdown clear button={() => <Button editor>Add Image</Button>}>
          <Box data-scope="menu">
            <Box option onClick={uploadModal.toggle}>
              <UploadSimple size={24} />
              <Text label>Upload</Text>
            </Box>
            <Box option onClick={libraryModal.toggle}>
              <SquaresFour size={24} />
              <Text label>Library</Text>
            </Box>
            <Box option onClick={sourceModal.toggle}>
              <LinkSimple size={24} />
              <Text label>Source</Text>
            </Box>
          </Box>
        </Dropdown>
        <Button styles="icon-dark sm" hoverLabel="Crop" onClick={cropModal.toggle}>
          <Crop size={24} color="currentColor" />
        </Button>
      </Box>
      {uploadModal.isOpen ? (
        <UploadModal
          title="Upload Image"
          onClose={uploadModal.hide}
          update={uploadImage}
          dropzoneProps={{ accept: { 'image/*': [] } }}
        />
      ) : null}
      {libraryModal.isOpen ? (
        <ImagesModal display={true} onClose={libraryModal.hide} updateImage={updateImage} />
      ) : null}
      {cropModal?.isOpen ? (
        <ImageCropper
          display={true}
          onClose={cropModal.hide}
          src={sync?.value?.src}
          currentCrop={sync?.value?.crop}
          onUpdate={updateCrop}
          removeEdits={removeCrop}
        />
      ) : null}
      {sourceModal.isOpen ? <SourceModal onClose={sourceModal.hide} sync={sync} updateSource={updateSource} /> : null}
    </>
  );
};
