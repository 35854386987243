export const initialToolkit = {
  viewMode: 'desktop',
  settings: false,
  selection: {
    at: 0,
    type: '',
    id: '',
    ids: [],
    bounds: {},
  },
  drag: {
    type: '',
    edge: '',
    isComplete: false,
    isDragging: false,
    sourceId: '',
    destinationId: '',
    toolType: '',
    completedAt: Date.now(),
  },
  keyboard: {
    updatedAt: '',
    shortkey: '',
  },
  contextMenu: { display: false, position: { x: 0, y: 0 } },
};

export const toolKitReducer = (state, action) => {
  switch (action?.type) {
    case 'SET_PROPERTIES': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_PROPERTY': {
      return {
        ...state,
        [action?.property]: {
          ...(state?.[action?.property] || {}),
          ...action.payload,
        },
      };
    }
    case 'SET_PROPERTY': {
      return {
        ...state,
        [action?.property]: action.payload,
      };
    }
    case 'RESET_PROPERTY': {
      return {
        ...state,
        [action?.property]: initialToolkit[action?.property],
      };
    }
    default: {
      return state;
    }
  }
};
