import { useEffect, useState } from 'react';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { ToolKit } from '../providers/tool-kit/ToolKit';

// Cache images in the editor so they don't have
// to reload every time they are moved or resized, etc.

export const useImage = (src) => {
  const toolkit = useToolKit();

  const imagesCache = ToolKit.getCache(toolkit, ToolKit?.CACHE_KEYS?.IMAGES) || {};
  const hasImage = !!imagesCache[src];
  const [loading, setLoading] = useState(!hasImage);

  useEffect(() => {
    setLoading(true);
    const img = new Image();
    img.onload = () => {
      setLoading(false);
      ToolKit.mergeCache(toolkit, 'images', {
        [src]: {
          img,
          error: false,
          loading: false,
        },
      });
    };
    img.onerror = () => {
      setLoading(false);
      ToolKit.mergeCache(toolkit, 'images', {
        [src]: {
          img: null,
          error: true,
          loading: false,
        },
      });
    };
    img.src = src;
  }, [src]);

  const imageDataRef = imagesCache?.[src] || { loading };

  return { ...imageDataRef, loading };
};
