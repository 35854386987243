import { useEffect, useRef, useState } from 'react';
import { compileBatchIntoActions, getBatch, migrateV2 } from './editor-utils';
import { useDrop } from './useDrop';
import { useCacheEditorResources } from '../../BriteEditor/use-editor-resource';
import { useEditorShortkeys } from './keyboard/useEditorShortkeys';
import { useDeselect } from './useDeselect';

export const useEditor = (guide, page) => {
  // LOAD RESOURCES
  const isLoadingResources = useCacheEditorResources({ course: guide });

  // PAGE CONTENT
  const [content, setContent] = useState({ ...page?.data?.Content });
  const localActions = useRef(null);

  useEffect(() => {
    if (!page?.isLoading) {
      const content = migrateV2(page?.data?.Content);
      setContent(content);
      console.log(content);
    }
  }, [page?.isLoading, page?.data?.ID]);

  const ws = {
    send: (data) => {
      const clone = JSON.parse(JSON.stringify(data));
      console.log('SEND', clone);
    },
  };

  const sendUpdates = (updaterFn) => {
    setContent((old) => {
      const { content, actions } = updaterFn(old);
      ws.send(actions);
      return content;
    });
  };

  // TODO: sendBatch and createBatch are good candidates for web worker:
  const createBatch = (batchId) => (updaterFn) => {
    setContent((old) => {
      const { content, actions } = updaterFn(old);
      const local = getBatch(localActions.current, batchId, actions);
      localActions.current = local;
      return content;
    });
  };

  const sendBatch = () => {
    if (localActions !== null) {
      const actions = compileBatchIntoActions(localActions.current);
      ws.send(actions);
      localActions.current = null;
    }
  };

  const destroyBatch = (batchId) => {
    try {
      delete localActions.current.history[batchId];
      delete localActions.current.changes[batchId];
      const hasHistory = Object.keys(localActions?.current?.history || {}).length > 0;
      const hasChanges = Object.keys(localActions?.current?.changes || {}).length > 0;
      if (!hasHistory && !hasChanges) {
        localActions.current = null;
      }
    } catch (err) {
      console.warn(err);
    }
  };

  // Custom Hooks
  useEditorShortkeys(sendUpdates);
  useDrop(sendUpdates);
  useDeselect();

  // Return
  const isLoading = isLoadingResources || page?.isLoading;

  return {
    content,
    setContent,
    sendUpdates,
    local: {
      destroyBatch,
      createBatch,
      sendBatch,
    },
    isLoading,
  };
};
