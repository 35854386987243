import { useSlate } from 'slate-react';
import {
  getActiveColor,
  removeAllMarks,
  updateColor,
} from '../../../../BriteEditor/editor-components/text-v2/slate-utils';
import { ColorPicker } from '../../../utility/settings/ColorPicker';
import { Range } from 'slate';
import { Box, Text } from '../../../../shared/components';
import { colors } from '../../../../shared/styles';
import { Property } from '../property';
import { COLOR_MAP } from '../../../utility/constants';
import { useMemo } from 'react';

export const SlateColorPicker = ({ data, update, designStyles }) => {
  const slate = useSlate();

  const colorPalette = designStyles?.general?.colorPalette;

  const textType = data?.properties?.type;
  const color = data?.properties?.attributes?.style?.color || designStyles?.text?.[textType]?.color || '';
  const activeColor = getActiveColor(slate) || color || `var(--text-${textType})`;
  const handleColorUpdate = (color) => {
    if (Range.isCollapsed(slate?.selection)) {
      removeAllMarks(slate, 'color');
      update((data) => ({
        ...data,
        properties: {
          ...data?.properties,
          attributes: {
            ...data?.properties?.attributes,
            style: { ...data?.properties?.attributes?.style, color },
          },
        },
      }));
    } else {
      updateColor(slate, 'color', color);
    }
  };

  const getPreviewColor = () => {
    if (activeColor === 'rgba(0, 0, 0, 0)') {
      return 'None';
    } else if (activeColor.startsWith('var(--')) {
      const key = activeColor?.replace('var(--', '')?.replace(')', '');
      return COLOR_MAP[key] || colorPalette[key] || key;
    }
    return activeColor;
  };

  const previewColor = useMemo(() => {
    return getPreviewColor();
  }, [activeColor, colorPalette]);

  return (
    <Property>
      <Text label>Font Color</Text>
      <Box
        css={`
          border-radius: 8px;
          border: 1px solid ${colors.gray[300]};
          padding: 8px;
          gap: 8px;
        `}
        flex="left"
      >
        <ColorPicker color={activeColor} update={handleColorUpdate} />
        <Text label>{previewColor}</Text>
      </Box>
    </Property>
  );
};
