import { container } from '../../../../shared/shared-styles';
import { colors } from '../../../../shared/styles';

export const propertyButton = (selected, disabled) => `
  flex-grow: 1;
  border: 1px solid ${selected ? colors.purple : colors.gray[300]};
  svg {
    transition: color 0.2s ease;
    color: ${colors.black};
  }
    ${
      disabled
        ? `
      pointer-events: none;
      opacity: .5;
    `
        : ''
    }
  ${
    selected
      ? `
    outline: 1px solid ${colors.purple};
    border: 1px solid ${colors.purple};
    svg {
      color: ${colors.purple}
    }
  `
      : ``
  }
  border-radius: 8px;
  min-width: 40px;
  max-width: 56px;
  height: 40px;
  ${container.hover}
`;
