import { useEffect } from 'react';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { Content } from '../providers/content/Content';
import { useEvent } from '../../shared/use-event';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';

export const useDrop = (sendUpdates) => {
  const toolkit = useToolKit();
  const drag = ToolKit.getDrag(toolkit);

  const handleDrop = async () => {
    Content.drop(sendUpdates, drag);
    ToolKit.resetDrag(toolkit);
  };

  useEffect(() => {
    if (drag?.isComplete) {
      handleDrop();
    }
  }, [drag?.completedAt]);

  const endDrag = () => {
    const drag = ToolKit.getDrag(toolkit);
    if (drag?.isDragging) {
      ToolKit.resetDrag(toolkit);
    }
  };

  useEvent('mouseup', endDrag);
};
