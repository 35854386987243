import { useEffect, useState } from 'react';
import { useWS } from './WebSocketProvider';
import * as uuid from 'uuid';

const createEvent = ({ action, type, key }) => ({
  action,
  id: uuid.v4(),
  timestamp: Date.now().toString(),
  subscriptionType: type,
  subscriptionKey: key,
  expiresAt: '',
});

const getMessageData = (message) => {
  if ('status' in message) {
    return JSON.parse(message.message);
  }
  return message;
};

export const useSubscription = ({ type, key, onMessage, onSuccess, echo = false }) => {
  const ws = useWS();
  const [isSubscribed, setIsSubscribed] = useState(true);

  const handleMessage = (message) => {
    // We may want to move this to a webworker, it could get pretty heavy.
    const messageData = getMessageData(message);
    const isSubscribedMessage =
      messageData?.event?.subscriptionType === type && messageData?.event?.subscriptionKey === key;

    if (!isSubscribedMessage) {
      return;
    }

    switch (message.status) {
      case 'ok': {
        // Successfully sent a message
        const isMessageSubscribing = messageData?.event?.action !== 'unsubscribe';
        if (!isSubscribed) {
          setIsSubscribed(isMessageSubscribing);
        }
        if (isMessageSubscribing) {
          onSuccess?.(message);
        }
        break;
      }
      // Received a broadcast message from a different client
      default: {
        onMessage(message);
      }
    }
  };

  const send = (action, data = {}) => {
    const event = createEvent({ action, type, key });
    const message = ws.send(event, data);
    if (echo) {
      onMessage?.(message);
    }
  };

  const initiateSubscription = () => send('subscribe');

  useEffect(() => {
    if (ws.status === 'connected') {
      initiateSubscription();
    }
    return () => send('unsubscribe');
  }, [ws.status]);

  useEffect(() => {
    handleMessage(ws?.lastJsonMessage || {});
  }, [ws.lastJsonMessage]);

  return {
    send,
    isSubscribed: isSubscribed && ws.status === 'connected',
  };
};
