import React, { useMemo, useEffect } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import Home from '../Home';
import { CompaniesContainer } from '../Companies/Companies';
import { CoursesContainer } from '../Courses/Courses';
import { Settings } from '../Settings/Settings';

import Login from '../Callback/Callback';
import { history } from '../history';
import { PrivateRoute } from './PrivateRoute';
import { PageViewAnalytics } from './PageViewAnalytics';
import { useAuth0 } from '../Auth/react-auth0-wrapper';
import { SignUp } from '../SignUp';
import { Documents } from '../Documents/Documents';
import { Surveys } from '../Surveys/Surveys';
import { css } from '@emotion/css';
import { CustomMenu } from '../custom-menu';
import { includes } from 'lodash';
import { WalkThrough } from './Walkthrough';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../images/brite-logo-new.png';
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import { MultiHeader } from '../multi-header';
import { NPSModal } from '../nps-modal';
import { UnAuthedLoader } from './UnAuthedLoader';
import { useQueryAPI, USER_BUSINESSES_LIST } from '../react-query';
import { useStore } from '../store-provider/use-store';
import { Dashboard } from '../Dashboard/dashboard';
import { Members } from '../Dashboard/member';
import { MemberAccess } from '../Dashboard/member-access';
import { useMembers } from '../Dashboard/use-members';
import { Components } from './components';
import { CourseViewer } from '../Content/Builder-v2/course-viewer';
import { BusinessMembers } from '../members/business-members';
import { BusinessDetails } from '../Settings/business-details';
import { DesignStylesPage } from '../design-styles/design-styles-page';
import { Messaging } from '../Messaging/Messaging';
import { ProductPage } from '../products/page';
import { BenefitPackagePage } from '../benefit-package/BenefitPackagesPage';
import { PackagePage } from '../benefit-package/package-page';
import { Templates } from '../templates/Templates';
import { EditTemplate } from '../templates/edit-template';
import { AppModals } from './app-modals';
import { BusinessSmartFields } from '../Settings/business-smart-fields';
import { CreationFlow } from '../benefit-package/creation-flow/creation-flow';
import { useKeyboard } from './use-keyboard';
import { SharedAnalyticsPage } from '../Analytics/SharedAnalyticsPage';
import { colors } from '../shared/styles';
import { animation } from '../shared/shared-styles';
import posthog from 'posthog-js';
import { CreateBusinessModal } from '../CollectBusinessInfoModal/CreateBusinessModal';
import { Modal } from '../shared/components';
import { BriteLoader } from '../shared/components/brite-loader';
import { AppBusinessRefresh } from './AppBusinessRefresh';
import { AcceptTerms } from '../CollectBusinessInfoModal/AcceptTerms';
import { EditorGuideAnalytics } from '../Analytics/editor/EditorGuideAnalytics';
import { AnalyticsRoute } from '../Analytics/AnalyticsRoute';

var { jwtDecode } = require('jwt-decode');

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerClose: {
    overflowX: 'hidden',
    width: 240,
    background: '#F5F7FA',
    borderRight: 'none',
  },

  drawerWhite: {
    background: '#FFF',
  },
  drawerOpen: {
    width: 180,
    background: '#F5F7FA',
    borderRight: 'none',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    flexGrow: 1,
    background: '#fff',
  },
  logo: {
    width: 48,
    height: 47,
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 24,
    paddingLeft: 24,
    marginBottom: 60,
  },
  logoContainerOpen: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: 4,
    marginTop: 8,
  },
  selected: {},
  listItem: {
    marginBottom: 4,
    marginTop: 4,
    paddingLeft: 24,
    '&:hover': {
      backgroundColor: 'transparent',
      borderLeft: '5px solid #25282D',
      paddingLeft: 19,
    },
    '&$selected': {
      backgroundColor: 'transparent',
      borderLeft: '5px solid #25282D',
      paddingLeft: 19,
      '&:hover': {
        backgroundColor: 'transparent',
        borderLeft: '5px solid #25282D',
        paddingLeft: 19,
      },
    },
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 80,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(1, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));

export const productFormRoute = '/:businessId/packages/:packageId/:planType/:planId';
const open = false;
export const AppRouter = () => {
  const {
    logout,
    loading,
    tokenGlobal,
    showTermsPopup,
    isAuthenticated,
    showBusinessPopup,
    setShowWalkthrough,
    showWalkthrough,
  } = useAuth0();

  const classes = useStyles();

  const {
    data: { user, devMode, profiler, selectedBusiness },
    setStore,
  } = useStore();
  const { ID: userId = '' } = user;
  const { ID: businessId = '' } = selectedBusiness;

  useQueryAPI(
    {
      url: `v1/kollaconnect`,
      enabled: !!businessId,
      onMount: async (data) => {
        try {
          await window.kolla.authenticate(data.Token);
          const consumer = window.kolla.getConsumer();

          const kolla = Object.entries(consumer.linked_accounts).reduce((prev, [key, { status }]) => {
            return { ...prev, [key]: status === 'ACTIVE' };
          }, {});
          setStore('kolla', { ...kolla });
        } catch (err) {
          console.log('error authenticating kolla', err);
          setStore('kolla', {});
        }
      },
      onError: () => console.log('no consumer token'),
    },
    [businessId]
  );

  useEffect(() => {
    setStore('kolla', {});
    posthog.reloadFeatureFlags();
  }, [businessId]);

  // Cache users business list
  useQueryAPI({
    url: USER_BUSINESSES_LIST,
    params: { userId },
    enabled: !!userId,
    staleTime: 1000 * 60 * 60,
  });

  // Cache this here cuz load time is looonnngggggg
  useMembers();

  const isBriteEmployee = useMemo(() => {
    if (!loading && isAuthenticated) {
      let decoded = jwtDecode(tokenGlobal);
      if (decoded.permissions && includes(decoded.permissions, 'superuser')) {
        setStore('isBriteEmployee', true);
        return true;
      }
    }
    const briteUserLoading = loading ? null : false;
    setStore('isBriteEmployee', briteUserLoading);
    return false;
  }, [loading, isAuthenticated]);

  const keydown = (captured, event) => {
    if (captured === 'ctrl+shift+KeyD') {
      setStore('devMode', !devMode);
    } else if (captured === 'ctrl+shift+KeyP') {
      setStore('profiler', !profiler);
    } else if (captured === 'ctrl+shift+KeyG') {
      history.push(`/${businessId}/courses`);
    } else if (captured === 'ctrl+shift+KeyT') {
      history.push(`/${businessId}/templates`);
    } else if (captured === 'ctrl+shift+KeyB') {
      history.push(`/${businessId}/packages`);
    } else if (captured === 'ctrl+shift+KeyM') {
      history.push(`/${businessId}/messaging`);
    } else if (captured === 'ctrl+shift+KeyA') {
      history.push(`/${businessId}/analytics`);
    }
  };

  useKeyboard({ keydown });

  if (loading) {
    return (
      <Modal full display={true}>
        <BriteLoader isLoading={true} />
      </Modal>
    );
  }

  return (
    <Router history={history}>
      <AppBusinessRefresh />
      {devMode ? (
        <div
          className={css`
            z-index: 9999999999;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            outline: 8px solid ${colors.purple};
            outline-offset: -6px;
            pointer-events: none;
            ${animation('fadeIn', '.2s ease')}
          `}
        />
      ) : null}
      <AppModals />

      <PageViewAnalytics></PageViewAnalytics>
      {showTermsPopup ? (
        <AcceptTerms />
      ) : showBusinessPopup ? (
        <CreateBusinessModal />
      ) : (
        <div className={classes.root}>
          <CssBaseline />

          <Drawer
            style={{ zIndex: 0 }}
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
              [classes.drawerWhite]: !isAuthenticated,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
                [classes.drawerWhite]: !isAuthenticated,
              }),
            }}
          >
            <div
              className={clsx(classes.drawer, {
                [classes.logoContainer]: !open,
                [classes.logoContainerOpen]: open,
              })}
              style={{ cursor: 'pointer' }}
              onClick={() => history.push('/dashboard')}
            >
              <img
                src={logo}
                className={css`
                  width: 113px;
                  height: 40px;
                `}
                alt="Brite Logo"
                title="Brite Logo"
              />
            </div>

            {isAuthenticated && <NPSModal />}
            {isAuthenticated && <CustomMenu logout={logout} isBriteEmployee={isBriteEmployee} />}
          </Drawer>
          <main className={classes.content}>
            {isAuthenticated && <MultiHeader logout={logout} />}
            {showWalkthrough && (
              <WalkThrough showWalkthrough={showWalkthrough} setShowWalkthrough={setShowWalkthrough} />
            )}

            <div
              className={css`
                padding: 16px 40px;
              `}
            >
              <Switch>
                <Route path="/" exact children={<Home />} />
                <Route path="/sign-up" exact children={<SignUp />} />
                <Route path="/refresh" exact children={<UnAuthedLoader />} />

                <Route
                  path="/shared/business/:businessId/course/:courseId/analytics"
                  exact
                  children={<SharedAnalyticsPage />}
                />

                {!isAuthenticated && <Redirect to={'/'} />}

                <Route path="/dashboard" exact children={<Dashboard />} />
                <Route path="/members" exact children={<Members />} />
                <Route path="/members/:userId" exact children={<MemberAccess />} />

                <PrivateRoute path="/:businessId/templates/:templateId" children={<EditTemplate />} />

                <PrivateRoute path="/:businessId/templates" children={<Templates />} />

                <PrivateRoute path="/:businessId/companies" children={<CompaniesContainer />} />
                <PrivateRoute path="/:businessId/analytics" children={<AnalyticsRoute />} />

                <PrivateRoute path={productFormRoute} children={<ProductPage />} />
                <PrivateRoute path="/:businessId/packages/:packageId/:step" children={<CreationFlow />} />
                <PrivateRoute path="/:businessId/packages/:packageId" children={<PackagePage />} />
                <PrivateRoute path="/:businessId/packages" children={<BenefitPackagePage />} />

                <PrivateRoute path="/:businessId/design-styles" children={<DesignStylesPage />} />

                <PrivateRoute path="/:businessId/courses/:courseId/analytics" children={<EditorGuideAnalytics />} />
                <PrivateRoute path="/:businessId/courses/:courseId" children={<CourseViewer />} />

                <PrivateRoute path="/:businessId/courses" children={<CoursesContainer />} />
                <PrivateRoute path="/:businessId/documents" children={<Documents />} />

                <PrivateRoute path="/:businessId/messaging" children={<Messaging />} />
                <PrivateRoute path="/:businessId/login" children={<Login />} />
                <PrivateRoute path="/:businessId/settings" children={<Settings />} />
                <PrivateRoute path="/:businessId/surveys" children={<Surveys />} />
                <PrivateRoute path="/:businessId/users" children={<BusinessMembers />} />
                <PrivateRoute path="/:businessId/business-details" children={<BusinessDetails />} />
                <PrivateRoute path="/:businessId/smart-fields" children={<BusinessSmartFields />} />
                <PrivateRoute path="/:businessId/components" children={<Components />} />

                <Redirect to={'/dashboard'} />
              </Switch>
            </div>
          </main>
        </div>
      )}
    </Router>
  );
};
