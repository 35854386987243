import { useCallback, useRef, useState } from 'react';
import { elementMap, listSchema } from '../../../BriteEditor/editor-components/text-v2/slate-config';
import { useEditorResource } from '../../../BriteEditor/use-editor-resource';
import { DefaultElement, Leaf } from '../../../BriteEditor/editor-components/text-v2/slate-components';
import {
  getActiveColor,
  textEditorShortKeys,
  toggleBlock,
  toggleInline,
  updateColor,
} from '../../../BriteEditor/editor-components/text-v2/slate-utils';
import { createEditor, Editor, Transforms } from 'slate';
import { ListsEditor, onKeyDown as slateList_onKeyDown, withLists } from '@prezly/slate-lists';
import { HistoryEditor, withHistory } from 'slate-history';
import { Segment } from '../../providers/segment/Segment';
import { withReact } from 'slate-react';
import { slateContainer, withInlines } from './text-helpers';
import { isEqual } from 'lodash';
import { defaultComponents } from '../../../BriteEditor/constants';

export const useTextBlock = (block) => {
  const textContext = useRef({});
  const { data: designStyles } = useEditorResource('styles');
  const { data: liveSmartFields } = useEditorResource('liveSmartFields');

  const properties = Segment.getProperties(block.segment);
  const blockStyle = Segment.getStyle(block.segment);
  const { slateContent = defaultComponents?.textV2?.slateContent } = properties;

  const [slateEditor] = useState(() => withLists(listSchema)(withInlines(withReact(withHistory(createEditor())))));
  const [at, setAt] = useState();

  const color = blockStyle?.color || designStyles?.text?.[properties.type]?.color || '';
  const style = {
    ...(designStyles?.text?.[properties.type] || {}),
    color,
  };

  const renderElement = useCallback((slateProps) => {
    const Element = elementMap?.[slateProps.element.type] || DefaultElement;
    return <Element {...slateProps} />;
  }, []);

  const renderLeaf = useCallback((slateProps) => {
    return <Leaf {...slateProps} />;
  }, []);

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (ListsEditor.isAtList(slateEditor)) {
        const color = getActiveColor(slateEditor);
        textContext.current.lastListColor = color;
      }
    } else if (event.key === 'ArrowLeft') {
      event.stopPropagation();
    } else if (event.key === 'ArrowRight') {
      event.stopPropagation();
    } else if (event.ctrlKey || event.metaKey) {
      if (event.key === 'z') {
        event.stopPropagation();
        if (event.shiftKey) {
          HistoryEditor.redo(slateEditor);
        } else {
          HistoryEditor.undo(slateEditor);
        }
      } else if (event.key in textEditorShortKeys) {
        event.preventDefault();
        const { type, key } = textEditorShortKeys[event.key];
        if (type === 'inline') {
          toggleInline(slateEditor, key);
        }
      }
    } else if (event.key === 'Backspace' || event.key === 'Delete') {
      const [block] = Editor.nodes(slateEditor, {
        match: (n) => !Editor.isBlock(slateEditor, n) && n.type === 'smart-field',
      });
      const [, blockPath] = block || [];
      if (block?.[0]?.type === 'smart-field') {
        event.preventDefault();
        event.stopPropagation();
        Transforms.delete(slateEditor, {
          at: blockPath,
        });
      } else if (ListsEditor.isAtStartOfListItem(slateEditor)) {
        event.preventDefault();
        ListsEditor.decreaseDepth(slateEditor);
      }
    }
    slateList_onKeyDown(slateEditor, event);
  };

  const onChange = (slateChanges) => {
    const anchor = slateEditor?.selection?.anchor || null;
    if (anchor !== null) {
      const { text = '' } = slateChanges[anchor?.path?.[0]]?.children?.[anchor?.path?.[1]] || { text: '' };
      if (anchor?.offset === 2 && anchor?.path[1] === 0 && text.startsWith('- ')) {
        const at = {
          ...slateEditor.selection,
          anchor: { ...anchor, offset: 0 },
        };
        Transforms.delete(slateEditor, { at });
        toggleBlock(slateEditor, 'unordered-list');
      } else if (anchor.offset === 3 && anchor.path[1] === 0 && text.startsWith('1. ')) {
        const at = {
          ...slateEditor.selection,
          anchor: { ...anchor, offset: 0 },
        };
        Transforms.delete(slateEditor, { at });
        toggleBlock(slateEditor, 'ordered-list');
      }
    }
    textContext.current.blockPathString = '';

    const hasChanges = !isEqual(slateChanges, slateContent);

    if (hasChanges) {
      setAt(Date.now());
    }
  };

  const onKeyUp = () => {
    if (textContext?.current?.lastListColor) {
      updateColor(slateEditor, 'color', textContext?.current?.lastListColor);
      textContext.current.lastListColor = '';
    }
  };

  return {
    editorProps: {
      className: slateContainer,
      style,

      onKeyDown,
      onKeyUp,

      renderElement,
      renderLeaf,

      readOnly: !block?.isSelected,
      placeholder: 'Write something...',
    },
    slateProps: {
      editor: slateEditor,
      initialValue: slateContent,
      onChange,
    },
    at,
    liveSmartFields,
    color,
    type: properties.type,
  };
};
