import { getComponent } from '../../../../BriteEditor/utils';
import { attachAction, getAction, getParentAction, removeEmptyActions, updateAction } from './action-utils';
import { addChild, getChildFromAction } from './child-utils';
import { addToComposition, compileCompositionActions, removeFromComposition } from './composition-utils';
import * as uuid from 'uuid';

export const createBlockAction = ({ type, parentId, properties = {} }) => {
  const id = uuid.v4();
  const block = {
    id,
    type,
    parentId,
    properties,
  };

  const action = {
    id,
    data: block,
    old: null,
    updates: block,
    property: 'data',
    type: 'insert',
  };

  return action;
};

export const getBlockProperties = (toolType) => {
  const data = getComponent(toolType);
  const { component, ...rest } = data;
  return rest;
};

export const addBlockToAdjacentBlock = ({ composition, source, destination, edge }) => {
  const parent = getParentAction(composition, destination?.id);
  const child = getChildFromAction(source);
  const children = addChild(child, parent.data.children, { id: destination.data.id, edge });
  updateAction(composition, parent.id, 'update', { children });
  updateAction(composition, source.id, 'update', { parentId: parent.data.id });
};

export const moveBlock = ({ composition, source, destination, edge }) => {
  removeFromComposition(composition, source);
  addToComposition({ composition, source, destination, edge });
  removeEmptyActions(composition);
  const actions = compileCompositionActions(composition);
  return actions;
};

export const createBlock = ({ composition, drag }) => {
  const properties = getBlockProperties(drag?.toolType);
  const destination = getAction(composition, drag?.destinationId);
  const action = createBlockAction({
    parentId: destination.data.id,
    type: drag?.toolType,
    properties,
  });
  attachAction(composition, action);
  addToComposition({ composition, source: action, destination, edge: drag?.edge });
  removeEmptyActions(composition);
  const actions = compileCompositionActions(composition);
  return actions;
};
