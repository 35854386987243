import { Box } from '../../../shared/components';
import { PaddingSetting } from '../components/PaddingSetting';
import { RadiusSetting } from '../components/RadiusSetting';
import { getPaddingProperties, getRadiusProperties } from './property-utils';

export const DividerSettings = (settingProps) => {
  const padding = getPaddingProperties({ ...settingProps });
  const radius = getRadiusProperties({ ...settingProps, type: 'container' });
  return (
    <Box
      css={`
        display: grid;
        gap: 24px;
      `}
    >
      <PaddingSetting {...padding} />
      <RadiusSetting {...radius} />
    </Box>
  );
};
