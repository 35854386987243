import { Check, FolderSimple, PencilSimple, Trash, UploadSimple } from '@phosphor-icons/react';
import { Box, Button, Dropdown, Input, Text } from '../../../shared/components';
import { Modify } from '../../providers/content/content-modify';
import { useContent } from '../../providers/content/use-content';
import { useModal } from '../../../common/hooks/useModal';
import { UploadModal } from '../../utility/UploadModal';
import { DocumentsLibrary } from '../../../BriteEditor/toolbar-menus';
import { useState } from 'react';

export const DocumentToolbar = ({ id, document }) => {
  const { sendUpdates } = useContent();

  const uploadModal = useModal();
  const libraryModal = useModal();
  const [isEditingName, setIsEditingName] = useState(false);

  const updateDocument = (document) => Modify.properties(sendUpdates, id, () => ({ document }));
  const removeDocument = () => Modify.properties(sendUpdates, id, () => ({ document: null }));

  const handleName = (event) => {
    event?.preventDefault();
    setIsEditingName(false);
    updateDocument(document.value);
  };

  const handleEditingName = () => {
    if (!isEditingName) {
      setIsEditingName(true);
    } else {
      handleName();
    }
  };

  return (
    <>
      <Box
        flex="left"
        css={`
          gap: 8px;
        `}
      >
        <Dropdown
          clear
          button={() => (
            <Button
              editor
              css={`
                padding: 4px 16px;
                width: 150px;
              `}
            >
              {!document?.value ? 'Add Document' : 'Replace'}
            </Button>
          )}
        >
          <Box
            data-scope="menu"
            css={`
              width: 150px;
              > * {
                gap: 8px;
              }
            `}
          >
            <Box option flex="left" onClick={uploadModal.toggle}>
              <UploadSimple size={24} />
              <Text label>Upload</Text>
            </Box>
            <Box option flex="left" onClick={libraryModal.toggle}>
              <FolderSimple size={24} />
              <Text label>Library</Text>
            </Box>
            {!!document.value ? (
              <Box option flex="left" onClick={removeDocument}>
                <Trash size={24} />
                <Text label>Remove</Text>
              </Box>
            ) : null}
          </Box>
        </Dropdown>

        {!!document?.value ? (
          <>
            <Button styles="icon-dark sm" onClick={handleEditingName}>
              {isEditingName ? (
                <Check size={24} color="currentColor" />
              ) : (
                <PencilSimple size={24} color="currentColor" />
              )}
            </Button>
            {isEditingName ? (
              <form onSubmit={handleName}>
                <Input
                  css={`
                    width: 150px;
                    height: 34px;
                  `}
                  value={document?.value?.Name}
                  onChange={(e) => document.set({ ...document.value, Name: e.target.value })}
                />
              </form>
            ) : (
              <>
                <a target="_blank" href={document?.value?.PublicURL}>
                  <Text
                    ellipsis
                    uppercase
                    css={`
                      width: 150px;
                      border-radius: 8px;
                      color: white;
                      text-docoration: underline;
                    `}
                  >
                    {document?.value?.Name}
                  </Text>
                </a>
              </>
            )}
          </>
        ) : null}
      </Box>
      {libraryModal.isOpen ? (
        <DocumentsLibrary
          display
          onClose={libraryModal.hide}
          onUpdate={({ document }) => updateDocument(document)}
          element={{ document }}
        />
      ) : null}
      {uploadModal.isOpen ? (
        <UploadModal title="Upload Document" onClose={uploadModal.hide} update={updateDocument} />
      ) : null}
    </>
  );
};
