import { Segment } from '../providers/segment/Segment';
import { useSegment } from '../providers/segment/use-segment';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';

// Common props that are generally useful for block components

export const useBlock = () => {
  const toolkit = useToolKit();
  const segment = useSegment();
  const isSelected = Segment.getIsSelected(segment, toolkit);
  const anchor = Segment.getElement(segment, 'row');
  return {
    toolkit,
    segment,
    anchor,
    isSelected,
    id: segment.id,
  };
};
